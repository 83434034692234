import { FC, Fragment, RefObject, useEffect, useRef, useState } from 'react'
import { IProps } from './types'
import './style.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'

const QueryContainer: FC<IProps> = ({ children, itemsLength }) => {
    const containerRef: RefObject<HTMLDivElement> = useRef(null)
    const bottomElementRef: RefObject<HTMLDivElement> = useRef(null)
    const [showArrow, setShowArrow] = useState(false)

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight
        }
    }, [itemsLength])

    const onScroll = () => {
        if (!containerRef.current) return
        const { scrollTop, scrollHeight } = containerRef.current
        setShowArrow(scrollHeight - scrollTop > 1000)
    }

    const onClickArrowDown = () => {
        bottomElementRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }

    return (
        <Fragment>
            <div ref={containerRef} className="query_container" onScrollCapture={onScroll}>
                {children}
                <div ref={bottomElementRef}></div>
            </div>
            <div className={`arrow_down_container ${showArrow ? 'active' : 'passive'}`} onClick={onClickArrowDown}>
                <FontAwesomeIcon icon={faArrowDown} size="1x" />
            </div>
        </Fragment>
    )
}

export default QueryContainer
