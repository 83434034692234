import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  orderBy,
  and,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase-services";
import { IQueryLogHealthCommentDBModel, QueryLogHealthDBModel } from "./types";

class QueryLogHealthDBServices {
  private collectionName = "healthQueryLogs";
  private dbRef = collection(db, this.collectionName);

  getCollectionName = () => this.collectionName;

  async getAllQueryLogs(dayAgo = 3): Promise<QueryLogHealthDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(
          this.dbRef,
          where("requestTime", ">=", Timestamp.fromDate(date)),
          orderBy("requestTime", "desc")
        )
      );

      const logs: QueryLogHealthDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogHealthDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(email: string): Promise<QueryLogHealthDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.dbRef, where("request_email", "==", email))
      );

      const logs: QueryLogHealthDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogHealthDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(logId: string): Promise<QueryLogHealthDBModel | null> {
    try {
        const docRef = doc(db, this.collectionName, logId);
        const docSnapshot = await getDoc(docRef);

        if (docSnapshot.exists()) {
            const logData = docSnapshot.data() as QueryLogHealthDBModel;
            return {
                ...logData,
                id: docSnapshot.id,
            };
        } else {
            return null
        }
    } catch (error) {
        console.error(error);
        throw error
    }
  }

  async createComment(id: string, data: IQueryLogHealthCommentDBModel) {
    try {
      const docRef = doc(db, "healthQueryLogs", id);
      await setDoc(
        docRef,
        data,
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }

  }

  async getQueryLogForComment(
    patient_id: string,
    query_type: string
  ): Promise<QueryLogHealthDBModel> {
    try {
      const querySnapshot = await getDocs(
        query(
          this.dbRef,
          and(
            where("patient_id", "==", patient_id),
            where("query_type", "==", query_type)
          ),
          orderBy('requestTime', 'desc')
        )
      );

      const logs: QueryLogHealthDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryLogHealthDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryLogHealthDBServices;
