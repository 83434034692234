import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.scss";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { timestampToDate } from "../../utils/helpers";
import { useUtilContext } from "../../context/store/utilContext";
import Button from "../../components/Button";
import DraggableTabs from "../../components/DraggableTabs";
import { TabItemModel } from "../../components/DraggableTabs/types";
import { Empty, Rate, Select } from "antd";
import Avatar from "../../components/Avatar";
import UserService from "../../services/ui-services/userService";
import { IUserModel } from "../../services/ui-services/userService/types";
import QueryHealthChatLogService from "../../services/ui-services/healthQueryChatLogsService";
import { IQueryChatLogHealthResponseModel } from "../../services/ui-services/healthQueryChatLogsService/types";
import Input from "../../components/Input";

const HealthChatQueryLogs: FC = () => {
  const aiAvatarURL = "/ai-avatar.jpg";
  const queryHealthChatLogService = useMemo(() => {
    return new QueryHealthChatLogService();
  }, []);
  const userService = useMemo(() => {
    return new UserService();
  }, []);
  const { onCompleted, onLoading } = useLoading();
  const { t } = useTranslation();
  const {
    healthChatQueryLogs,
    onChangeHealthChatQueryLogs,
    healthChatQueryLogsAnswers,
    onChangeHealthChatQueryLogsAnswers,
  } = useUtilContext();

  const [activeResponseTabKey, setActiveResponseTabKey] = useState<
    string | undefined
  >();
  const [queryLogId, setQueryLogId] = useState<string>("");
  const [queryLogEmail, setQueryLogEmail] = useState<string>("");
  const [queryLogsByEmail, setQueryLogsByEmail] = useState<
    IQueryChatLogHealthResponseModel[]
  >([]);
  const [users, setUsers] = useState<IUserModel[]>([]);
  const logDayCount = 3;
  const maxResponseCount = 100;

  const getAllUsers = useCallback(async () => {
    const res = await userService.getAllUsers();
    setUsers(res);
  }, [userService]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const getAllQueries = useCallback(
    async () => {
      try {
        onLoading();
        const response = await queryHealthChatLogService.getAllQueryLogs(
          logDayCount
        );
        onChangeHealthChatQueryLogs(response);
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        onCompleted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getQueryLogsByEmail = async () => {
    try {
      onLoading();
      const response = await queryHealthChatLogService.getQueryLogsByEmail(
        queryLogEmail
      );
      setQueryLogEmail("");
      setQueryLogsByEmail(response);
      if (!response.length) {
        toast.warn('Not exist!');
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  useEffect(() => {
    if (!healthChatQueryLogs.length) {
      getAllQueries();
    }
  }, [getAllQueries, healthChatQueryLogs.length]);

  const onClickQuery = async (log: IQueryChatLogHealthResponseModel) => {
    try {
      const existLog = healthChatQueryLogsAnswers.find(
        (answer) => answer.id === log.id
      );
      if (existLog) {
        setActiveResponseTabKey(existLog.id);
      } else {
        onLoading();
        let existAnswers = healthChatQueryLogsAnswers;
        existAnswers = [log, ...existAnswers].slice(0, maxResponseCount);
        onChangeHealthChatQueryLogsAnswers(existAnswers);
        setActiveResponseTabKey(log.id);
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  const onClickExecute = async () => {
    if (queryLogId) {
      const existLog = healthChatQueryLogsAnswers.find(
        (answer) => answer.id === queryLogId
      );

      if (existLog) {
        setActiveResponseTabKey(existLog.id);
        setQueryLogId("");
      } else {
        try {
          onLoading();
          const log = await queryHealthChatLogService.getQueryLogById(
            queryLogId
          );
          if (log) {
            await onClickQuery(log);
            setQueryLogId("");
          } else {
            toast.error(t("error.tryAgain"));
          }
        } catch (error) {
          toast.error(t("error.tryAgain"));
        } finally {
          onCompleted();
        }
      }
    }
  };

  const responseViewer = (
    response: IQueryChatLogHealthResponseModel
  ): ReactNode => {
    const {
      requestTime,
      request_email,
      id,
      patient_id,
      history,
      assistant_id,
      message_file_id,
      thread_id,
      comments,
    } = response;

    let filledHistory: {
      created_at?: string | undefined;
      instruction?: string | undefined;
      role?: string | undefined;
      value?: string | undefined;
    }[] = [];

    for (let index = 0; index < (history?.length ?? 0); index++) {
      const h = history![index];
      filledHistory.push(h);
      if (h.role === "user" && history![index + 1]?.role !== "assistant") {
        filledHistory.push({
          value: undefined,
          role: "assistant",
        });
      }
    }

    const answerTopSide = (
      <div className="question-container">
        <div className="fieldLabel">Request Id:</div>
        <div className="fieldValue">{id || ""}</div>
        <div className="fieldLabel">Request Email:</div>
        <div className="fieldValue">{request_email || ""}</div>
        <div className="fieldLabel">Request Time:</div>
        <div className="fieldValue">
          {timestampToDate(requestTime!)!.toString()}
        </div>
        <div className="fieldLabel">Patient Id:</div>
        <div className="fieldValue">{patient_id || ""}</div>
        <div className="fieldLabel">Assistant Id:</div>
        <div className="fieldValue">{assistant_id || ""}</div>
        <div className="fieldLabel">Message File Id:</div>
        <div className="fieldValue">{message_file_id || ""}</div>
        <div className="fieldLabel">Thread Id:</div>
        <div className="fieldValue">{thread_id || ""}</div>
        <div className="fieldLabel">History:</div>
      </div>
    );

    const renderComment = (i: number) => {
      const comment = comments?.find(
        (comment) => (comment.index + 1) * 2 - 1 === i
      );

      if (comment) {
        return (
          <div
            style={{
              width: "100%",
              padding: "10px",
              borderRadius: "3px",
              border: "0.1px dotted gray",
            }}
          >
            <div>
              <Rate value={comment.rating} disabled />
            </div>
            <div>{comment.comment}</div>
          </div>
        );
      }

      return null;
    };

    return (
      <div>
        {answerTopSide}
        {filledHistory?.map((h, i) => (
          <div
            key={i}
            style={{
              padding: "5px",
              marginLeft: "30px",
              marginBlock: "5px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {h?.role === "user" ? (
                <Avatar
                  showDisplayName={false}
                  displayName={request_email ?? ""}
                />
              ) : (
                <Avatar photoUrl={aiAvatarURL} />
              )}
              <div>
                {!!h?.created_at &&
                  timestampToDate(h?.created_at as any)!.toString()}
              </div>
            </div>

            <div className="fieldValue" style={{ whiteSpace: "normal" }}>
              <b>{h?.instruction}</b>
            </div>
            <div className="fieldValue" style={{ whiteSpace: "normal" }}>
              {h?.value ?? <Empty />}
            </div>
            {renderComment(i)}
          </div>
        ))}
      </div>
    );
  };

  const setResponseTabItems = (): TabItemModel[] =>
    healthChatQueryLogsAnswers.map((res, i) => {
      const { id, patient_id } = res;

      return {
        key: id!,
        label: `${patient_id || "-"}`,
        children: responseViewer(res),
        title: `${patient_id || "-"}`,
      };
    });

  const onResponseTabClick = (key: string) => setActiveResponseTabKey(key);

  const onDragAndRemoveTabs = (items: TabItemModel[]) => {
    const newAnswers = items.map((item) => ({
      ...(healthChatQueryLogsAnswers.find((log) => log.id === item.key) ||
        ({} as IQueryChatLogHealthResponseModel)),
    }));
    onChangeHealthChatQueryLogsAnswers(newAnswers);
  };

  const setUserOptions = () =>
    users
      .filter((user) => user.email)
      .map((user) => ({
        value: user.email,
        label: user.email,
        id: user.id,
        key: user.id,
      }));

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="query-logs-page-container">
      <div className="left-side">
        <div className="inputContainer">
          <Input
            value={queryLogId}
            onChange={(val) => setQueryLogId(val as string)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickExecute();
            }}
          />
          <Button disabled={!queryLogId} onClick={onClickExecute}>
            Search By Id
          </Button>
        </div>
        <div className="inputContainer">
          <Select
            className="user_dropdown"
            showSearch
            placeholder="Select a person"
            filterOption={filterOption}
            optionFilterProp="children"
            value={queryLogEmail}
            onChange={(val) => setQueryLogEmail(val as string)}
            options={setUserOptions()}
          ></Select>
          <Button disabled={!queryLogEmail} onClick={getQueryLogsByEmail}>
            Search By Email
          </Button>
        </div>
        <div className="inputContainer">
          <Button onClick={getAllQueries}>
            Refresh List
          </Button>
        </div>
        <div className="prompt-container">
          <ul>
            {queryLogsByEmail
              .sort(
                (a, b) =>
                  timestampToDate(b.requestTime!)!.getTime() -
                  timestampToDate(a.requestTime!)!.getTime()
              )
              .map((log) => {
                return (
                  <li
                    style={{ border: "1px solid teal" }}
                    key={log.id}
                    onClick={() => onClickQuery(log)}
                  >
                    {`${log.patient_id || "-"}`}
                  </li>
                );
              })}
            {healthChatQueryLogs.map((log) => {
              return (
                <li key={log.id} onClick={() => onClickQuery(log)}>
                  {`${log.patient_id || "-"}`}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="right-side">
        <div className="response_tab_container">
          <DraggableTabs
            items={setResponseTabItems()}
            activeKey={activeResponseTabKey}
            onChangeActiveKey={onResponseTabClick}
            onDragAndRemove={onDragAndRemoveTabs}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(HealthChatQueryLogs);
