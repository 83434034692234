import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import "./style.scss";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import AutoSizeTextArea from "../../../components/TextArea";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  handleCreatePackage: (packageName: string, context: string) => void;
}

const CreateModal: React.FC<IProps> = ({
  visible,
  handleCreatePackage,
  closeModal,
}) => {
  const { t } = useTranslation();
  // const baseUrl = "https://tr.wikipedia.org/wiki/";
  const [packageName, setPackageName] = useState<string>("");
  const [context, setContext] = useState<string>("");
  // const [document, setDocument] = useState<File | null>(null);
  // const [URL, setURL] = useState<string>("");
  // const [selectedCreateType, setSelectedCreateType] = useState<DropdownOption>({
  //   value: "pdf",
  //   label: "PDF",
  // });

  // const handleDeletePdf = () => {
  //   setDocument(null);
  // };

  const isValidForm = () =>
    packageName.length > 3 && context.length > 10 && context.length < 500;
  // (selectedCreateType.value === "pdf"
  //   ? document
  //   : isWikiUrlValid(URL, baseUrl));

  return (
    <Modal
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      title={t("PDFPackages.createPackage")}
      className="create-package-modal"
    >
      <div className="modal-line">
        <Input
          error={packageName.length < 3}
          errorMessage={t("PDFPackages.collectionNameValidMessage")}
          value={packageName}
          onChange={(e) => setPackageName(e as string)}
          label={t("PDFPackages.packageName")}
        />
      </div>
      <div className="modal-line">
        <AutoSizeTextArea
          rows={5}
          error={context.length <= 10 || context.length >= 500}
          errorMessage={t("PDFPackages.collectionContextValidMessage")}
          value={context}
          onChange={(e) => setContext(e.target.value)}
          label={t("PDFPackages.context")}
        />
      </div>
      {/* <div className="modal-line">
        <Dropdown
          label={t("PDFPackages.documentType")}
          options={[
            { value: "pdf", label: "PDF" },
            { value: "url", label: "URL" },
          ]}
          onSelect={setSelectedCreateType}
          value={selectedCreateType}
        />
      </div> */}
      {/* {selectedCreateType.value === "pdf" ? (
        <>
          {!document && (
            <div className="modal-line">
              <FileInput
                maxSize={5}
                handleChange={(pdf) => setDocument(pdf as File)}
              />
            </div>
          )}
        </>
      ) : (
        <div className="modal-line">
          <Input
            label={t("PDFPackages.url")}
            error={!isWikiUrlValid(URL, baseUrl)}
            value={URL}
            onChange={(e) => setURL(e as string)}
            placeholder={baseUrl}
            errorMessage={t("PDFPackages.urlValidMessage")}
          />
        </div>
      )} */}
      {/* {document && selectedCreateType.value === "pdf" && (
        <div className="pdfs">
          <div className="pdf">
            <div className="text">{document.name}</div>
            <div className="action">
              <Button
                onClick={() => handleDeletePdf()}
                className="delete-button"
                icon={faTrash}
              />
            </div>
          </div>
        </div>
      )} */}

      <div className="footer">
        <Button
          onClick={() => handleCreatePackage(packageName, context)}
          className="save-button"
          disabled={!isValidForm()}
          icon={faSave}
          iconSize="2x"
        />
      </div>
    </Modal>
  );
};

export default CreateModal;
