export const embedModels = [
  // {
  //   label: "Bert based sentence-transformers model by EmreCan",
  //   value: 0,
  // },
  // {
  //   label: "Multilingual universal sentence encoder (v3) by Google",
  //   value: 1,
  // },
  // {
  //   label: "Cohere Multilingual model (v3.0)",
  //   value: 2,
  // },
  // {
  //   label: "Clip-ViT-B-32-multilingual-v1 sentence transformer model",
  //   value: 3,
  // },
  {
    label: "OpenAI text-embedding-ada-002 model",
    value: 4,
  },
];