import {
  browserLocalPersistence,
  createUserWithEmailAndPassword,
  GithubAuthProvider,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail,
  setPersistence,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateProfile,
  User,
  UserCredential,
} from "firebase/auth";
import { auth } from ".";


export async function createUser(
  email: string,
  password: string
) {
  try {
    const userCredential: UserCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );

    await resendEmailVerification(userCredential.user)

    return userCredential;
  } catch (error) {
    throw error;
  }
}

export async function resendEmailVerification(
  user: User
): Promise<boolean> {
  try {
    await sendEmailVerification(user, {
      url: window.location.href,
    })
    return true;
  } catch (error) {
    return false
  }
}

export function listenToEmailVerifiedChanges(oldUser: User, callback: (user: User) => void) {
  try {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (oldUser.emailVerified !== user.emailVerified) {
          callback(user)
        }
      }
    });

    return () => unsubscribe();
  } catch (error) {
    console.error(error);
    throw error;
  }
}



export function getUserId(): string | null {
  var tenant = JSON.parse(localStorage.getItem("userInfo")!);

  return tenant.uid;
}

export function getCurrentUser(): User | null {
  const user = JSON.parse(localStorage.getItem("userInfo")!);
  return user;
}

export function getUserInfo(): { id: string, email: string } {
  const user = JSON.parse(localStorage.getItem("userInfo")!);
  const currentUser = {
    id: user.uid as string,
    email: user.email as string,
  }
  if (!currentUser.id && !currentUser.email) {
    throw new Error("Kullanıcı bilgileri bulunamadı.");
  }
  return currentUser;
}

export async function logIn(email: string, password: string) {
  try {
    await setPersistence(auth, browserLocalPersistence);
    let userCredential: UserCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential;
  } catch (error) {
    throw error;
  }
}

export async function googleLogIn() {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function githubLogIn() {
  try {
    const provider = new GithubAuthProvider();
    const result = await signInWithPopup(auth, provider);
    return result;
  } catch (error) {
    throw error;
  }
}

export async function logOut() {
  try {
    await signOut(auth);
    return true;
  } catch (error) {
    throw error;
  }
}

export async function resetPassword(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    throw error;
  }
}

export async function updateUserProfile(user: User, name: string) {
  try {
    await updateProfile(user, { displayName: name });
    return true;
  } catch (error) {
    throw error;
  }
}
