import {
  collection,
  getDocs,
  query,
  where,
  Timestamp,
  orderBy,
  addDoc,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase-services";
import { QueryChatLogCommentModel, QueryChatLogHealthDBModel } from "./types";

class QueryChatLogHealthDBServices {
  private collectionName = "healthChatLogs";
  private dbRef = collection(db, this.collectionName);

  async createComments(
    comments: Array<QueryChatLogCommentModel>
  ): Promise<string> {
    try {
      const docRef = await addDoc(this.dbRef, { comments });
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateCommentsById(
    id: string,
    comments: Array<QueryChatLogCommentModel>
  ) {
    try {
      const docRef = doc(db, this.collectionName, id);
      await setDoc(docRef, { comments }, { merge: true });
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllQueryLogs(dayAgo = 3): Promise<QueryChatLogHealthDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(
          this.dbRef,
          where("requestTime", ">=", Timestamp.fromDate(date)),
          orderBy("requestTime", "desc")
        )
      );

      const logs: QueryChatLogHealthDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryChatLogHealthDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(logId: string): Promise<QueryChatLogHealthDBModel | null> {
    try {
      const docRef = doc(db, this.collectionName, logId);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const logData = docSnapshot.data() as QueryChatLogHealthDBModel;
        return {
          ...logData,
          id: docSnapshot.id,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(
    email: string
  ): Promise<QueryChatLogHealthDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.dbRef, where("request_email", "==", email))
      );

      const logs: QueryChatLogHealthDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QueryChatLogHealthDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryChatLogHealthDBServices;
