import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Layout, Model, IJsonModel } from "flexlayout-react";
import { mapFlexLayoutItems } from "../../utils/helpers";
import IndicatorContainer from "./components/IndicatorContainer";
import { IIndicatorModel } from "./types";
import "./style.scss";
import { Button, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const json: IJsonModel = {
  global: {},
  layout: {
    type: "row",
    children: [],
  },
};

type Props = {
  jsonLayout?: IJsonModel;
  indicators?: IIndicatorModel[];
  isPreview?: boolean;
  onChangeLayout?: (layout: IJsonModel) => void;
  onDeleteIndicator?: (id: string) => void;
};

const IndicatorLayout: FC<Props> = ({
  jsonLayout = json,
  indicators = [],
  isPreview = false,
  onChangeLayout = () => null,
  onDeleteIndicator = () => null,
}) => {
  const [unmountedIndicators, setUnmountedIndicators] = useState<
    IIndicatorModel[]
  >([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [sortedItems, setSortedItems] = useState<
    {
      id: string;
      name: string;
    }[]
  >([]);

  const layoutRef = useRef<any>(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getIndicators = useCallback(() => {
    setUnmountedIndicators(
      indicators.filter(
        (indicator) => !JSON.stringify(jsonLayout).includes(indicator.id)
      ) ?? []
    );
  }, [indicators, jsonLayout]);

  useEffect(() => {
    getIndicators();
  }, [getIndicators]);

  useEffect(() => {
    if (isPreview) {
      setSortedItems(mapFlexLayoutItems(jsonLayout));
    }
  }, [jsonLayout, isPreview]);

  const addIndicatorToLayout = (selectedIndicator: IIndicatorModel) => {
    if (!isPreview) {
      layoutRef.current?.addTabWithDragAndDrop(
        `Add ${selectedIndicator.name} <br>(Drag to location)`,
        {
          enableClose: true,
          enableFloat: false,
          enableTabStrip: false,
          enableDrag: true,
          enableDrop: true,
          component: selectedIndicator.id,
          enableRename: false,
          id: selectedIndicator.id,
          name: selectedIndicator.name,
        },
        () => {
          setUnmountedIndicators(
            unmountedIndicators.filter(
              (indicator) => indicator.id !== selectedIndicator.id
            )
          );
        }
      );
    }
  };

  const handleAction = (action: any) => {
    if (action.type === "FlexLayout_DeleteTab") {
      const node = Model.fromJson(jsonLayout).getNodeById(
        action.data.node
      ) as any;
      const closedIndicatorId = node.getComponent();
      const indicator = indicators.find((t) => t.id === closedIndicatorId);
      if (indicator) {
        setUnmountedIndicators((prev) => [...prev, indicator]);
      }
    }
    return action;
  };

  const factory = (node: any) => {
    const component = node.getComponent();
    const indicator = indicators.find((t) => t.id === component);

    if (!indicator) return null;

    return isPreview ? (
      <IndicatorContainer
        generaterFunction={indicator.generaterFunction}
      />
    ) : (
      <div>{indicator.content}</div>
    );
  };

  const onModelChange = (val: Model) => {
    if (!isPreview) {
      onChangeLayout(val.toJson());
    }
  };

  const onClickDelete = (id: string) => {
    onDeleteIndicator(id);
  };

  const renderMobilItem = (id: string) => {
    const indicator = indicators.find((t) => t.id === id);

    if (!indicator) return null;

    return (
      <IndicatorContainer
        key={id}
        generaterFunction={indicator.generaterFunction}
      />
    );
  };

  return (
    <div
      className={`indicator-layout-container ${isPreview && "preview-mode"}`}
    >
      {!isPreview && (
        <div className="unmounted-indicators">
          <h4>
            <b>Unmounted Indicators</b>
          </h4>
          <hr />
          <ul>
            {unmountedIndicators.map((indicator) => (
              <li key={indicator.id}>
                <span onClick={() => addIndicatorToLayout(indicator)}>
                  <div>{indicator.content}</div>
                  <Tooltip title="Delete" placement="right">
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        onClickDelete(indicator.id);
                      }}
                      icon={<FontAwesomeIcon icon={faTrash} />}
                      size="small"
                      style={{ marginTop: "3px" }}
                    />
                  </Tooltip>
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      <div className="layout-side">
        {isMobile && isPreview ? (
          <>{sortedItems.map((item) => renderMobilItem(item.id))}</>
        ) : (
          <Layout
            model={Model.fromJson(jsonLayout)}
            onModelChange={onModelChange}
            factory={factory}
            onAction={handleAction}
            ref={layoutRef}
          />
        )}
      </div>
    </div>
  );
};

export default memo(IndicatorLayout);
