import React, { memo, useMemo } from "react";
import "./style.scss";
import SignUIService from "../../services/ui-services/authService";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/store/authContext";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

const GoogleSignIn: React.FC = () => {
  const signUIService = useMemo(() => {
    return new SignUIService();
  }, [])

  const { t } = useTranslation();
  const { updateLoggedInUserData: setLoggedInUserData } = useAuth();
  const navigate: NavigateFunction = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const response = await signUIService.googleLogIn();
      if (response) {
        setLoggedInUserData(response);
        navigate("/");
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  return (
    <div onClick={handleGoogleSignIn} className="google-container">
      <FontAwesomeIcon icon={faGoogle} />
      <p>{t("googleLogin")}</p>
    </div>
  );
};

export default memo(GoogleSignIn);
