/* eslint-disable no-unreachable */
import {
  FC,
  ReactNode,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import "./style.scss";
import QueryLogService from "../../services/ui-services/queryLogsService";
import { useLoading } from "../../context/store/loadingContext";
import {
  IQueryLogResponseModel,
  IQueryLogWithAnswerModel,
} from "../../services/ui-services/queryLogsService/types";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { timestampToDate } from "../../utils/helpers";
import { useUtilContext } from "../../context/store/utilContext";
import Button from "../../components/Button";
import Input from "../../components/Input";
import DraggableTabs from "../../components/DraggableTabs";
import { TabItemModel } from "../../components/DraggableTabs/types";
import { Rate, Select } from "antd";
import UserService from "../../services/ui-services/userService";
import { IUserModel } from "../../services/ui-services/userService/types";
import DinamicTable from "../../components/DinamicTable";
import DownloadableImage from "../../components/DownloadableImage";
import { useSearchParams } from "react-router-dom";

const DataAnalysisLogs: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const logId = searchParams.get("log_id");

  const queryLogService = useMemo(() => {
    return new QueryLogService();
  }, []);

  const userService = useMemo(() => {
    return new UserService();
  }, []);
  const { onCompleted, onLoading } = useLoading();
  const { t } = useTranslation();
  const {
    dataAnalysisQueryLogs,
    onChangeDataAnalysisQueryLogs,
    dataAnalysisQueryLogsAnswers,
    onChangeDataAnalysisQueryLogsAnswers,
  } = useUtilContext();

  const [activeResponseTabKey, setActiveResponseTabKey] = useState<
    string | undefined
  >();
  const [queryLogId, setQueryLogId] = useState<string>("");
  const [queryLogEmail, setQueryLogEmail] = useState<string>("");
  const [queryLogsByEmail, setQueryLogsByEmail] = useState<
    IQueryLogResponseModel[]
  >([]);
  const [users, setUsers] = useState<IUserModel[]>([]);
  const logDayCount = 3;
  const maxResponseCount = 100;

  const getAllUsers = useCallback(async () => {
    const res = await userService.getAllUsers();
    setUsers(res);
  }, [userService]);

  useEffect(() => {
    getAllUsers();
  }, [getAllUsers]);

  const getAllQueries = useCallback(
    async () => {
      try {
        onLoading();
        const response = await queryLogService.getAllDataAnalysisQueryLogs(logDayCount);
        onChangeDataAnalysisQueryLogs(response);
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        onCompleted();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getQueryLogsByEmail = async () => {
    try {
      onLoading();
      const response = await queryLogService.getDataAnalysisQueryLogsByEmail(
        queryLogEmail
      );
      setQueryLogEmail("");
      setQueryLogsByEmail(response);
      if (!response.length) {
        toast.warn('Not exist!');
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  useEffect(() => {
    if (!dataAnalysisQueryLogs.length) {
      getAllQueries();
    }
  }, [getAllQueries, dataAnalysisQueryLogs.length]);

  const onClickQuery = useCallback(async (log: IQueryLogResponseModel) => {
    try {
      const existLog = dataAnalysisQueryLogsAnswers.find((answer) => answer.id === log.id);
      if (existLog) {
        setActiveResponseTabKey(existLog.id);
      } else {
        onLoading();    
      
        const res = await queryLogService.executeGeneratedTabularDataCode({
          fb_path: `${queryLogService.getDataAnalysisCollectionName()}/${log.id}`,
        });
        let existAnswers = dataAnalysisQueryLogsAnswers;
        existAnswers = [
          { ...log, answer: res.answer, result_type: res.result_type },
          ...existAnswers,
        ].slice(0, maxResponseCount);
        onChangeDataAnalysisQueryLogsAnswers(existAnswers);
        setActiveResponseTabKey(log.id);
      }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      onCompleted();
    }
  }, [onChangeDataAnalysisQueryLogsAnswers, onCompleted, onLoading, dataAnalysisQueryLogsAnswers, queryLogService]);

  const responseViewer = (response: IQueryLogWithAnswerModel): ReactNode => {
    const {
      answer,
      user_query,
      generated_code,
      prompted_query,
      requestTime,
      request_email,
      id,
      request_comment,
      request_rating,
      result_type,
      exec_times_in_second
    } = response;

    const answerTopSide = (
      <div className="question-container">
        <div className="fieldLabel">Request Id:</div>
        <div className="fieldValue">{id || ""}</div>
        <div className="fieldLabel">Request Email:</div>
        <div className="fieldValue">{request_email || ""}</div>
        <div className="fieldLabel">Request Time:</div>
        <div className="fieldValue">
          {timestampToDate(requestTime!)!.toString()}
        </div>
        <div className="fieldLabel">Execute Time In Seconds:</div>
        <div className="fieldValue">
          {JSON.stringify(exec_times_in_second || {})}
        </div>
        <div className="fieldLabel">Request Rating:</div>
        <div className="fieldValue">
          {!!request_rating && <Rate value={request_rating || 0} disabled />}
        </div>
        <div className="fieldLabel">Request Comment:</div>
        <div className="fieldValue">{request_comment || ""}</div>
        <div className="fieldLabel">Question:</div>
        <div className="fieldValue">{user_query}</div>
        <div className="fieldLabel">Generated Code:</div>
        <div className="fieldValue">
          <pre>
            <code>{`${generated_code}`}</code>
          </pre>
        </div>
        <div className="fieldLabel">Prompted Query:</div>
        <div className="fieldValue">
          <pre>
            <code>{`${prompted_query}`}</code>
          </pre>
        </div>
        <div className="fieldLabel">Answer:</div>
      </div>
    );

    if (Array.isArray(answer)) {
      return (
        <div>
          {answerTopSide}
          <DinamicTable items={answer} />
        </div>
      );
    } else if (result_type === "plot") {
      return (
        <div>
          {answerTopSide}
          <DownloadableImage
            src={`https://dev-information-retrieval-store.s3.us-west-2.amazonaws.com/data_insight_images/${answer}`}
            alt="fund_img"
            style={{ width: "100%" }}
          />
        </div>
      );
    } else {
      return (
        <div>
          {answerTopSide}
          <p className="answer-text">{answer}</p>
        </div>
      );
    }
  };

  const setResponseTabItems = (): TabItemModel[] =>
    dataAnalysisQueryLogsAnswers.map((res, i) => {
      const { user_query, id } = res;

      return {
        key: id!,
        label: user_query!.substring(0, 10),
        children: responseViewer(res),
        title: user_query!,
      };
    });

  const onResponseTabClick = (key: string) => setActiveResponseTabKey(key);

  const onClickExecute = async () => {
    if (queryLogId) {
      const existLog = dataAnalysisQueryLogsAnswers.find(
        (answer) => answer.id === queryLogId
      );

      if (existLog) {
        setActiveResponseTabKey(existLog.id);
        setQueryLogId("");
      } else {
        try {
          onLoading();
          const log = await queryLogService.getDataAnalysisQueryLogById(queryLogId);
          if (log) {
            await onClickQuery(log);
            setQueryLogId("");
          } else {
            toast.error(t("error.tryAgain"));
          }
        } catch (error) {
          toast.error(t("error.tryAgain"));
        } finally {
          onCompleted();
        }
      }
    }
  };

  const executeByParamsLogId = useCallback(async () => {
    if (logId) {
      const existLog = dataAnalysisQueryLogsAnswers.find(
        (answer) => answer.id === logId
      );

      if (existLog) {
        setActiveResponseTabKey(existLog.id);
        searchParams.delete('log_id');
        setSearchParams(searchParams);
      } else {
        try {
          onLoading();
          const log = await queryLogService.getDataAnalysisQueryLogById(logId);
          if (log) {
            await onClickQuery(log);
            searchParams.delete('log_id');
            setSearchParams(searchParams);
          } else {
            toast.error(t("error.tryAgain"));
          }
        } catch (error) {
          toast.error(t("error.tryAgain"));
        } finally {
          onCompleted();
        }
      }
    }
  }, [logId, onClickQuery, onCompleted, onLoading, dataAnalysisQueryLogsAnswers, queryLogService, searchParams, setSearchParams, t]);

  useEffect(() => {
    executeByParamsLogId();
  }, [executeByParamsLogId]);

  const onDragAndRemoveTabs = (items: TabItemModel[]) => {
    const newAnswers = items.map((item) => ({
      ...(dataAnalysisQueryLogsAnswers.find((log) => log.id === item.key) ||
        ({} as IQueryLogWithAnswerModel)),
    }));
    onChangeDataAnalysisQueryLogsAnswers(newAnswers);
  };

  const setUserOptions = () =>
    users
      .filter((user) => user.email)
      .map((user) => ({
        value: user.email,
        label: user.email,
        id: user.id,
        key: user.id,
      }));

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <div className="query-logs-page-container">
      <div className="left-side">
        <div className="inputContainer">
          <Input
            value={queryLogId}
            onChange={(val) => setQueryLogId(val as string)}
            onKeyDown={(e) => {
              if (e.key === "Enter") onClickExecute();
            }}
          />
          <Button disabled={!queryLogId} onClick={onClickExecute}>
            Search By Id
          </Button>
        </div>
        <div className="inputContainer">
          <Select
            className="user_dropdown"
            showSearch
            placeholder="Select a person"
            filterOption={filterOption}
            optionFilterProp="children"
            value={queryLogEmail}
            onChange={(val) => setQueryLogEmail(val as string)}
            options={setUserOptions()}
          ></Select>
          <Button disabled={!queryLogEmail} onClick={getQueryLogsByEmail}>
            Search By Email
          </Button>
        </div>
        <div className="inputContainer">
          <Button onClick={getAllQueries}>
            Refresh List
          </Button>
        </div>
        <div className="prompt-container">
          <ul>
            {queryLogsByEmail
              .sort(
                (a, b) =>
                  timestampToDate(b.requestTime!)!.getTime() -
                  timestampToDate(a.requestTime!)!.getTime()
              )
              .map((log) => {
                return (
                  <li
                    style={{ border: "1px solid teal" }}
                    key={log.id}
                    onClick={() => onClickQuery(log)}
                  >
                    {`${log.user_query} ( ${log.id} )`}
                  </li>
                );
              })}
            {dataAnalysisQueryLogs.map((log) => {
              return (
                <li key={log.id} onClick={() => onClickQuery(log)}>
                  {`${log.user_query} ( ${log.id} )`}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <div className="right-side">
        <div className="response_tab_container">
          <DraggableTabs
            items={setResponseTabItems()}
            activeKey={activeResponseTabKey}
            onChangeActiveKey={onResponseTabClick}
            onDragAndRemove={onDragAndRemoveTabs}
          />
          {/* <Tab activeTab={activeResponseTab} tabs={setResponseTabItems()} onTabClick={onResponseTabClick} /> */}
        </div>
      </div>
    </div>
  );
};

export default memo(DataAnalysisLogs);
