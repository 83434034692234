import { createContext, useState, useContext } from "react";
import {
  IQueryLogResponseModel,
  IQueryLogWithAnswerModel,
} from "../../services/ui-services/queryLogsService/types";
import { IPatientFormModel } from "../../pages/HealthCreatePatient/CreateUpdatePatient/types";
import { IQueryLogHealthResponseModel } from "../../services/ui-services/healthQueryLogsService/types";
import { IQueryChatLogHealthResponseModel } from "../../services/ui-services/healthQueryChatLogsService/types";
import { ILogResponseModel } from "../../services/ui-services/types";

interface UtilContextType {
  healthLLMLogs: ILogResponseModel[];
  onChangeHealthLLMLogs: (res: ILogResponseModel[]) => void;
  fundLLMLogs: ILogResponseModel[];
  onChangeFundLLMLogs: (res: ILogResponseModel[]) => void;
  workCubeLLMLogs: ILogResponseModel[];
  onChangeWorkCubeLLMLogs: (res: ILogResponseModel[]) => void;
  workCubeInEngLLMLogs: ILogResponseModel[];
  onChangeWorkCubeInEngLLMLogs: (res: ILogResponseModel[]) => void;
  yevmiyeSQLLLMLogs: ILogResponseModel[];
  onChangeYevmiyeSQLLLMLogs: (res: ILogResponseModel[]) => void;
  yevmiyeLLMLogs: ILogResponseModel[];
  onChangeYevmiyeLLMLogs: (res: ILogResponseModel[]) => void;
  dataAnalysisLLMLogs: ILogResponseModel[];
  onChangeDataAnalysisLLMLogs: (res: ILogResponseModel[]) => void;
  baseLLMLogs: ILogResponseModel[];
  onChangeBaseLLMLogs: (res: ILogResponseModel[]) => void;
  hospitalLLMLogs: ILogResponseModel[];
  onChangeHospitalLLMLogs: (res: ILogResponseModel[]) => void;
  hospitalInEngLLMLogs: ILogResponseModel[];
  onChangeHospitalInEngLLMLogs: (res: ILogResponseModel[]) => void;
  yevmiyeQueryLogs: IQueryLogResponseModel[];
  onChangeYevmiyeQueryLogs: (res: IQueryLogResponseModel[]) => void;
  fundQueryLogs: IQueryLogResponseModel[];
  onChangeFundQueryLogs: (res: IQueryLogResponseModel[]) => void;
  workCubeQueryLogs: IQueryLogResponseModel[];
  onChangeWorkCubeQueryLogs: (res: IQueryLogResponseModel[]) => void;
  workCubeInEngQueryLogs: IQueryLogResponseModel[];
  onChangeWorkCubeInEngQueryLogs: (res: IQueryLogResponseModel[]) => void;
  dataAnalysisQueryLogs: IQueryLogResponseModel[];
  onChangeDataAnalysisQueryLogs: (res: IQueryLogResponseModel[]) => void;
  baseQueryLogs: IQueryLogResponseModel[];
  onChangeBaseQueryLogs: (res: IQueryLogResponseModel[]) => void;
  hospitalQueryLogs: IQueryLogResponseModel[];
  onChangeHospitalQueryLogs: (res: IQueryLogResponseModel[]) => void;
  hospitalInEngQueryLogs: IQueryLogResponseModel[];
  onChangeHospitalInEngQueryLogs: (res: IQueryLogResponseModel[]) => void;
  yevmiyeSQLQueryLogs: IQueryLogResponseModel[];
  onChangeYevmiyeSQLQueryLogs: (res: IQueryLogResponseModel[]) => void;
  healthQueryLogs: IQueryLogHealthResponseModel[];
  onChangeHealthQueryLogs: (res: IQueryLogHealthResponseModel[]) => void;
  healthChatQueryLogs: IQueryChatLogHealthResponseModel[];
  onChangeHealthChatQueryLogs: (res: IQueryChatLogHealthResponseModel[]) => void;
  healthChatQueryLogsAnswers: IQueryChatLogHealthResponseModel[];
  onChangeHealthChatQueryLogsAnswers: (res: IQueryChatLogHealthResponseModel[]) => void;
  healthQueryLogsAnswers: IQueryLogHealthResponseModel[];
  onChangeHealthQueryLogsAnswers: (res: IQueryLogHealthResponseModel[]) => void;
  yevmiyeQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeYevmiyeQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  fundQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeFundQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  workCubeQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeWorkCubeQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  workCubeInEngQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeWorkCubeInEngQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  dataAnalysisQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeDataAnalysisQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  baseQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeBaseQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  hospitalQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeHospitalQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  hospitalInEngQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeHospitalInEngQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  yevmiyeSQLQueryLogsAnswers: IQueryLogWithAnswerModel[];
  onChangeYevmiyeSQLQueryLogsAnswers: (res: IQueryLogWithAnswerModel[]) => void;
  uploadedPatient: IPatientFormModel | null;
  onChangeUploadedPatient: (arg: null | IPatientFormModel) => void;
}

let UtilContext = createContext<UtilContextType>(null!);

function UtilContextProvider({ children }: { children: React.ReactNode }) {
  const [healthLLMLogs, setHealthLLMLogs] = useState<ILogResponseModel[]>([]);

  const [fundLLMLogs, setFundLLMLogs] = useState<ILogResponseModel[]>([]);

  const [workCubeLLMLogs, setWorkCubeLLMLogs] = useState<ILogResponseModel[]>([]);

  const [workCubeInEngLLMLogs, setWorkCubeInEngLLMLogs] = useState<ILogResponseModel[]>([]);

  const [yevmiyeSQLLLMLogs, setYevmiyeSQLLLMLogs] = useState<ILogResponseModel[]>([]);

  const [yevmiyeLLMLogs, setYevmiyeLLMLogs] = useState<ILogResponseModel[]>([]);

  const [dataAnalysisLLMLogs, setDataAnalysisLLMLogs] = useState<ILogResponseModel[]>([]);

  const [baseLLMLogs, setBaseLLMLogs] = useState<ILogResponseModel[]>([]);

  const [hospitalLLMLogs, setHospitalLLMLogs] = useState<ILogResponseModel[]>([]);

  const [hospitalInEngLLMLogs, setHospitalInEngLLMLogs] = useState<ILogResponseModel[]>([]);

  const [yevmiyeQueryLogs, setYevmiyeQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [fundQueryLogs, setFundQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [workCubeQueryLogs, setWorkCubeQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [workCubeInEngQueryLogs, setWorkCubeInEngQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [dataAnalysisQueryLogs, setDataAnalysisQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [baseQueryLogs, setBaseQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [hospitalQueryLogs, setHospitalQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [hospitalInEngQueryLogs, setHospitalInEngQueryLogs] = useState<IQueryLogResponseModel[]>([]);

  const [yevmiyeSQLQueryLogs, setYevmiyeSQLQueryLogs] = useState<IQueryLogResponseModel[]>(
    []
  );
  const [healthQueryLogs, setHealthQueryLogs] = useState<IQueryLogHealthResponseModel[]>(
    []
  );
  const [healthQueryLogsAnswers, setHealthQueryLogsAnswers] = useState<IQueryLogHealthResponseModel[]>(
    []
  );
  const [healthChatQueryLogs, setHealthChartQueryLogs] = useState<IQueryChatLogHealthResponseModel[]>(
    []
  );
  const [healthChatQueryLogsAnswers, setHealthChatQueryLogsAnswers] = useState<IQueryChatLogHealthResponseModel[]>(
    []
  );

  const [yevmiyeQueryLogsAnswers, setYevmiyeQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [fundQueryLogsAnswers, setFundQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [workCubeQueryLogsAnswers, setWorkCubeQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [workCubeInEngQueryLogsAnswers, setWorkCubeInEngQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [dataAnalysisQueryLogsAnswers, setDataAnalysisQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [baseQueryLogsAnswers, setBaseQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [hospitalQueryLogsAnswers, setHospitalQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [hospitalInEngQueryLogsAnswers, setHospitalInEngQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [yevmiyeSQLQueryLogsAnswers, setYevmiyeSQLQueryLogsAnswers] = useState<
    IQueryLogWithAnswerModel[]
  >([]);
  const [uploadedPatient, setUploadedPatient] =
    useState<null | IPatientFormModel>(null);

  const onChangeUploadedPatient = (arg: null | IPatientFormModel) => {
    setUploadedPatient(arg);
  };

  const onChangeHealthLLMLogs = (res: ILogResponseModel[]) => {
    setHealthLLMLogs(res);
  };

  const onChangeFundLLMLogs = (res: ILogResponseModel[]) => {
    setFundLLMLogs(res);
  };

  const onChangeWorkCubeLLMLogs = (res: ILogResponseModel[]) => {
    setWorkCubeLLMLogs(res);
  };

  const onChangeWorkCubeInEngLLMLogs = (res: ILogResponseModel[]) => {
    setWorkCubeInEngLLMLogs(res);
  };

  const onChangeYevmiyeSQLLLMLogs = (res: ILogResponseModel[]) => {
    setYevmiyeSQLLLMLogs(res);
  };

  const onChangeYevmiyeLLMLogs = (res: ILogResponseModel[]) => {
    setYevmiyeLLMLogs(res);
  };

  const onChangeDataAnalysisLLMLogs = (res: ILogResponseModel[]) => {
    setDataAnalysisLLMLogs(res);
  };

  const onChangeBaseLLMLogs = (res: ILogResponseModel[]) => {
    setBaseLLMLogs(res);
  };

  const onChangeHospitalLLMLogs = (res: ILogResponseModel[]) => {
    setHospitalLLMLogs(res);
  };

  const onChangeHospitalInEngLLMLogs = (res: ILogResponseModel[]) => {
    setHospitalInEngLLMLogs(res);
  };

  const onChangeYevmiyeQueryLogs = (res: IQueryLogResponseModel[]) => {
    setYevmiyeQueryLogs(res);
  };

  const onChangeFundQueryLogs = (res: IQueryLogResponseModel[]) => {
    setFundQueryLogs(res);
  };

  const onChangeWorkCubeQueryLogs = (res: IQueryLogResponseModel[]) => {
    setWorkCubeQueryLogs(res);
  };

  const onChangeWorkCubeInEngQueryLogs = (res: IQueryLogResponseModel[]) => {
    setWorkCubeInEngQueryLogs(res);
  };

  const onChangeDataAnalysisQueryLogs = (res: IQueryLogResponseModel[]) => {
    setDataAnalysisQueryLogs(res);
  };

  const onChangeBaseQueryLogs = (res: IQueryLogResponseModel[]) => {
    setBaseQueryLogs(res);
  };

  const onChangeHospitalQueryLogs = (res: IQueryLogResponseModel[]) => {
    setHospitalQueryLogs(res);
  };

  const onChangeHospitalInEngQueryLogs = (res: IQueryLogResponseModel[]) => {
    setHospitalInEngQueryLogs(res);
  };

  const onChangeYevmiyeSQLQueryLogs = (res: IQueryLogResponseModel[]) => {
    setYevmiyeSQLQueryLogs(res);
  };

  const onChangeHealthQueryLogs = (res: IQueryLogHealthResponseModel[]) => {
    setHealthQueryLogs(res);
  };

  const onChangeHealthChatQueryLogs = (res: IQueryChatLogHealthResponseModel[]) => {
    setHealthChartQueryLogs(res);
  };

  const onChangeHealthChatQueryLogsAnswers = (res: IQueryChatLogHealthResponseModel[]) => {
    setHealthChatQueryLogsAnswers(res);
  };

  const onChangeHealthQueryLogsAnswers = (res: IQueryLogHealthResponseModel[]) => {
    setHealthQueryLogsAnswers(res);
  };

  const onChangeYevmiyeQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setYevmiyeQueryLogsAnswers(res);
  };

  const onChangeFundQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setFundQueryLogsAnswers(res);
  };

  const onChangeWorkCubeQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setWorkCubeQueryLogsAnswers(res);
  };

  const onChangeWorkCubeInEngQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setWorkCubeInEngQueryLogsAnswers(res);
  };

  const onChangeDataAnalysisQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setDataAnalysisQueryLogsAnswers(res);
  };

  const onChangeBaseQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setBaseQueryLogsAnswers(res);
  };

  const onChangeHospitalQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setHospitalQueryLogsAnswers(res);
  };

  const onChangeHospitalInEngQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setHospitalInEngQueryLogsAnswers(res);
  };

  const onChangeYevmiyeSQLQueryLogsAnswers = (res: IQueryLogWithAnswerModel[]) => {
    setYevmiyeSQLQueryLogsAnswers(res);
  };

  let value = {
    healthLLMLogs,
    onChangeHealthLLMLogs,
    uploadedPatient,
    onChangeUploadedPatient,
    yevmiyeQueryLogs,
    onChangeYevmiyeQueryLogs,
    yevmiyeQueryLogsAnswers,
    onChangeYevmiyeQueryLogsAnswers,
    yevmiyeSQLQueryLogsAnswers,
    onChangeYevmiyeSQLQueryLogsAnswers,
    yevmiyeSQLQueryLogs,
    onChangeYevmiyeSQLQueryLogs,
    healthQueryLogs,
    onChangeHealthQueryLogs,
    healthChatQueryLogs,
    onChangeHealthChatQueryLogs,
    healthChatQueryLogsAnswers,
    onChangeHealthChatQueryLogsAnswers,
    healthQueryLogsAnswers,
    onChangeHealthQueryLogsAnswers,
    fundQueryLogsAnswers,
    onChangeFundQueryLogsAnswers,
    fundQueryLogs,
    onChangeFundQueryLogs,
    fundLLMLogs,
    onChangeFundLLMLogs,
    hospitalQueryLogsAnswers,
    onChangeHospitalQueryLogsAnswers,
    hospitalQueryLogs,
    onChangeHospitalQueryLogs,
    hospitalLLMLogs,
    onChangeHospitalLLMLogs,
    baseQueryLogs,
    onChangeBaseQueryLogs,
    baseQueryLogsAnswers,
    onChangeBaseQueryLogsAnswers,
    baseLLMLogs,
    onChangeBaseLLMLogs,
    dataAnalysisQueryLogs,
    onChangeDataAnalysisQueryLogs,
    dataAnalysisQueryLogsAnswers,
    onChangeDataAnalysisQueryLogsAnswers,
    dataAnalysisLLMLogs,
    onChangeDataAnalysisLLMLogs,
    yevmiyeLLMLogs,
    onChangeYevmiyeLLMLogs,
    yevmiyeSQLLLMLogs,
    onChangeYevmiyeSQLLLMLogs,
    hospitalInEngQueryLogsAnswers,
    onChangeHospitalInEngQueryLogsAnswers,
    hospitalInEngQueryLogs,
    onChangeHospitalInEngQueryLogs,
    hospitalInEngLLMLogs,
    onChangeHospitalInEngLLMLogs,
    workCubeQueryLogsAnswers,
    onChangeWorkCubeQueryLogsAnswers,
    workCubeQueryLogs,
    onChangeWorkCubeQueryLogs,
    workCubeLLMLogs,
    onChangeWorkCubeLLMLogs,
    workCubeInEngQueryLogsAnswers,
    onChangeWorkCubeInEngQueryLogsAnswers,
    workCubeInEngQueryLogs,
    onChangeWorkCubeInEngQueryLogs,
    workCubeInEngLLMLogs,
    onChangeWorkCubeInEngLLMLogs
  };

  return <UtilContext.Provider value={value}>{children}</UtilContext.Provider>;
}

const useUtilContext = (): UtilContextType => {
  return useContext(UtilContext);
};

export { useUtilContext, UtilContextProvider };
