import PatientDBService from "../../db-services/patientDBService";
import { PatientDBModel } from "../../db-services/patientDBService/types";
import {
  ICreateUpdatePatientRequestModel,
  IMakeDiagnosisInputModel,
  IMakeDiagnosisOutputModel,
  IMakeTriageInputModel,
  IMakeTriageOutputModel,
} from "./types";
import { Api } from "../../../api";

class PatientService {
  private dbService;

  constructor() {
    this.dbService = new PatientDBService();
  }

  private async isExistPatient(patientId: string, id?: string) {
    try {
      const patient = await this.dbService.getByPatientId(patientId);
      if (patient && Object.keys(patient).length && id !== patient?.id) {
        throw new Error("PatientID must be unique!");
      }
    } catch (error) {
      throw error;
    }
  }

  async create(payload: ICreateUpdatePatientRequestModel): Promise<boolean> {
    try {
      await this.isExistPatient(payload.patientID);
      const response = await this.dbService.create(payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(
    id: string,
    payload: ICreateUpdatePatientRequestModel
  ): Promise<boolean> {
    try {
      await this.isExistPatient(payload.patientID, id);
      const response = await this.dbService.update(id, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const response = await this.dbService.delete(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAll(): Promise<Array<PatientDBModel>> {
    try {
      const response = await this.dbService.getAll();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getById(id: string): Promise<PatientDBModel | null> {
    try {
      const response = await this.dbService.getById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async makeDiagnosis(
    payload: IMakeDiagnosisInputModel
  ): Promise<IMakeDiagnosisOutputModel> {
    try {
      const response: IMakeDiagnosisOutputModel = await Api.post(
        "/make_diagnosis",
        payload,
        {}
      );
      this.dbService.setDiagnosis(
        payload.firebase_document_id,
        response?.answer ?? ''
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async makeTriage(
    payload: IMakeTriageInputModel
  ): Promise<IMakeTriageOutputModel> {
    try {
      const response: IMakeTriageOutputModel = await Api.post(
        "/make_triage2",
        payload,
        {}
      );
      this.dbService.setTriage(
        payload.firebase_document_id,
        '',
        response?.answer ?? ''
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default PatientService;
