export enum ProjectTypes {
    Yevmiye,
    Health,
    Base,
    DataAnalysis,
    Fund,
    Hospital,
    HospitalInEng,
    WorkCube,
    WorkCubeInEng
}