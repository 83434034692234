import { FC, ReactNode, useMemo } from "react";
import { useAuth } from "../context/store/authContext";
import { Navigate } from "react-router-dom";
import { PermissionTypes } from "../permissions";
import { ProjectProvider } from "../context/store/projectContext";

interface IRouteProps {
  children: ReactNode;
  permissionType?: PermissionTypes;
}

const PrivateRoute: FC<IRouteProps> = ({ children, permissionType }) => {
  const { loggedInUserData } = useAuth();

  const isAuthenticated = useMemo(() => !!loggedInUserData, [loggedInUserData]);

  if (
    isAuthenticated &&
    (!permissionType ||
      loggedInUserData?.permissions?.includes(permissionType))
  ) {
    return <ProjectProvider>{children}</ProjectProvider>;
  } else {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRoute;
