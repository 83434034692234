import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Form, Input, Button, Radio, Divider, Skeleton } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import HealthInstructionsService from "../../services/ui-services/healthInstructionsService";
import { toast } from "react-toastify";

const { TextArea } = Input;

interface Instruction {
  title: string;
  content: string;
  isDefault: boolean;
}

interface IInstructionModel {
  chatInstructions: Instruction[];
  triageInstructions: Instruction[];
  diagnosisInstructions: Instruction[];
}

const HealthInstructions: React.FC = () => {
  const healthInstructionsService = useMemo(
    () => new HealthInstructionsService(),
    []
  );

  const [values, setValues] = useState<IInstructionModel>({
    chatInstructions: [],
    diagnosisInstructions: [],
    triageInstructions: [],
  });
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const [form] = Form.useForm();

  const onValuesChange = (changedValues: any, allValues: IInstructionModel) => {
    const updateDefault = (instructions: Instruction[]) => {
      const defaultIndex = instructions.findIndex((inst) => inst.isDefault);
      if (defaultIndex !== -1) {
        instructions.forEach((inst, index) => {
          if (index !== defaultIndex && inst) inst.isDefault = false;
        });
      }
      return instructions;
    };

    if (changedValues.chatInstructions) {
      form.setFieldsValue({
        chatInstructions: updateDefault(allValues.chatInstructions),
      });
    }
    if (changedValues.triageInstructions) {
      form.setFieldsValue({
        triageInstructions: updateDefault(allValues.triageInstructions),
      });
    }
    if (changedValues.diagnosisInstructions) {
      form.setFieldsValue({
        diagnosisInstructions: updateDefault(allValues.diagnosisInstructions),
      });
    }
  };

  const renderInstructionFields = (instructionType: string) => (
    <Form.List name={instructionType}>
      {(fields, { add, remove }) => (
        <>
          {fields.map(({ key, name, fieldKey, ...restField }, index) => (
            <div key={key} style={{ marginBottom: 8 }}>
              <Form.Item
                {...restField}
                name={[name, "title"]}
                fieldKey={[fieldKey as any, "title"]}
                label="Title"
                rules={[{ required: true, message: "Title is required" }]}
              >
                <Input placeholder="Enter title" />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "content"]}
                fieldKey={[fieldKey as any, "content"]}
                label="Content"
                rules={[{ required: true, message: "Content is required" }]}
              >
                <TextArea placeholder="Enter content" rows={4} />
              </Form.Item>
              <Form.Item
                {...restField}
                name={[name, "isDefault"]}
                fieldKey={[fieldKey as any, "isDefault"]}
                valuePropName="checked"
              >
                <Radio.Group
                  value={form.getFieldValue([
                    instructionType,
                    index,
                    "isDefault",
                  ])}
                  onChange={() => {
                    const values = form.getFieldsValue()[instructionType];
                    values.forEach((item: Instruction, i: number) => {
                      item.isDefault = i === index;
                    });
                    form.setFieldsValue({ [instructionType]: values });
                  }}
                >
                  <Radio value={true}>Is Default</Radio>
                </Radio.Group>
              </Form.Item>
              {fields?.length > 1 && (
                <MinusCircleOutlined onClick={() => remove(name)} />
              )}
              {fields?.length && fields.length - 1 !== index && <Divider />}
            </div>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              icon={<PlusOutlined />}
              style={{ width: "100%" }}
            >
              Add Instruction
            </Button>
          </Form.Item>
          {instructionType !== "diagnosisInstructions" && <hr />}
        </>
      )}
    </Form.List>
  );

  const getIntructions = useCallback(async () => {
    try {
      const res = await healthInstructionsService.get();
      setValues({ ...res! });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [healthInstructionsService]);

  useEffect(() => {
    getIntructions();
  }, [getIntructions]);

  useEffect(() => {
    if (values) {
      form.setFieldsValue(values);
    }
  }, [form, values]);

  const areThereDefaultValues = (values: IInstructionModel) =>
    Object.values(values).every((inst) =>
      (inst as Array<Instruction>).some((i) => i.isDefault)
    );

  const onSubmit = async (values: IInstructionModel) => {
    try {
      if (!areThereDefaultValues(values)) {
        toast.warn('All instructions must have default value');
        return;
      }
      setUpdating(true);
      await healthInstructionsService.update(values);
      toast("Updated Successfully!");
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return <Skeleton active />;
  }

  return (
    <Form
      form={form}
      name="instruction_form"
      onFinish={onSubmit}
      onValuesChange={onValuesChange}
      layout="vertical"
    >
      <h2 style={{ marginBottom: "20px" }}>
        <b>Chat Instructions</b>
      </h2>
      {renderInstructionFields("chatInstructions")}

      <h2 style={{ marginBottom: "20px" }}>
        <b>Triage Instructions</b>
      </h2>
      {renderInstructionFields("triageInstructions")}

      <h2 style={{ marginBottom: "20px" }}>
        <b>Diagnosis Instructions</b>
      </h2>
      {renderInstructionFields("diagnosisInstructions")}

      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          loading={updating}
          disabled={updating}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default memo(HealthInstructions);
