import { FC, useEffect, useMemo, useState } from 'react'
import { IProps } from './types'
import { Input, Tabs, TabsProps } from 'antd'
import { AudioOutlined } from '@ant-design/icons'
import './styles.scss'

const { Search } = Input;

const sampleQuestions: string[] = [
    'How old is the given patient?',
    'What is the gender of the given patient?',
]

const QueryInput: FC<IProps> = ({ onSearch, disabled }) => {
    const [value, setValue] = useState('')
    const [activeTabKey, setActiveTabKey] = useState('1')
    const [listening, setListening] = useState(false)

    const speechRecognition = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition
        if (!SpeechRecognition) return null

        const instance = new SpeechRecognition()
        instance.lang = 'tr'
        instance.continuous = true
        return instance
    }, [])

    useEffect(() => {
        if (speechRecognition) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            speechRecognition.onresult = (event: any) => {
                const current = event.resultIndex
                const { transcript } = event.results[current][0]
                setValue((prevState) => `${prevState} ${transcript}`)
            }

            speechRecognition.onerror = () => {
                setListening(false)
            }
        }

        return () => {
            speechRecognition?.stop()
        }
    }, [speechRecognition])

    const onClickMicrophone = () => {
        if (disabled) return

        if (listening) {
            speechRecognition?.stop()
            setListening(false)
        } else {
            speechRecognition?.start()
            setListening(true)
        }
    }

    const suffix = speechRecognition ? <AudioOutlined className={`microphone ${listening && 'active'}`} onClick={onClickMicrophone} /> : undefined

    const onSubmit = (value: string) => {
        if (!value || value.startsWith('/')) return

        if (listening) {
            speechRecognition?.stop()
            setListening(false)
        }

        onSearch(value)
        setValue('')
    }

    const showSearchOptions = () => value.startsWith('/')

    const onClickSampleQuestion = (question: string) => {
        onSubmit(question)
    }

    const questionsContent = () => (
        <div className="questions_container">
            {sampleQuestions.map((question, i) => (
                <div className="question_item" key={i} onClick={() => onClickSampleQuestion(question)}>
                    {question}
                </div>
            ))}
        </div>
    )

    const tabItems: TabsProps['items'] = [
        {
            key: '1',
            label: 'Sample Questions',
            children: questionsContent(),
        },
    ]

    const setActiveKey = (val: string) => tabItems.find((item) => item.label?.toString().toLowerCase().includes(val.substring(1).toLowerCase()))?.key ?? '1'

    const onTyping = (val: string) => {
        setValue(val)
        const key = setActiveKey(val)
        setActiveTabKey(key)
    }

    return (
        <div className="query_input_container">
            {showSearchOptions() && (
                <div className="search_options_container">
                    <div className="search_options_content">
                        <Tabs activeKey={activeTabKey} onChange={(key) => setActiveTabKey(key)} items={tabItems} />
                    </div>
                </div>
            )}
            <Search
                placeholder="Input Search Text"
                enterButton="Search"
                size="large"
                suffix={suffix}
                onSearch={onSubmit}
                value={value}
                onChange={(e) => onTyping(e.target.value)}
                disabled={disabled}
            />
        </div>
    )
}

export default QueryInput
