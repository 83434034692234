import { Button, Dropdown, Menu, Skeleton, Table, Upload } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import PatientService from "../../services/ui-services/patientService";
import { IPatientsTableModel } from "./types";
import { ColumnsType } from "antd/es/table";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { useUtilContext } from "../../context/store/utilContext";
import { useProject } from "../../context/store/projectContext";

const HealthPatients = () => {
  const { onChangeUploadedPatient } = useUtilContext();
  const navigate = useNavigate();
  const [patients, setPatients] = useState<IPatientsTableModel[]>([]);
  const [loading, setLoading] = useState(false);
  const { projectType } = useProject();

  const patientService = useMemo(() => {
    return new PatientService();
  }, []);

  const getAllPatients = useCallback(async () => {
    try {
      setLoading(true);
      const res = await patientService.getAll();
      setPatients(
        res
          ?.sort(
            (a: any, b: any) =>
              new Date(b?.createdAt).getTime() -
              new Date(a?.createdAt).getTime()
          )
          ?.map((patient, i) => ({
            age: patient.age,
            key: patient.id,
            patientID: patient.patientID,
            sex: patient.sex,
            short_notes: patient.short_notes,
            sorterID: res.length - i,
          })) ?? []
      );
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [patientService]);

  useEffect(() => {
    getAllPatients();
  }, [getAllPatients]);

  const onClickViewPatient = (id: string) => {
    navigate(`/health-patients/${id}?project_type=${projectType}`);
  };

  const onClickUpdatePatient = (id: string) => {
    navigate(`/health-update-patient/${id}?project_type=${projectType}`);
  };

  const onClickDeletePatient = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      try {
        await patientService.delete(id);
        toast("Success");
        setPatients((prevState) =>
          prevState.filter((patient) => patient.key !== id)
        );
      } catch (error) {
        toast.warn((error as Error).message);
      }
    }
  };

  const setFilterOptions = (columnName: keyof IPatientsTableModel) => {
    const output: any = {};
    let filterOptions: any[] = [];

    patients.forEach((x) => {
      const value = x[columnName];
      if (value && !filterOptions.includes(value)) {
        filterOptions.push(value);
      }
    });

    output.filters = filterOptions.map((option) => ({
      text: option,
      value: option,
    }));

    output.filterMode = "tree";

    output.filterSearch = true;

    output.onFilter = (value: string, record: any) =>
      record[columnName] === value;

    return output;
  };

  const columns: ColumnsType<IPatientsTableModel> = [
    {
      title: "No",
      dataIndex: "sorterID",
      key: "sorterID",
    },
    {
      title: "Patient ID",
      dataIndex: "patientID",
      key: "patientID",
      render: (value, record) => (
        <div
          onClick={() => onClickViewPatient(record.key)}
          style={{ cursor: "pointer" }}
        >
          {value}
        </div>
      ),
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Sex",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "Note",
      dataIndex: "short_notes",
      key: "short_notes",
      render: (value) => (
        <div
          style={{
            whiteSpace: "nowrap",
            maxWidth: "300px",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {value}
        </div>
      ),
      width: "300px",
      ...setFilterOptions("short_notes"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (value, record) => (
        <Dropdown
          overlay={
            <Menu
              items={[
                {
                  key: "1",
                  label: "View",
                  onClick: () => onClickViewPatient(record.key),
                },
                {
                  key: "2",
                  label: "Update",
                  onClick: () => onClickUpdatePatient(record.key),
                },
                {
                  key: "3",
                  label: "Delete",
                  onClick: () => onClickDeletePatient(record.key),
                },
              ]}
            />
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <Button
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            size="small"
          />
        </Dropdown>
      ),
    },
  ];

  const onClickCreatePatient = () => {
    navigate(`/health-create-patient?project_type=${projectType}`);
  };

  const onClickDownload = () => {
    const link = document.createElement("a");
    link.href = "/samplePatient.json";
    link.download = "samplePatient.json";
    link.click();
    link.remove();
  };

  const handleUpload = (info: any) => {
    const { status } = info.file;
    if (status === "done") {
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const jsonData = JSON.parse(e.target?.result as any);
          if (Array.isArray(jsonData)) {
            toast.warn("You can upload single patient!");
          } else {
            onChangeUploadedPatient(jsonData);
            toast("You can edit your data. Not uploaded yet!");
            navigate("/health-create-patient");
          }
        } catch (err) {
          toast.warn("Not valid file!");
        }
      };
      reader.readAsText(info.file.originFileObj);
    } else if (status === "error") {
      toast.warn("Please try again!");
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".json",
    showUploadList: false,
    customRequest: ({ file, onSuccess }: any) => {
      setTimeout(() => {
        onSuccess("ok");
      }, 0);
    },
    onChange: handleUpload,
  };

  if (loading) {
    return <Skeleton />;
  }

  return (
    <>
      <Button
        onClick={onClickCreatePatient}
        type="primary"
        style={{ margin: "10px" }}
      >
        Create Patient
      </Button>
      <Button
        onClick={onClickDownload}
        type="primary"
        style={{ margin: "10px" }}
      >
        Download Sample Patient
      </Button>
      <Upload {...uploadProps}>
        <Button type="primary" style={{ margin: "10px" }}>
          Upload Patient
        </Button>
      </Upload>

      <Table dataSource={patients} columns={columns} scroll={{ x: 500}} />
    </>
  );
};

export default HealthPatients;
