import React, { FC, useEffect } from "react";
import { SpecialZoomLevel, Viewer } from "@react-pdf-viewer/core";
import { searchPlugin } from "@react-pdf-viewer/search";
import { pageNavigationPlugin } from "@react-pdf-viewer/page-navigation";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { getFilePlugin } from "@react-pdf-viewer/get-file";
import { renderError } from "./components/RenderError/RenderError";
import "./style.scss";

interface Props {
  fileName?: string;
  fileUrl: string;
  searchKeys?: string[];
  pageNumber?: number;
  zoomControl?: boolean;
  downloadDocument?: boolean;
}

const PDFViewer: FC<Props> = ({
  zoomControl,
  downloadDocument,
  fileUrl,
  searchKeys,
  pageNumber,
  fileName,
}) => {
  const searchPluginInstance = searchPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const zoomPluginInstance = zoomPlugin();
  const getFilePluginInstance = getFilePlugin();
  const { jumpToPage } = pageNavigationPluginInstance;
  const { clearHighlights, setTargetPages, highlight } = searchPluginInstance;
  const { Zoom } = zoomPluginInstance;
  const { DownloadButton } = getFilePluginInstance;

  const onLoadPDF = () => {
    if (pageNumber) {
      jumpToPage(pageNumber - 1);
    }
  };

  const handleReferance = async () => {
    if (searchPluginInstance && pageNumber && searchKeys?.length) {
      clearHighlights();
      setTargetPages((targetPage) => targetPage.pageIndex === pageNumber - 1);
      const searchText = searchKeys.join("");
      await highlight(searchText).catch(() => clearHighlights());
      jumpToPage(pageNumber - 1);
    }
  };

  useEffect(() => {
    handleReferance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeys, pageNumber]);

  return (
    <div className="pdf-viewer-container">
      <div className="controller-bar">
        <div className="left-side">
          {fileName && (
            <div className="pdf-name-info">
              <h1>{fileName}</h1>
            </div>
          )}
        </div>
        <div className="right-side">
          {zoomControl && (
            <div className="zoom-container">
              <Zoom />
            </div>
          )}
          {downloadDocument && (
            <div className="download-container">
              <DownloadButton />
            </div>
          )}
        </div>
      </div>

      <div className="pdf-viewer">
        {fileUrl && (
          <Viewer
            defaultScale={SpecialZoomLevel.PageFit}
            fileUrl={fileUrl}
            plugins={[
              searchPluginInstance,
              pageNavigationPluginInstance,
              zoomPluginInstance,
              getFilePluginInstance,
            ]}
            onDocumentLoad={onLoadPDF}
            renderError={renderError}
          />
        )}
      </div>
    </div>
  );
};

export default PDFViewer;
