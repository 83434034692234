import { Col, Row, Typography } from "antd";
import React from "react";
import { SmileOutlined } from "@ant-design/icons";
import "./style.scss";

const { Title, Paragraph } = Typography;

const Home = () => {
  return (
    <div className="home-page-container">
      <Row justify="center" align="middle" style={{ minHeight: "280px", minWidth: "500px" }}>
        <Col span={12} style={{ textAlign: "center" }}>
          <SmileOutlined style={{ fontSize: "48px", color: "#08c" }} />
          <Title level={2}>Welcome to Our Website!</Title>
          <Paragraph>
            We are delighted to have you here. Explore our services by navigating through the menu.
          </Paragraph>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
