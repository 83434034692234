import LayoutDBService from "../../db-services/layoutDBService";
import { LayoutDBModel } from "../../db-services/layoutDBService/types";
import { ProjectTypes } from "../../../models/ProjectTypes";
import { ICreateLayoutRequestModel, IUpdateLayoutRequestModel } from "./types";

class LayoutService {
  private dbService;

  constructor() {
    this.dbService = new LayoutDBService();
  }


  async create(payload: ICreateLayoutRequestModel): Promise<{ id: string }> {
    try {
      const response = await this.dbService.create(payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(
    id: string,
    payload: IUpdateLayoutRequestModel
  ): Promise<boolean> {
    try {
      const response = await this.dbService.update(id, payload);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAll(projectType: ProjectTypes): Promise<Array<LayoutDBModel>> {
    try {
      const response = await this.dbService.getProjectLayouts(projectType);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default LayoutService;
