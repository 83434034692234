import { User } from "firebase/auth";
import UsersDBServices from "../../db-services/userDBService";
import { IUserModel } from "./types";

class UserService {
    private dbService;

    constructor() {
        this.dbService = new UsersDBServices();
    }

    async create(userPayload: IUserModel): Promise<IUserModel> {
        try {
            await this.dbService.create(userPayload)
            return userPayload
        } catch (error) {
            console.error(error);
            throw error;
        }
    }


    async getAllUsers(): Promise<IUserModel[]> {
        try {
            const response = await this.dbService.getAll()
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async getUser(user: User): Promise<IUserModel> {
        try {
            const response = await this.dbService.getById(user)

            return response
        } catch (error) {

            console.error(error);
            throw error;
        }
    };
}

export default UserService