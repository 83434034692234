import React, { ChangeEvent, ReactNode, useEffect } from "react";
import "./style.scss";

interface InputProps {
  value: string | number;
  onChange: (value: string | number | React.ChangeEvent<Element>) => void;
  id?: string;
  label?: ReactNode;
  placeholder?: string;
  type?: React.HTMLInputTypeAttribute;
  error?: boolean;
  errorMessage?: string;
  max?: number;
  min?: number;
  decimalIncrease?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const Input: React.FC<InputProps> = (props) => {
  const {
    value,
    onChange,
    id,
    label,
    placeholder,
    type,
    error,
    errorMessage,
    max,
    min,
    decimalIncrease,
    onKeyDown,
  } = props;

  const [inputValue, setInputValue] = React.useState<string | number>(value);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (type === "number") {
      let currentInputValue = parseFloat(
        event.target.value || inputValue.toString()
      );

      if (min && currentInputValue < min) {
        currentInputValue = min;
      } else if (max && currentInputValue > max) {
        currentInputValue = max;
      }
      const currentValue = decimalIncrease
        ? currentInputValue.toFixed(1)
        : currentInputValue;

      onChange(currentValue);
    } else {
      setInputValue(event.target.value);
      onChange(event.target.value);
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  return (
    <fieldset
      className={`field-set ${error ? "field-set-error" : ""}
      `}
    >
      <legend className="legend">
        <label htmlFor={id}>{label}</label>
      </legend>
      <input
        className="input"
        id={id}
        step={decimalIncrease ? "0.1" : undefined}
        placeholder={placeholder}
        type={type || "text"}
        value={inputValue}
        onChange={handleChange}
        max={max}
        min={min}
        onKeyDown={onKeyDown}
      />
      {errorMessage && error && (
        <label className="error-message">{errorMessage}</label>
      )}
    </fieldset>
  );
};

export default Input;
