import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-services";
import { CreateLayoutDBPayloadModel, LayoutDBModel, UpdateLayoutDBPayloadModel } from "./types";
import { ProjectTypes } from "../../../models/ProjectTypes";

class LayoutDBService {
  private collectionName = "layouts";
  private dbRef = collection(db, this.collectionName);

  async create(payload: CreateLayoutDBPayloadModel): Promise<{ id: string }> {
    try {
      const docRef = await addDoc(this.dbRef, {
        ...payload,
        createdAt: new Date().toISOString(),
      });
      return { id: docRef.id };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(
    id: string,
    payload: UpdateLayoutDBPayloadModel
  ): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);

      await updateDoc(docRef, { id, ...payload });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async getProjectLayouts(projectType: ProjectTypes): Promise<LayoutDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.dbRef, where("projectType", "==", projectType))
      );

      const logs: LayoutDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as LayoutDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default LayoutDBService;
