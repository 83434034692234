import React, { FC, useEffect, useMemo, useState } from 'react';
import { IQueryResponseWithQuestionModel } from '../../../services/ui-services/collectionService/types';
import './style.scss';
import { CommentInputModel } from './types';
import { ValueOf } from '../../../models/Util';
import { Button, Rate, Input, Modal, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
interface Props {
    data: IQueryResponseWithQuestionModel;
    onSentComment: (data: IQueryResponseWithQuestionModel, successCallback: () => void) => Promise<void>
}

const { TextArea } = Input;

const CommentModal: FC<Props> = ({ data, onSentComment }) => {
    const [inputs, setInputs] = useState<CommentInputModel>({});
    const [showModal, setShowModal] = useState(false);
    const [sending, setSending] = useState(false);
    const [listening, setListening] = useState(false);

    const speechRecognition = useMemo(() => {
      const SpeechRecognition =
        window.SpeechRecognition || window.webkitSpeechRecognition;

      if (!SpeechRecognition) return null;
      const instance = new SpeechRecognition();
      instance.continuous = true;
      instance.lang = 'tr';
      return instance;
    }, []);

    const onClose = () => {
      setShowModal(false);
      setInputs({});
      setSending(false);
      speechRecognition?.stop();
      setListening(false);
    }

    useEffect(() => {
      setInputs({ request_comment: data?.request_comment ?? '', request_rating: data?.request_rating ?? 0 });
    }, [data, showModal]);

    const onChangeInputs = (key: keyof CommentInputModel, value: ValueOf<CommentInputModel>) => {
        setInputs((prevState) => ({
            ...prevState,
            [key]: value
        }))
    };

    useEffect(() => {
      if (speechRecognition) {
        speechRecognition.onresult = (event: any) => {
          const current = event.resultIndex;
          const { transcript } = event.results[current][0];
          onChangeInputs('request_comment', `${ inputs.request_comment } ${ transcript }`)
        };
      }
    }, [inputs.request_comment, speechRecognition]);

    const onClickMicrophone = () => {
      if (listening) {
        speechRecognition?.stop();
        setListening(false);
      } else {
        speechRecognition?.start();
        setListening(true);
      }
    };

    const isValid = () => inputs.request_comment && inputs.request_rating && !sending && ((inputs.request_comment || '') !== (data.request_comment || '') || (inputs.request_rating || 0) !== (data.request_rating || 0));

    const onClickSave = () => {
      if (isValid()) {
        setSending(true);
        onSentComment({ ...data, ...inputs }, onClose);
      }
    };

    const setButtonText = () => (data.request_comment || data.request_rating) ? 'Update Comment' : 'Create Comment'; 

  return (
    <div className='comment_container'>
      <Tooltip title="Send Feedback">
        <Button onClick={() => setShowModal(true)} icon={<FontAwesomeIcon icon={faThumbsUp} />} />
      </Tooltip>
      {showModal && (
        <Modal open={showModal} onCancel={onClose} title='Please Rate Your Experience' onOk={onClickSave} okText={setButtonText()} okButtonProps={{ loading: sending, disabled: !isValid() }}>
          <div className='modal_fields' style={{ marginBottom: '10px' }}>
            <Rate value={inputs.request_rating} onChange={(value) => onChangeInputs('request_rating', value)} />
          </div>
          {speechRecognition && (
            <div className="modal_fields microphone_container">
              <FontAwesomeIcon icon={faMicrophone} className={`microphone ${listening && 'active-microphone'}`} onClick={onClickMicrophone} />
            </div>
          )}
          <div className='modal_fields'>
            <TextArea
              className="text-input"
              value={inputs.request_comment || ''}
              onChange={(e) => onChangeInputs('request_comment', e.target.value)}
              style={{ resize: 'none' }}
              rows={5}
            />
          </div>
        </Modal>
      )}
    </div>
  )
}

export default CommentModal;