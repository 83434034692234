import PDFPackagesDBService from "../../db-services/documentDBService";
import { IDocumentModel, IPDFPackagePayloadModel, IPDFPackageResponseModel, IUpdatePDFPackageModel } from "./types";


class PDFPackageService {
    private dbService;

    constructor() {
        this.dbService = new PDFPackagesDBService();
    }

    async createPackage(payload: IPDFPackagePayloadModel): Promise<IPDFPackageResponseModel> {
        try {
            const response = await this.dbService.create(payload)
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async update(payload: IUpdatePDFPackageModel): Promise<boolean> {
        try {

            const isSuccess = await this.dbService.update(payload);
            return isSuccess;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteCollection(packageId: string): Promise<boolean> {
        try {

            const isSuccess = await this.dbService.deleteCollection(packageId);
            return isSuccess;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async deleteDocumentFromCollection(packageId: string, documentId: string): Promise<boolean> {
        try {

            const isSuccess = await this.dbService.deleteDocumentFromCollection(packageId, documentId);
            return isSuccess;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async addPDF(packageId: string, newPDF: IDocumentModel): Promise<boolean> {
        try {

            const isSuccess = await this.dbService.addPDF(packageId, newPDF);
            return isSuccess;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async getAllPackages(): Promise<IPDFPackageResponseModel[]> {
        try {
            const response = await this.dbService.getAllMyData()
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async listenToPackageDocumentSummaryStatusChanges(packageId: string, documentId: string, callback: (response: IPDFPackageResponseModel) => void) {
        try {
            await this.dbService.listenToPDFSummarizeStatusChanges(packageId, documentId, (status) => {
                callback(status)
            })

        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async listenToPackageDocumentStatusChanges(packageId: string, documentId: string, callback: (response: IPDFPackageResponseModel) => void) {
        try {
            await this.dbService.listenToPDFStatusChanges(packageId, documentId, (status) => {
                callback(status)
            })

        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async listenToPackagesChanges(callback: (response: boolean) => void) {
        try {
            await this.dbService.listenToChanges((data) => {
                callback(data)
            })

        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async getQuizById(id: string): Promise<IPDFPackageResponseModel | null> {
        try {
            const response = await this.dbService.getById(id)
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };
}

export default PDFPackageService
