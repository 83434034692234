import React, { ReactNode, memo, useEffect, useMemo, useState } from "react";
import "./style.scss";
import { toast } from "react-toastify";
import CollectionAWSService from "../../services/ui-services/collectionService/index";
import Tab from "../../components/Tab";
import { TabItem } from "../../components/Tab/types";
import { Tabs } from "../../models/Tabs";
// import PlotQueryForm from "./PlotQueryForm";
import Loader from "../../components/Loading/Loader";
import {
  IQueryResponseModel,
  IQueryResponseWithQuestionModel,
} from "../../services/ui-services/collectionService/types";
import { useAuth } from "../../context/store/authContext";
import DraggableTabs from "../../components/DraggableTabs";
import { TabItemModel } from "../../components/DraggableTabs/types";
import QueryLogService from "../../services/ui-services/queryLogsService";
import {
  ICreateUpdateCommentInputModel,
  IUpdateIndicatorInputModel,
} from "../../services/ui-services/queryLogsService/types";
import { useUtilContext } from "../../context/store/utilContext";
import { useTranslation } from "react-i18next";
import CommentModal from "../Yevmiye/CommentModal";
import TextQueryForm, { TextQueryType } from "../../components/TextQueryForm";
import DinamicTable from "../../components/DinamicTable";
import DownloadableImage from "../../components/DownloadableImage";
import { PermissionTypes } from "../../permissions";
import { Button, Switch, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Plot from "react-plotly.js";
import PlotSuggestionModal from "../../components/PlotSuggestionModal";

const tabItems: TabItem[] = [{ id: Tabs.TextQuery, name: "Örnek Sorular" }];

const queryResponseLocalStorageKey = "workCubeResponses";

const WorkCubeQuery: React.FC = () => {
  const { loggedInUserData } = useAuth();
  const { workCubeQueryLogsAnswers, onChangeWorkCubeQueryLogsAnswers } =
    useUtilContext();
  const { t } = useTranslation();

  const collectionAWSService = useMemo(() => {
    return new CollectionAWSService();
  }, []);

  const queryLogService = useMemo(() => {
    return new QueryLogService();
  }, []);

  const [queryResponses, setQueryResponses] = useState<
    IQueryResponseWithQuestionModel[]
  >([]);
  const [activeResponseTabKey, setActiveResponseTabKey] = useState<string>("");

  // const [plotQuery, setPlotQuery] = useState<string>("");
  // const [plotQueryResponse, setPlotQueryResponse] = useState<string>("");
  const [activeTab, setActiveTab] = useState<number>(Tabs.TextQuery);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isInteractive, setIsInteractive] = useState(true);
  const [suggestionModal, setSuggestionModal] = useState({ visible: false, query: '' })
  const maxResponseCount = 100;

  useEffect(() => {
    const res = localStorage.getItem(queryResponseLocalStorageKey);
    if (res) {
      const responses =
        JSON.parse(res)?.filter(
          (response: IQueryResponseModel) => response.fb_path
        ) || [];
      setQueryResponses(responses);
      setActiveResponseTabKey(
        (responses as IQueryResponseWithQuestionModel[])?.at(0)?.fb_path ?? ""
      );
    }
  }, []);

  const onChangeQueryResponses = (value: IQueryResponseWithQuestionModel[]) => {
    setQueryResponses([...value]);
    localStorage.setItem(queryResponseLocalStorageKey, JSON.stringify(value));
  };

  const onSentComment = async (
    data: IQueryResponseWithQuestionModel,
    successCallback: () => void
  ) => {
    try {
      const id = data.fb_path.replace(`${queryLogService.getWorkCubeCollectionName()}/`, "");
      const input: ICreateUpdateCommentInputModel = {
        request_comment: data.request_comment,
        request_rating: data.request_rating,
      };
      await queryLogService.createUpdateWorkCubeComment(id, input);

      let existResponses = queryResponses;
      const responseIndex = existResponses.findIndex(
        (res) => res.fb_path === data.fb_path
      );
      if (responseIndex !== -1) {
        existResponses[responseIndex] = data;
        onChangeQueryResponses(existResponses);
      }

      let existLogAnswers = workCubeQueryLogsAnswers;
      const answerIndex = existLogAnswers.findIndex(
        (answer) => answer.id === id
      );
      if (answerIndex !== -1) {
        existLogAnswers[answerIndex].request_comment = data.request_comment;
        existLogAnswers[answerIndex].request_rating = data.request_rating;

        onChangeWorkCubeQueryLogsAnswers(existLogAnswers);
      }

      successCallback();
    } catch (error) {
      toast.error(t("error.tryAgain"));
      console.log(error);
    }
  };

  const onCreateIndicator = async (data: IQueryResponseWithQuestionModel) => {
    if (window.confirm("Are you sure?")) {
      try {
        const id = data.fb_path.replace(`${queryLogService.getWorkCubeCollectionName()}/`, "");
        const input: IUpdateIndicatorInputModel = {
          isIndicator: true,
        };
        await queryLogService.createUpdateWorkCubeIndicator(id, input);

        let existResponses = queryResponses;
        const responseIndex = existResponses.findIndex(
          (res) => res.fb_path === data.fb_path
        );
        if (responseIndex !== -1) {
          existResponses[responseIndex].isIndicator = true;
          onChangeQueryResponses(existResponses);
        }
      } catch (error) {
        toast.error(t("error.tryAgain"));
        console.log(error);
      }
    }
  };

  const cacheResponses = (response: IQueryResponseModel, query: string) => {
    let existResponses = queryResponses;
    existResponses.unshift({ ...response, question: query });
    existResponses = existResponses.slice(0, maxResponseCount);
    onChangeQueryResponses(existResponses);
    setActiveResponseTabKey(response.fb_path);
  };

  const showId = () =>
    loggedInUserData?.permissions?.includes(
      PermissionTypes.ViewWorkCubeLogsPage
    );

  const sendQuery = async (query: string, successCallback?: VoidFunction) => {
    try {
      setIsLoading(true);
      await collectionAWSService
        .tabularDataInsight(query, loggedInUserData?.email ?? "", isInteractive, 'workcube.csv')
        .then((response) => {
          if (!response.result_status) {
            toast.error(response.error_message);
          } else {
            cacheResponses(response, query);
          }
        });
        if (successCallback) {
          successCallback();
        }
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickSendQuery = (query: string, successCallback: VoidFunction) => {
    if (isInteractive) {
      setSuggestionModal({ visible: true, query })
    } else {
      sendQuery(query, successCallback);
    }
  };

  const onTabClick = (id: number) => setActiveTab(id);

  const responseViewer = (
    response: IQueryResponseWithQuestionModel
  ): ReactNode => {
    const { answer, question, result_type, fb_path } = response;
    if (Array.isArray(answer)) {
      return (
        <div>
          {showId() && <div className="question-container">ID: {fb_path}</div>}
          <div className="question-container">Question: {question}</div>
          <div className="question-actions-container">
            <CommentModal data={response} onSentComment={onSentComment} />
            {!response.isIndicator && (
              <Tooltip title="Set as Indicator">
                <Button
                  onClick={() => onCreateIndicator(response)}
                  icon={<FontAwesomeIcon icon={faStar} />}
                />
              </Tooltip>
            )}
          </div>
          <DinamicTable items={answer} />
        </div>
      );
    } else if (result_type === "plot") {
      return (
        <div>
          {showId() && <div className="question-container">ID: {fb_path}</div>}
          <div className="question-container">Question: {question}</div>
          <div className="question-actions-container">
            <CommentModal data={response} onSentComment={onSentComment} />
            {!response.isIndicator && (
              <Tooltip title="Set as Indicator">
                <Button
                  onClick={() => onCreateIndicator(response)}
                  icon={<FontAwesomeIcon icon={faStar} />}
                />
              </Tooltip>
            )}
          </div>
          <DownloadableImage
            src={`https://dev-information-retrieval-store.s3.us-west-2.amazonaws.com/data_insight_images/${answer}`}
            alt="fund_img"
            style={{ width: "100%" }}
          />
        </div>
      );
    } else if (result_type === "plotly") {
      return (
        <div>
          {showId() && <div className="question-container">ID: {fb_path}</div>}
          <div className="question-container">Question: {question}</div>
          <div className="question-actions-container">
            <CommentModal data={response} onSentComment={onSentComment} />
            {!response.isIndicator && (
              <Tooltip title="Set as Indicator">
                <Button
                  onClick={() => onCreateIndicator(response)}
                  icon={<FontAwesomeIcon icon={faStar} />}
                />
              </Tooltip>
            )}
          </div>
          <Plot {...JSON.parse(answer as any)} style={{ width: "100%" }} />
        </div>
      );
    } else {
      return (
        <div>
          {showId() && <div className="question-container">ID: {fb_path}</div>}
          <div className="question-container">Question: {question}</div>
          <div className="question-actions-container">
            <CommentModal data={response} onSentComment={onSentComment} />
            {!response.isIndicator && (
              <Tooltip title="Set as Indicator">
                <Button
                  onClick={() => onCreateIndicator(response)}
                  icon={<FontAwesomeIcon icon={faStar} />}
                />
              </Tooltip>
            )}
          </div>
          <p className="answer-text">{answer}</p>
        </div>
      );
    }
  };

  const setResponseTabItems = (): TabItemModel[] =>
    queryResponses.slice(0, maxResponseCount).map((res, i) => {
      return {
        key: res.fb_path,
        label: res.question.substring(0, 10),
        children: responseViewer(res),
        title: res.question,
      };
    });

  const onDragAndRemoveTabs = (items: TabItemModel[]) => {
    const newResponses: IQueryResponseWithQuestionModel[] = items.map(
      (item) => ({
        ...(queryResponses.find((response) => response.fb_path === item.key) ||
          ({} as IQueryResponseWithQuestionModel)),
      })
    );
    onChangeQueryResponses(newResponses);
  };

  return (
    <>
      {!!suggestionModal.query && (
        <PlotSuggestionModal onClose={() => setSuggestionModal({ visible: false, query: '' })} onSubmit={sendQuery} query={suggestionModal.query}  />
      )}
      <div className="fund-page-container">
        <div className="left-side">
          <div>
            <span>Interactive Plots</span>{" "}
            <Switch
              style={{ width: "50px" }}
              checked={isInteractive}
              onChange={(checked) => setIsInteractive(checked)}
            />
          </div>
          <div className="tab-container">
            <Tab activeTab={activeTab} tabs={tabItems} onTabClick={onTabClick} />
          </div>
          {/* {activeTab === Tabs.PlotQuery && (
            <PlotQueryForm
              handlePlotQuery={sendPlotQuery}
              plotQuery={plotQuery}
              setPlotQuery={setPlotQuery}
              responseData={plotQueryResponse}
              isLoading={isLoading}
            />
          )} */}
          {activeTab === Tabs.TextQuery && (
            <TextQueryForm
              handleTextQuery={onClickSendQuery}
              isLoading={isLoading}
              queryType={TextQueryType.WorkCube}
            />
          )}
          {isLoading && (
            <div className="loading-container">
              <Loader />
            </div>
          )}
        </div>
        <div className="right-side">
          <div className="response_tab_container">
            <DraggableTabs
              items={setResponseTabItems()}
              onDragAndRemove={onDragAndRemoveTabs}
              activeKey={activeResponseTabKey}
              onChangeActiveKey={(key) => setActiveResponseTabKey(key)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(WorkCubeQuery);
