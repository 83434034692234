import { createContext, useState, useContext } from "react";
import { UserResponseModel } from "../../services/ui-services/authService/types";
import { permissions } from "../../permissions";

export interface AuthContextType {
  loggedInUserData: UserResponseModel | null;
  updateLoggedInUserData: (data: UserResponseModel | null) => void;
}

let AuthContext = createContext<AuthContextType>(null!);

const getAuthData = () => {
  return JSON.parse(localStorage.getItem("userInfo")!);
};

const setPermission = (user: UserResponseModel | null) => {
  if (user) {
    user.permissions = permissions[user.role];
  };

  return user;
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  const userInfo = getAuthData();
  const [loggedInUserData, setLoggedInUserData] =
    useState<UserResponseModel | null>(setPermission(userInfo));

  const updateLoggedInUserData = (user: UserResponseModel | null) => {
    localStorage.setItem("userInfo", JSON.stringify(user));
    setLoggedInUserData(setPermission(user));
  };

  let value = { loggedInUserData, updateLoggedInUserData };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
};

export { useAuth, AuthProvider };
