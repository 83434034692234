import { Api } from "../../../api";
import {
  IGetDefaultInstructionsResponseModel,
  IHealthDeleteAssistantRequestModel,
  IHealthSendQueryRequestModel,
  IHealthSendQueryResponseModel,
  IUpdateDefaultInstructionsRequestModel,
} from "./types";

class HealthAssistantService {
  async sendQuery(
    payload: IHealthSendQueryRequestModel
  ): Promise<IHealthSendQueryResponseModel> {
    try {
      const response = await Api.post("/assistant/", { ...payload }, {});
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async deleteAssistant(
    payload: IHealthDeleteAssistantRequestModel
  ): Promise<void> {
    try {
      const response = await Api.post("/delete_assistant/", payload, {});
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDefaultInstructions(): Promise<IGetDefaultInstructionsResponseModel> {
    try {
      const response = await Api.post("/get_default_health_instructions", {}, {});
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateDefaultInstructions(payload: IUpdateDefaultInstructionsRequestModel): Promise<void> {
    try {
      const response = await Api.post("/update_default_health_instructions", payload, {});
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default HealthAssistantService;
