import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../firebase-services";
import { getUserInfo } from "../../firebase-services/auth";
import { AddPDFToPackageDBModel, CreatePDFPackageDBModel, CreatePDFPackageDBPayload as CreatePDFPackagePayloadModel, PDFPackageResponseDBModel, UpdatePDFPackageDBModel } from "./types";
import { UserAuditDBModel } from "../userDBService/types";
import { StatusTypes } from "../../../models/Status";

class PDFPackageDBService {
    private collectionName = "PDFPackages";

    async create(packagePayload: CreatePDFPackagePayloadModel): Promise<PDFPackageResponseDBModel> {
        try {
            const currentUserInfo = getUserInfo();
            const audit = {
                lastModifierUserInfo: currentUserInfo,
                lastModificationTime: new Date(),
                creationTime: new Date(),
                creatorUserInfo: currentUserInfo,
            } as UserAuditDBModel;

            const PDFPackageCurrent: CreatePDFPackageDBModel = {
                ...audit,
                content: packagePayload
            };

            const docRef = await addDoc(collection(db, this.collectionName), PDFPackageCurrent);
            return { ...PDFPackageCurrent, id: docRef.id };
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async deleteCollection(packageId: string): Promise<boolean> {
        try {
            const packageRef = doc(db, this.collectionName, packageId);
            await deleteDoc(packageRef);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async deleteDocumentFromCollection(packageId: string, documentId: string): Promise<boolean> {

        try {
            const collectionRef = doc(db, this.collectionName, packageId);
            const collectionSnapshot = await getDoc(collectionRef);

            if (!collectionSnapshot.exists) {
                return false;
            }

            const contentData = collectionSnapshot.data()?.content;

            const pdfs = collectionSnapshot.data()?.content.PDFS || [];
            const updatedPdfs = pdfs.filter((pdf: any) => pdf.docId !== documentId);

            await updateDoc(collectionRef, { content: { ...contentData, PDFS: updatedPdfs } })
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async update(updatedPackagePayload: UpdatePDFPackageDBModel): Promise<boolean> {
        try {
            const packageRef = doc(db, this.collectionName, updatedPackagePayload.id);

            const updateData = {
                content: { ...updatedPackagePayload },
                lastModifierUserInfo: getUserInfo(),
                lastModificationTime: new Date(),
            };

            await updateDoc(packageRef, updateData);
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async addPDF(packageId: string, newPDF: AddPDFToPackageDBModel): Promise<boolean> {
        try {
            const packageRef = doc(db, this.collectionName, packageId);
            const packageSnapshot = await getDoc(packageRef);

            if (!packageSnapshot.exists()) {
                throw new Error('PDF paketi bulunamadı');
            }

            const currentUserInfo = getUserInfo();
            const existingPDFs = packageSnapshot.data()?.content?.PDFS || [];
            const updatedPDFs = [...existingPDFs, { ...newPDF }];

            await updateDoc(packageRef, {
                content: {
                    ...packageSnapshot.data()?.content,
                    PDFS: updatedPDFs,
                },
                lastModifierUserInfo: currentUserInfo,
                lastModificationTime: new Date(),
            });

            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    async getAllMyData(): Promise<PDFPackageResponseDBModel[]> {
        try {
            const currentUserInfo = getUserInfo();
            const querySnapshot = await getDocs(query(collection(db, this.collectionName),
                where('creatorUserInfo.id', '==', currentUserInfo.id),
            ));
            const packages: PDFPackageResponseDBModel[] = [];

            querySnapshot.forEach((doc) => {
                const packageData = doc.data() as PDFPackageResponseDBModel;
                packages.push({
                    ...packageData,
                    id: doc.id,
                });
            });
            return packages;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async listenToPDFSummarizeStatusChanges(packageId: string, documentId: string, callback: (response: PDFPackageResponseDBModel) => void) {
        try {


            const packageDocRef = doc(db, this.collectionName, packageId);


            const unsubscribe = onSnapshot(packageDocRef, (snapshot) => {
                if (snapshot.exists()) {
                    const packagedata = snapshot.data() as PDFPackageResponseDBModel;
                    const status = packagedata.content.PDFS.find((document) => document.docId === documentId)?.status

                    callback(packagedata);
                    if (status && status === StatusTypes.Done) {
                        unsubscribe();
                    }
                }
            });

            return unsubscribe;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async listenToPDFStatusChanges(packageId: string, documentId: string, callback: (response: PDFPackageResponseDBModel) => void) {
        try {


            const packageDocRef = doc(db, this.collectionName, packageId);

            const unsubscribe = onSnapshot(packageDocRef, (snapshot) => {
                if (snapshot.exists()) {
                    const packagedata = snapshot.data() as PDFPackageResponseDBModel;
                    const status = packagedata.content.PDFS.find((document) => document.docId === documentId)?.docStatus

                    callback(packagedata);
                    if (status && status === StatusTypes.Done) {
                        unsubscribe();
                    }
                }
            });

            return unsubscribe;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async listenToChanges(callback: (response: boolean) => void) {
        try {
            const collectionRef = collection(db, this.collectionName);
            const unsubscribe = onSnapshot(collectionRef, async (snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === 'modified') {
                        callback(true)

                    }
                });

            });

            return unsubscribe;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getAll(): Promise<PDFPackageResponseDBModel[]> {
        try {
            const querySnapshot = await getDocs(collection(db, this.collectionName));
            const packages: PDFPackageResponseDBModel[] = [];

            querySnapshot.forEach((doc) => {
                const packageData = doc.data() as PDFPackageResponseDBModel;
                packages.push({
                    ...packageData,
                    id: doc.id,
                });
            });
            return packages;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async getById(packageId: string): Promise<PDFPackageResponseDBModel | null> {
        try {
            const docRef = doc(db, this.collectionName, packageId);
            const docSnapshot = await getDoc(docRef);

            if (docSnapshot.exists()) {
                const packageData = docSnapshot.data() as PDFPackageResponseDBModel;
                return {
                    ...packageData,
                    id: docSnapshot.id,
                };
            } else {
                return null
            }
        } catch (error) {
            console.error(error);
            throw error
        }
    }
}

export default PDFPackageDBService;

