import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import CheckboxInput from "../CheckboxInput";
import { useTranslation } from "react-i18next";
import { IQuizResponseModel } from "../../services/ui-services/quizService/types";
import { IQuestionModel } from "../../services/ui-services/questionService/types";

interface IProps {
  quiz: IQuizResponseModel;
  checked?: boolean;
  handleSelectCart?: (value: IQuizResponseModel) => void;
  onDeleteCard?: (isSelected: boolean) => void;
}

const QuizBox: React.FC<IProps> = ({
  handleSelectCart,
  onDeleteCard,
  quiz,
  checked,
}) => {
  const { t } = useTranslation();
  const calculateAveragePoint = (questions: IQuestionModel[]) => {
    if (questions.length === 0) {
      return 0; // Eğer soru yoksa ortalamayı sıfır olarak döndür
    }

    const isCompleted = !questions.find((question) => question.point === null);

    const totalPoints = questions.reduce(
      (sum, question) => sum + (question.point ?? 0),
      0
    );
    const averagePoint = totalPoints / questions.length;
    return isCompleted ? averagePoint.toFixed(1) : "N/A";
  };

  const configInfo = quiz.content.configration;
  const questions = quiz.content.questions;
  return (
    <div className="quiz-box-container">
      <Link key={quiz.id} to={`/base-assignments/${quiz.id}`}>
        <div className="quiz-box">
          <span className="box-header">{quiz.content.name}</span>
          <div className="box-content">
            <div className="config-info">
              <div className="line">
                <span className="label">{t("quizBox.modelNumber")}</span>
                <span className="value">{configInfo.embed_model_number}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.topK")}</span>
                <span className="value">{configInfo.top_k}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.topN")}</span>
                <span className="value">{configInfo.top_n}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.engineName")}</span>
                <span className="value">{configInfo.engine_name}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.llmTemp")}</span>
                <span className="value">{configInfo.llm_temp}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.reductionType")}</span>
                <span className="value">{configInfo.reduction_type}</span>
              </div>
            </div>

            <div className="right-side">
              <div className="line">
                <span className="label">{t("quizBox.PDFPackage")}</span>
                <span className="value">{quiz.PDFPackageInfo.name}</span>
              </div>

              <div className="line">
                <span className="label">{t("quizBox.questionPackage")}</span>
                <span className="value">{quiz.questionPackageInfo.name}</span>
              </div>

              <div className="line">
                <span className="label">{t("quizBox.creator")}</span>
                <span className="value">{quiz.creatorUserInfo.email}</span>
              </div>
              <div className="line">
                <span className="label">{t("quizBox.assigned")}</span>
                <span className="value">{quiz.assignedUserInfo.email}</span>
              </div>
            </div>
            {/* <div className="questions-info">
              {!!questions.length &&
                questions.map((question, index) => {
                  return (
                    <div key={index} className="question-line">
                      <span className="question">{question.ask}</span>
                      <b className="point">{question.point ?? "N/A"}</b>
                    </div>
                  );
                })}
            </div> */}
          </div>
          <div className="box-footer">
            <span className="label">{t("quizBox.average")}</span>
            <span className="value">{calculateAveragePoint(questions)}</span>
          </div>
        </div>
      </Link>
      {handleSelectCart && (
        <div className="checkbox-container">
          <CheckboxInput
            className="checkbox"
            checked={checked}
            onChange={() => handleSelectCart(quiz)}
          />
        </div>
      )}
      {onDeleteCard && (
        <div className="checkbox-container">
          <CheckboxInput
            className="checkbox"
            checked={checked}
            onChange={() => onDeleteCard(!checked)}
          />
        </div>
      )}
    </div>
  );
};

export default QuizBox;
