import QueryChatLogHealthDBService from "../../db-services/queryChatLogHealthDBService";
import { QueryChatLogCommentModel } from "../../db-services/queryChatLogHealthDBService/types";
import { IQueryChatLogHealthResponseModel } from "./types";

class QueryHealthChatLogService {
  private dbService;

  constructor() {
    this.dbService = new QueryChatLogHealthDBService();
  }

  async createComments(comments: QueryChatLogCommentModel[]): Promise<string> {
    try {
      const response = await this.dbService.createComments(
        comments
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async updateCommentsById(
    id: string,
    comments: Array<QueryChatLogCommentModel>
  ) {
    try {
      const response = await this.dbService.updateCommentsById(
        id,
        comments
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllQueryLogs(
    dayAgo: number
  ): Promise<IQueryChatLogHealthResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogs(
        dayAgo
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(
    id: string
  ): Promise<IQueryChatLogHealthResponseModel | null> {
    try {
      const response = await this.dbService.getQueryLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(
    email: string
  ): Promise<IQueryChatLogHealthResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsByEmail(
        email
      );
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryHealthChatLogService;
