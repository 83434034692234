import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Checkbox, Button, Input } from "antd";
import React, { FC, useEffect, useState } from "react";

type Props = {
  query: string;
  onSubmit: (query: string, successCallback?: VoidFunction) => void;
  onClose: VoidFunction;
};

const PlotSuggestionModal: FC<Props> = ({ query, onSubmit, onClose }) => {
  const suggestions = [
    "En yüksek 5 tanesini sütunlar halinde yanyana göster.",
    "Geri kalanları DİĞER adı altında birleştir.",
    "Tekst datasının tamamını tut.",
    "Tekst datasının tamamının ilk 10 karakterini tut.",
    "Sayı datasının tamamını tut.",
    "Sayı datasını milyonlar seviyesinde round et ve tut.",
    "Eksenler üzerinde tekst datasının tamamının ilk 10 karakterini göster.",
    "Birim değerlerini gösteren sayıları MILYONLAR oranında round et.",
    "Grafik üzerine hover ettiğimde full precision sayı datasını göster.",
    "Grafik üzerine hover ettiğimde tekst datasının tamamını göster.",
    "Grafik üzerinde hover etmiyorsam sayıları round edilmiş şekilde göster.",
  ];

  const [selectedSuggestions, setSelectedSuggestions] = useState<string[]>([]);
  const [text, setText] = useState("");

  const handleCheckboxChange = (checked: boolean, suggestion: string) => {
    setSelectedSuggestions((prev) =>
      checked
        ? [...prev, suggestion]
        : prev.filter((item) => item !== suggestion)
    );
  };

  useEffect(() => {
    const modifiedQuery = `${query}, ${selectedSuggestions.join(", ")}`;
    setText(modifiedQuery);
  }, [query, selectedSuggestions]);

  const handleSubmit = () => {
    onSubmit(text);
    onClose();
  };

  return (
    <Modal
      title="Arama sonucunu iyileştirmek için aşağıdaki önerileri seçebilirsiniz."
      visible
      onCancel={onClose}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
          size="large"
        />,
      ]}
    >
      <Input.TextArea
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows={5}
        style={{ resize: "none", width: "100%", marginBottom: '10px' }}
      />
      {suggestions.map((suggestion) => (
        <div key={suggestion}>
          <Checkbox
            onChange={(e) => handleCheckboxChange(e.target.checked, suggestion)}
          >
            {suggestion}
          </Checkbox>
        </div>
      ))}
    </Modal>
  );
};

export default PlotSuggestionModal;
