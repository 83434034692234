import React from "react";
import Button from "../Button";

interface Column {
  label: string;
  key: string;
}

interface Props {
  data: any;
  filename: string;
  columns: Column[];
}

const CSVDownloadButton: React.FC<Props> = ({ data, filename, columns }) => {
  const downloadCSV = () => {
    const csvData = [
      columns
        .map((column) => {
          return column.label;
        })
        .join(","),
      ...data.map((row: any) => columns.map((col) => row[col.key]).join(",")),
    ];

    const csvContent = "data:text/csv;charset=utf-8," + csvData.join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", filename + ".csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return <Button onClick={downloadCSV}>CSV İndir</Button>;
};

export default CSVDownloadButton;
