import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { validateEmail, validatePassword } from "../../utils/helpers";
import SignUIService from "../../services/ui-services/authService";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/store/authContext";
import { useLoading } from "../../context/store/loadingContext";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import GoogleSignIn from "../../components/GoogleSignIn";
import GithubSignIn from "../../components/GithubSignIn";
// import LanguageSelector from "../../components/LanguageSelector";

const Login: React.FC = () => {
  const signUIService = useMemo(() => {
    return new SignUIService();
  }, [])

  const { t } = useTranslation();
  const { loggedInUserData, updateLoggedInUserData: setLoggedInUserData } =
    useAuth();
  const { onCompleted, onLoading } = useLoading();
  const navigate: NavigateFunction = useNavigate();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  useEffect(() => {
    if (loggedInUserData) {
      navigate("/");
    }
  }, [loggedInUserData, navigate]);

  const handleSignIn = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      onLoading();
      const response = await signUIService.logIn(email, password);
      if (response) {
        setLoggedInUserData(response);
      }
      return response;
    } catch (error) {
      console.log(error);
    } finally {
      onCompleted();
    }
  };

  useEffect(() => {
    const valid = validateEmail(email) && validatePassword(password);
    setIsValidForm(valid);
  }, [email, password]);

  return (
    <>
      <div className="login-page">
        <div className="login-page-wrapper">
          {/* <div className="language-container">
            <LanguageSelector />
          </div> */}
          {/* <div className="image-container">
            <img
              src="https://i.imgur.com/MYZd7of.png"
              alt=""
              className="group-image"
            />
          </div> */}
          <div className="login-form-container">
            <div className="login-form-wrapper">
              {/* <div className="logo-container">
              <img
                src="https://www.pngkey.com/png/full/529-5291672_sample-logo-png-transparent-background.png"
                alt=""
                className="logo"
              />
            </div> */}
              <header className="header">{t("login.welcome")}</header>
              {/* <header className="sub-header">
     
            </header> */}

              <form onSubmit={handleSignIn}>
                <div className="input-container">
                  <label className="label" typeof="emailAddress">
                    <img
                      src="https://i.imgur.com/Hn13wvm.png"
                      alt=""
                      className="label-icon"
                    />
                    <span>{t("login.email.label")}</span>
                  </label>
                  <input
                    type="email"
                    className="input"
                    placeholder={t("login.email.placeHolder")}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label className="label" typeof="emailAddress">
                    <img
                      src="https://i.imgur.com/g5SvdfG.png"
                      alt=""
                      className="label-icon"
                    />
                    <span>{t("login.password.label")}</span>
                  </label>
                  <input
                    type="password"
                    className="input"
                    placeholder={t("login.password.placeHolder")}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                {/* <div className="options-container">
                <div className="checkbox-container">
                  <input type="checkbox" id="RememberMe" className="checkbox" />{" "}
                  <label typeof="RememberMe">Remember me</label>
                </div>
                <a href="#" className="forgot-password-link">
                  Forgot Password?
                </a>
              </div> */}
                <span>
                  {t("login.notAccount")}
                  <Link to="/sign-up">{t("login.signUpHere")}</Link>
                </span>
                <Button
                  type="submit"
                  className="login-button"
                  disabled={!isValidForm}
                  children={t("login.login")}
                />
              </form>
              <div className="other-login-container">
                <GoogleSignIn />
                <GithubSignIn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
