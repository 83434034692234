/* eslint-disable @typescript-eslint/no-explicit-any */
export interface IProps {
    items: any[];
    headers?: IDinamicTableHeader[];
    showDownloadButton?: boolean;
    moneyThousandsSeparator?: '.' | ',';
    moneyDecimalSeparator?: '.' | ',';
}

export enum ColumnType {
    number = 'number',
    string = 'string',
    money = 'money',
    date = 'date',
    other = 'other',
}

export interface IDinamicTableHeader {
    key: string;
    label: string;
}
