import { FC, useState } from "react";
import "./style.scss";
import { ColumnType, IProps } from "./types";
import { Table as AntdTable, Empty, TableProps, Tooltip, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbTack, faDownload } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";

const DinamicTable: FC<IProps> = ({ items, headers, showDownloadButton = true, moneyThousandsSeparator = '.', moneyDecimalSeparator = ',' }) => {
  const [fixedColumns, setFixedColumns] = useState<string[]>([]);

  const isFixed = (item: string) =>
    fixedColumns.some((column) => column === item);

  const onClickFaTumbTack = (item: string) => {
    if (isFixed(item)) {
      setFixedColumns((prevState) => prevState.filter((s) => s !== item));
    } else {
      setFixedColumns((prevState) => [...prevState, item]);
    }
  };

  const setFaThumbTackColor = (item: string) =>
    isFixed(item) ? "#1677FF" : "#969696";

  const setMoneyRegex = () => {
    const regexPattern = `^\\d{1,3}(?:\\${moneyThousandsSeparator}\\d{3})*(?:\\${moneyDecimalSeparator}\\d{2})?$`;
    return new RegExp(regexPattern);
  };

  const getColumnType = (columnName: string) => {
    let type = ColumnType.other;

    for (let index = 0; index < items.length; index++) {
      const value = items[index][columnName];

      if (value === "") continue;

      if (isNaN(value) && typeof value === "string") {
        if (!isNaN(new Date(value as string).getTime())) {
          type = ColumnType.date;
        } else if (setMoneyRegex().test(value)) {
          type = ColumnType.money;
        } else {
          type = ColumnType.string;
        }
      } else if (!isNaN(value) || typeof value === "number") {
        type = ColumnType.number;
      }
      break;
    }

    return type;
  };

  const setFilterOptions = (columnName: string) => {
    const output: any = {};
    const type = getColumnType(columnName);

    if (type === ColumnType.string) {
      let filterOptions: any[] = [];

      items.forEach((x) => {
        const value = x[columnName];
        if (value && !filterOptions.includes(value)) {
          filterOptions.push(value);
        }
      });

      output.filters = filterOptions.map((option) => ({
        text: option,
        value: option,
      }));

      output.filterMode = "tree";

      output.filterSearch = true;

      output.onFilter = (value: string, record: any) =>
        record[columnName].startsWith(value);
    }

    return output;
  };

  const setSortFunc = (columnName: string, a: any, b: any) => {
    const type = getColumnType(columnName);
    let output: any = true;

    if (type === ColumnType.string) {
      output = a[columnName].localeCompare(b[columnName]);
    } else if (type === ColumnType.number) {
      output = a[columnName] - b[columnName];
    } else if (type === ColumnType.money) {
      output =
        Number((a[columnName] as string).split(moneyDecimalSeparator)[0].replaceAll(moneyThousandsSeparator, "")) -
        Number((b[columnName] as string).split(moneyDecimalSeparator)[0].replaceAll(moneyThousandsSeparator, ""));
    } else if (type === ColumnType.date) {
      output =
        new Date(a[columnName]).getTime() - new Date(b[columnName]).getTime();
    }

    return output;
  };

  const setColumnWidth = (columnName: string) =>
    `${columnName.length * 10 + 60}px`;

  const setColumnTextAlignment = (columnName: string) =>
    getColumnType(columnName) === ColumnType.number ||
    getColumnType(columnName) === ColumnType.money
      ? "right"
      : "left";

  const setColumns = (): TableProps<(typeof items)[0]>["columns"] =>
    [
      ...fixedColumns,
      ...Object.keys(items[0]).filter(
        (column) => !fixedColumns.some((x) => x === column)
      ),
    ].map((item) => ({
      title: (
        <div className="th_container">
          <span>
            {headers?.find((header) => header?.key === item)?.label ?? item}
          </span>
          <span className="faThumbTack" onClick={() => onClickFaTumbTack(item)}>
            <FontAwesomeIcon
              icon={faThumbTack}
              size="sm"
              color={setFaThumbTackColor(item)}
            />
          </span>
        </div>
      ),
      dataIndex: item,
      key: item,
      fixed: isFixed(item),
      width: setColumnWidth(item),
      sorter: (a, b) => setSortFunc(item, a, b),
      showSorterTooltip: false,
      render: (value) => (
        <Tooltip title={String(value)} placement="right">
          <div
            style={{
              width: "100%",
              textAlign: setColumnTextAlignment(item),
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {String(value)}
          </div>
        </Tooltip>
      ),
      ...setFilterOptions(item),
    }));

  const setXWidth = () => ((setColumns() || [])?.length > 3 ? 1500 : undefined);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(items);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  if (!items.length) {
    return <Empty />;
  }

  return (
    <>
      {showDownloadButton && (
        <div
          style={{
            width: "100%",
            position: "relative",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            marginBottom: '5px'
          }}
        >
          <Button
            icon={<FontAwesomeIcon icon={faDownload} />}
            onClick={downloadExcel}
          />
        </div>
      )}
      <AntdTable
        pagination={{ position: ["bottomRight"], showSizeChanger: true }}
        columns={setColumns()}
        dataSource={items}
        scroll={{ x: setXWidth() }}
      />
    </>
  );
};

export default DinamicTable;
