/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, Fragment, ReactNode } from "react";
import "./style.scss";
import { IProps } from "./types";
import { Empty, List, Skeleton, Tooltip } from "antd";
import Avatar from "../../../../components/Avatar";
import { IQueryModel } from "./types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRepeat, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import Confirm from "../../../../components/Confirm";
import { useAuth } from "../../../../context/store/authContext";

const QueryItems: FC<IProps> = ({
  items,
  isLoading,
  onRetry = () => null,
  onLike = () => null,
}) => {
  const { loggedInUserData } = useAuth();
  const aiAvatarURL = "/ai-avatar.jpg";

  const setContent = (item: IQueryModel) =>
    item.inProgress ? (
      <Skeleton active />
    ) : (
      <div style={{ color: '#1677ff' }}>{item.answer || <Empty />}</div>
    );

  const onClickRetry = (item: IQueryModel) => {
    onRetry(item.query || "");
  };

  const setItemFooter = (item: IQueryModel, content: ReactNode, index: number) => {
    return (
      <Fragment>
        {content}
        {!item.inProgress && !isLoading && (
          <div className="item-footer">
            <Confirm
              title="Retry Query"
              description="Are you sure?"
              onSubmit={() => onClickRetry(item)}
            >
              <Tooltip title="Retry" placement="bottom">
                <FontAwesomeIcon icon={faRepeat} />
              </Tooltip>
            </Confirm>
            <Tooltip title="Like" placement="bottom">
              <FontAwesomeIcon icon={faThumbsUp} style={{ cursor: 'pointer' }} onClick={() => onLike(index)} />
            </Tooltip>
          </div>
        )}
      </Fragment>
    );
  };

  return (
    <List
      className="query_items"
      itemLayout="vertical"
      dataSource={items}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            style={{
              position: "sticky",
              top: 0,
              zIndex: 20,
              backgroundColor: "white",
            }}
            avatar={
              <Avatar
                showDisplayName={false}
                displayName={loggedInUserData?.displayName ?? ""}
              />
            }
            title={<div>{item.query}</div>}
          />
          <List.Item.Meta
            avatar={<Avatar photoUrl={aiAvatarURL} />}
            description={setItemFooter(item, setContent(item), index)}
          />
        </List.Item>
      )}
    />
  );
};

export default QueryItems;
