import React, { useState } from "react";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faSave } from "@fortawesome/free-solid-svg-icons";
import { embedModels } from "../../../data";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { IAskConfigration } from "../../../services/ui-services/chatService/types";
interface IProps {
  visible: boolean;
  questionsPackagesDropdownData: DropdownOption[];
  PDFPackageDropdownData: DropdownOption[];
  usersDropdownData: DropdownOption[];
  closeModal: () => void;
  onCreateHandler: (
    name: string,
    configration: IAskConfigration,
    questionPackeId: string,
    assignedUserId: string,
    pdfPackeId: string
  ) => void;
}

const CreateModal: React.FC<IProps> = ({
  visible,
  questionsPackagesDropdownData,
  PDFPackageDropdownData,
  usersDropdownData,
  closeModal,
  onCreateHandler,
}) => {
  const { t } = useTranslation();

  const reductionTypes = [
    {
      label: "Map Reduce",
      value: "map_reduce",
      description: t("askConfig.reductionType.mapReduce.label"),
    },
    {
      label: "Stuff",
      value: "stuff",
      description: t("askConfig.reductionType.stuff.label"),
    },
    {
      label: "Refine",
      value: "refine",
      description: t("askConfig.reductionType.refine.label"),
    },
    {
      label: "Map Rerank",
      value: "map_rerank",
      description: t("askConfig.reductionType.mapRerank.label"),
    },
  ];

  const [topK, setTopK] = useState<number>(10);
  const [topN, setTopN] = useState<number>(10);
  const [llmTemp, setLlmTemp] = useState<number>(0);
  const [questionPackage, setQuestionPackage] = useState<
    DropdownOption | undefined
  >(undefined);
  const [reductionType, setReductionType] = useState<{
    label: string;
    value: string;
    description: string;
  }>(reductionTypes[1]);

  const [embedNumber, setEmbedNumber] = useState<DropdownOption>(
    embedModels.find((model) => model.value === 4) || {} as DropdownOption
  );
  const [PDFPackage, setPDFPackage] = useState<DropdownOption | undefined>(
    undefined
  );
  const [name, setName] = useState<string>("");
  const [assignedUser, setAssignedUser] = useState<DropdownOption | undefined>(
    undefined
  );

  const handleCreate = async () => {
    const payload: IAskConfigration = {
      embed_model_number: embedNumber.value as number,
      engine_name: "gpt-3.5-turbo-16k",
      llm_temp: llmTemp,
      reduction_type: reductionType.value,
      top_k: topK,
      top_n: topN,
      context: "",
    };
    await onCreateHandler(
      name,
      payload,
      questionPackage?.value as string,
      assignedUser?.value as string,
      PDFPackage?.value as string
    );
  };

  const isValidForm = () =>
    name.length > 3 &&
    questionPackage?.value &&
    reductionType?.value &&
    assignedUser?.value;

  return (
    <Modal
      className="quiz-modal-container"
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      title={t("quizzes.createQuiz.createQuiz")}
    >
      <div className="create-quiz-modal">
        <div className="modal-line">
          <Input
            label={t("quizzes.createQuiz.quizName")}
            error={name.length < 3}
            errorMessage={t("quizzes.createQuiz.collectionNameValidMessage")}
            value={name}
            onChange={(e) => setName(e as string)}
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={t("askConfig.engine.name")}
            disabled
            options={[{ value: "gpt-3.5-turbo-16k", label: "GPT 3.5 Turbo 16k" }]}
            onSelect={(e) => {}}
            value={{
              value: "gpt-3.5-turbo-16k",
              label: "gpt-3.5-turbo-16k",
            }}
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={
              <div className="label-container">
                <span>{t("askConfig.model.name")}</span>
                <Tooltip id="model" content={t("askConfig.model.label")} />
                <FontAwesomeIcon data-tooltip-id="model" icon={faInfoCircle} />
              </div>
            }
            options={embedModels}
            onSelect={setEmbedNumber}
            value={embedNumber}
          />
        </div>
        <div className="modal-line">
          <Input
            label={
              <div className="label-container">
                <span>{t("askConfig.topK.name")}</span>
                <Tooltip id="topK" content={t("askConfig.topK.label")} />
                <FontAwesomeIcon data-tooltip-id="topK" icon={faInfoCircle} />
              </div>
            }
            max={10}
            min={1}
            type="number"
            value={topK}
            onChange={(e) => {
              const value = e as number;
              setTopK(value < topN ? topN : value);
            }}
          />
        </div>
        <div className="modal-line">
          <Input
            label={
              <div className="label-container">
                <span>{t("askConfig.topN.name")}</span>
                <Tooltip id="topN" content={t("askConfig.topN.label")} />
                <FontAwesomeIcon data-tooltip-id="topN" icon={faInfoCircle} />
              </div>
            }
            max={10}
            min={1}
            type="number"
            value={topN}
            onChange={(e) => {
              const value = e as number;
              setTopN(value > topK ? topK : value);
            }}
          />
        </div>
        <div className="modal-line">
          <Input
            label={
              <div className="label-container">
                <span>{t("askConfig.llmTemp.name")}</span>
                <Tooltip id="llmTemp" content={t("askConfig.llmTemp.label")} />
                <FontAwesomeIcon
                  data-tooltip-id="llmTemp"
                  icon={faInfoCircle}
                />
              </div>
            }
            type="number"
            value={llmTemp}
            onChange={(e) => setLlmTemp(e as number)}
            decimalIncrease
            min={0}
            max={1}
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={
              <div className="label-container">
                <span>{t("askConfig.reductionType.name")}</span>
              </div>
            }
            options={reductionTypes.map((reduction) => {
              return {
                value: reduction.value,
                label: (
                  <span className="reduction-dropdown-select">
                    {reduction.label}
                    <Tooltip
                      id={reduction.value}
                      content={reduction.description}
                    />
                    <FontAwesomeIcon
                      data-tooltip-id={reduction.value}
                      icon={faInfoCircle}
                    />
                  </span>
                ),
              };
            })}
            onSelect={(e) => {
              const selectType = reductionTypes.find(
                (type) => type.value === e.value
              );
              setReductionType(selectType!);
            }}
            value={
              reductionType && {
                value: reductionType.value,
                label: (
                  <span className="reduction-dropdown-select">
                    {reductionType.label}
                    <Tooltip id="info" content={reductionType.description} />
                    <FontAwesomeIcon
                      data-tooltip-id="info"
                      icon={faInfoCircle}
                    />
                  </span>
                ),
              }
            }
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={t("quizzes.createQuiz.questionPackage")}
            options={questionsPackagesDropdownData}
            onSelect={setQuestionPackage}
            value={questionPackage}
            error={!questionPackage?.value}
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={t("quizzes.createQuiz.PDFPackage")}
            options={PDFPackageDropdownData}
            onSelect={setPDFPackage}
            value={PDFPackage}
            error={!PDFPackage?.value}
          />
        </div>
        <div className="modal-line">
          <Dropdown
            label={t("quizzes.createQuiz.assignedUser")}
            options={usersDropdownData}
            onSelect={setAssignedUser}
            value={assignedUser}
            error={!assignedUser?.value}
          />
        </div>
        <div className="footer">
          <Button
            disabled={!isValidForm()}
            onClick={handleCreate}
            className="save-button"
            icon={faSave}
            iconSize="2x"
          />
        </div>
      </div>
    </Modal>
  );
};

export default CreateModal;
