import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase-services";
import { getUserId, getUserInfo } from "../../firebase-services/auth";
import { CreateQuestionPackageDBModel, CreateQuestionPackageDBPayload, QuestionPackageResponseDBModel } from "./types";
import { UserAuditDBModel } from "../userDBService/types";



class QuestionDBServices {
  private collectionName = "questionsPackages";
  private dbRef = collection(db, this.collectionName);

  async create(questionPackage: CreateQuestionPackageDBPayload): Promise<string> {
    try {
      const currentUserInfo = getUserInfo();
      const audit = {
        lastModifierUserInfo: currentUserInfo,
        lastModificationTime: new Date(),
        creationTime: new Date(),
        creatorUserInfo: currentUserInfo,
      } as UserAuditDBModel;

      const packageWithReference: CreateQuestionPackageDBModel = {
        ...audit,
        content: questionPackage
      };


      const docRef = await addDoc(this.dbRef, packageWithReference);

      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAll(): Promise<QuestionPackageResponseDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(this.dbRef, where("creatorUserInfo.id", "==", getUserId()))
      );

      const packages: QuestionPackageResponseDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as QuestionPackageResponseDBModel;
        packages.push({
          ...packageData,
          id: doc.id,
        });
      });
      return packages;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QuestionDBServices;