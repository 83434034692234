import { QuestionDBModel } from "../questionDBService/types";
import { UserAuditDBModel, UserModel } from "../userDBService/types";

export interface QuizDBModel {
  id?:string
  name: string;
  status: QuizStatusTypes;
  configration: {
    embed_model_number: number;
    top_k: number;
    top_n: number;
    engine_name: string;
    llm_temp: number;
    reduction_type: string;
    context:""
  };
  questions: QuestionDBModel[];
}

export interface CreateQuizDBPayload {
  name: string;
  status: QuizStatusTypes;
  configration: {
    embed_model_number: number;
    top_k: number;
    top_n: number;
    engine_name: string;
    llm_temp: number;
    reduction_type: string;
    context:""
  };
}

export interface CreateQuizDBModel {
  name: string;
  status: QuizStatusTypes;
  configration: {
    embed_model_number: number;
    top_k: number;
    top_n: number;
    engine_name: string;
    llm_temp: number;
    reduction_type: string;
    context:""
  };
  questions: QuestionDBModel[];
}

export interface QuizAuditDBModel extends UserAuditDBModel {
  assignedUserInfo: UserModel,
  questionPackageInfo: QuizPackageInfoDBModel
  PDFPackageInfo: QuizPackageInfoDBModel;
}

export interface CreateQuizDBExtendModel extends QuizAuditDBModel {
  content: CreateQuizDBModel;
}

export interface QuizResponseDBModel extends QuizAuditDBModel {
  id: string;
  content: QuizDBModel;
}

interface QuizPackageInfoDBModel {
  id: string;
  collectionId: string;
  name: string;
}

export enum QuizStatusTypes {
  Pending = "pending",
  Completed = "completed",
}
