import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import IndicatorLayout from "../../components/IndicatorLayout";
import QueryLogService from "../../services/ui-services/queryLogsService";
import { IIndicatorModel } from "../../components/IndicatorLayout/types";
import { IJsonModel } from "flexlayout-react";
import { Skeleton, Switch } from "antd";
import "./style.scss";
import LayoutService from "../../services/ui-services/layoutService";
import { ProjectTypes } from "../../models/ProjectTypes";
import { toast } from "react-toastify";
import { IUpdateIndicatorInputModel } from "../../services/ui-services/queryLogsService/types";

const HospitalIndicatorManager = () => {
  const queryLogService = useMemo(() => {
    return new QueryLogService();
  }, []);

  const layoutService = useMemo(() => {
    return new LayoutService();
  }, []);

  const [indicators, setIndicators] = useState<IIndicatorModel[]>([]);
  const [jsonLayout, setJsonLayout] = useState<IJsonModel>();
  const [isPreview, setIsPreview] = useState(false);
  const [layoutId, setLayoutId] = useState<string>("");
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [loadingLayout, setLoadingLayout] = useState(false);

  const getIndicators = useCallback(async () => {
    try {
      setLoadingIndicators(true);
      const res = await queryLogService.getHospitalIndicators();
      setIndicators(
        res.map((indicator, i) => ({
          id: indicator?.id ?? "",
          name: (i + 1).toString(),
          content: indicator?.user_query ?? '',
          generaterFunction: () =>
            queryLogService.executeGeneratedTabularDataCode({
              fb_path: `${queryLogService.getHospitalCollectionName()}/${indicator.id}`
            })
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIndicators(false);
    }
  }, [queryLogService]);

  useEffect(() => {
    getIndicators();
  }, [getIndicators]);

  const getLayout = useCallback(async () => {
    try {
      setLoadingLayout(true);
      const res = await layoutService.getAll(ProjectTypes.Hospital);
      if (res.length) {
        setJsonLayout(JSON.parse(res[0].content));
        setLayoutId(res[0].id);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLayout(false);
    }
  }, [layoutService]);

  useEffect(() => {
    getLayout();
  }, [getLayout]);

  const createUpdateLayout = useCallback(
    async (content: string, successCallback: VoidFunction) => {
      try {
        if (layoutId) {
          await layoutService.update(layoutId, { content });
        } else {
          const res = await layoutService.create({
            content,
            projectType: ProjectTypes.Hospital,
          });
          setLayoutId(res.id);
        }
        successCallback();
      } catch (error) {
        toast.error("Layout couldnt updated!");
        console.log(error);
      }
    },
    [layoutId, layoutService]
  );

  const onChangeLayout = (layout: IJsonModel) => {
    createUpdateLayout(JSON.stringify(layout), () => {
      setJsonLayout(layout);
    });
  };

  const onDeleteIndicator = async (id: string) => {
    if (window.confirm("Are you sure?")) {
      try {
        const input: IUpdateIndicatorInputModel = {
          isIndicator: false,
        };
        await queryLogService.createUpdateHospitalIndicator(id, input);
        setIndicators((prevState) =>
          prevState.filter((indicator) => indicator.id !== id)
        );
      } catch (error) {
        toast.error("Try again!");
        console.log(error);
      }
    }
  };

  if (loadingIndicators || loadingLayout) {
    return <Skeleton active />;
  }

  return (
    <>
      <div className="indicator-manager-container">
        <div style={{ marginRight: "5px" }}>Edit Mode</div>
        <Switch
          checked={!isPreview}
          onChange={(checked) => setIsPreview(!checked)}
        />
      </div>
      <IndicatorLayout
        indicators={indicators}
        jsonLayout={jsonLayout}
        onChangeLayout={onChangeLayout}
        isPreview={isPreview}
        onDeleteIndicator={onDeleteIndicator}
      />
    </>
  );
};

export default memo(HospitalIndicatorManager);
