import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import "./style.scss";
import Chat from "../../components/Chat";
import PDFPackageService from "../../services/ui-services/documentPackageService";
import { useParams } from "react-router-dom";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { IPDFPackageResponseModel } from "../../services/ui-services/documentPackageService/types";
import { IActiveReferenceModel } from "../../components/Chat/types";

const BaseCollectionDetail: React.FC = () => {
  const pdfPackageService = useMemo(() => {
    return new PDFPackageService();
  }, [])

  const { t } = useTranslation();
  let { collectionId } = useParams();
  const { onCompleted, onLoading } = useLoading();

  const [activeReference, setActiveReference] = useState<
    IActiveReferenceModel | undefined
  >(undefined);
  const [PDFPackageInfo, setPDFPackage] = useState<
    IPDFPackageResponseModel | undefined
  >(undefined);

  // const sliceWords = (text: string, wordCount: number): string => {
  //   const words = text.trim().split(/\s+/);
  //   const slicedWords = words.slice(0, wordCount);
  //   return slicedWords.join(" ");
  // };

  const getPDFPackage = async () => {
    try {
      if (collectionId) {
        const PDFPackage = await pdfPackageService.getQuizById(collectionId!);
        setPDFPackage(PDFPackage!);
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
    }
  };

  const loadComponentCallback = useCallback(async () => {
    try {
      onLoading();
      await getPDFPackage();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted, onLoading]);

  useLayoutEffect(() => {
    loadComponentCallback();
  }, [loadComponentCallback]);

  return (
    <div className="context-page-container">
      {PDFPackageInfo && (
        <>
          <div className="left-side">
            <Chat
              collectionInfo={PDFPackageInfo}
              activeReference={activeReference}
              handleReference={setActiveReference}
            />
          </div>

          <div className="right-side">
            {!!PDFPackageInfo?.content.PDFS.length && (
              <PDFViewer
                zoomControl
                downloadDocument
                fileUrl={`${process.env.REACT_APP_S3_BASE_URL}${
                  PDFPackageInfo?.content.collectionId
                }/${
                  activeReference?.ref_file_name ??
                  PDFPackageInfo?.content.PDFS[
                    PDFPackageInfo?.content.PDFS.findIndex(
                      (document) => document.url
                    )
                  ].url
                }`}
                searchKeys={activeReference?.ref_text_lines}
                pageNumber={activeReference?.ref_page}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BaseCollectionDetail;
