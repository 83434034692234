import React, { FC } from "react";
import "./style.scss";
import { IMessageModel } from "../../services/ui-services/chatService/types";
import { IActiveReferenceModel } from "../Chat/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface IProps {
  data: IMessageModel;
  activeReference?: IActiveReferenceModel;
  onClickBox: (data: IMessageModel, index: number) => void;
}

const MessageBox: FC<IProps> = ({ data, activeReference, onClickBox }) => {
  const { t } = useTranslation();

  const handleCopyButton = () => {
    navigator.clipboard.writeText(data.answer).then(() => {
      toast.success(t("messageBox.copyButton.copySuccess") + data.answer, {
        autoClose: 1000,
      });
    });
  };

  return (
    <div
      className={`message-container-wrapper ${
        !data.is_question && "reverse-box"
      } `}
    >
      <div
        className={`message-container ${
          activeReference?.messageId === data.id && "active-box"
        }`}
      >
        <div
          className="text-container"
          onClick={() => !data.is_question && onClickBox(data, 0)}
        >
          <p className="text">{data.answer}</p>
        </div>
        {!data.is_question && !!data.sources?.length && (
          <div className="page-numbers">
            {data.sources.map((reference, index) => {
              return (
                <span
                  key={index}
                  className={`number ${
                    activeReference?.ref_text_lines === reference?.ref_text_lines &&
                    activeReference.messageId === data.id &&
                    "active-number"
                  }`}
                  onClick={() => !data.is_question && onClickBox(data, index)}
                >
                  {reference.ref_page}
                </span>
              );
            })}
          </div>
        )}

        <div onClick={handleCopyButton} className="copy-button">
          {/* <Tooltip
            id={"copy"}
            content={t("messageBox.copyButton.copy")}
          /> */}
          <FontAwesomeIcon
            size="xs"
            icon={faCopy}
          />
        </div>
      </div>
    </div>
  );
};

export default MessageBox;
