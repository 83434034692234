import { createContext, useState, useContext, useCallback } from "react";
import Loading from "../../components/Loading";

interface LoadingContextType {
  isLoading: boolean;
  onLoading: () => void;
  onCompleted: () => void;
}

let LoadingContext = createContext<LoadingContextType>(null!);

function LoadingProvider({ children }: { children: React.ReactNode }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onLoading = useCallback(() => {
    setIsLoading(true);
  }, [setIsLoading]);

  const onCompleted = useCallback(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  let value = { isLoading, onLoading, onCompleted };

  return (
    <LoadingContext.Provider value={value}>
      {isLoading && <Loading />}
      {children}
    </LoadingContext.Provider>
  );
}

const useLoading = (): LoadingContextType => {
  return useContext(LoadingContext);
};

export { useLoading, LoadingProvider };
