import React, { memo, useEffect, useMemo, useState } from "react";
import CreateUpdatePatientForm from "./CreateUpdatePatient";
import {
  IPatientFormModel,
  convertPatientFormModelToPayloadModel,
} from "./CreateUpdatePatient/types";
import PatientService from "../../services/ui-services/patientService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useUtilContext } from "../../context/store/utilContext";

const HealthCreatePatient = () => {
  const patientService = useMemo(() => {
    return new PatientService();
  }, []);
  const [submitting, setSubmitting] = useState(false);
  const [initialValues, setInitialValues] = useState<
    IPatientFormModel | undefined
  >();
  const navigate = useNavigate();
  const { onChangeUploadedPatient, uploadedPatient } = useUtilContext();

  useEffect(() => {
    return () => {
      onChangeUploadedPatient(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onCreate = async (values: IPatientFormModel) => {
    try {
      setSubmitting(true);
      const payload = convertPatientFormModelToPayloadModel(values);
      await patientService.create(payload);
      toast("Success");
      navigate(-1);
    } catch (error) {
      toast.warn((error as Error)?.message ?? "Please Try Again!");
      console.log({ error });
    } finally {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (uploadedPatient) {
      setInitialValues({...uploadedPatient});
    }
  }, [uploadedPatient]);

  return (
    <div>
      <CreateUpdatePatientForm
        onCreate={onCreate}
        initialValues={initialValues}
        submitting={submitting}
        title="Create a new patient"
        onCancel={onCancel}
      />
    </div>
  );
};

export default memo(HealthCreatePatient);
