import React, { useEffect, useMemo, useState } from "react";
import SignUIService from "../../services/ui-services/authService";
import { useAuth } from "../../context/store/authContext";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useLoading } from "../../context/store/loadingContext";
import { validateEmail, validatePassword } from "../../utils/helpers";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import GoogleSignIn from "../../components/GoogleSignIn";
import GithubSignIn from "../../components/GithubSignIn";
// import LanguageSelector from "../../components/LanguageSelector";

const SignUp: React.FC = () => {
  const signUIService = useMemo(() => {
    return new SignUIService();
  }, [])

  const { t } = useTranslation();
  const { loggedInUserData, updateLoggedInUserData: setLoggedInUserData } =
    useAuth();
  const { onCompleted, onLoading } = useLoading();
  const navigate: NavigateFunction = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  useEffect(() => {
    if (loggedInUserData) {
      navigate("/");
    }
  }, [loggedInUserData, navigate]);

  useEffect(() => {
    const valid =
      validateEmail(email) && validatePassword(password, confirmPassword);
    setIsValidForm(valid);
  }, [confirmPassword, email, password]);

  const handleSignUp = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      onLoading();
      const tempData = await signUIService.signUp(email, password);
      if (tempData) {
        localStorage.setItem("userInfo", JSON.stringify(tempData));
        setLoggedInUserData(tempData);
      }
    } catch (error) {
    } finally {
      onCompleted();
    }
  };

  return (
    <>
      <div className="login-page">
        <div className="login-page-wrapper">
          {/* <div className="language-container">
            <LanguageSelector />
          </div> */}
          {/* <div className="image-container">
            <img
              src="https://i.imgur.com/MYZd7of.png"
              alt=""
              className="group-image"
            />
          </div> */}
          <div className="login-form-container">
            <div className="login-form-wrapper">
              {/* <div className="logo-container">
              <img
                src="https://www.pngkey.com/png/full/529-5291672_sample-logo-png-transparent-background.png"
                alt=""
                className="logo"
              />
            </div> */}
              <header className="header">{t("signUp.welcome")}</header>
              {/* <header className="sub-header">
     
            </header> */}
              <form onSubmit={handleSignUp}>
                <div className="input-container">
                  <label className="label" typeof="emailAddress">
                    <img
                      src="https://i.imgur.com/Hn13wvm.png"
                      alt=""
                      className="label-icon"
                    />
                    <span>{t("signUp.email.label")}</span>
                  </label>
                  <input
                    type="email"
                    className="input"
                    placeholder={t("signUp.email.placeHolder")}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-container">
                  <label className="label" typeof="emailAddress">
                    <img
                      src="https://i.imgur.com/g5SvdfG.png"
                      alt=""
                      className="label-icon"
                    />
                    <span>{t("signUp.password.label")}</span>
                  </label>
                  <input
                    type="password"
                    className="input"
                    placeholder={t("signUp.password.placeHolder")}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <input
                    value={confirmPassword}
                    type="password"
                    className="input"
                    placeholder={t("signUp.password.confirmPlaceHolder")}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </div>
                {/* <div className="options-container">
                <div className="checkbox-container">
                  <input type="checkbox" id="RememberMe" className="checkbox" />{" "}
                  <label typeof="RememberMe">Remember me</label>
                </div>
                <a href="#" className="forgot-password-link">
                  Forgot Password?
                </a>
              </div> */}
                <span>
                  {t("signUp.alreadyAccount")}
                  <Link to="/login">{t("signUp.loginHere")}</Link>
                </span>

                <Button
                  disabled={!isValidForm}
                  type="submit"
                  className="login-button"
                  children={t("signUp.signUp")}
                />
              </form>
              <div className="other-login-container">
                <GoogleSignIn />
                <GithubSignIn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
