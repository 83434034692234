import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./context/store/authContext";
import { LoadingProvider } from "./context/store/loadingContext";
import { ToastContainer } from "react-toastify";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import "pdfjs-dist/build/pdf.worker.entry";
import { I18nextProvider } from "react-i18next";
import i18n from "./locales/i18n";
import { UtilContextProvider } from "./context/store/utilContext";
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

// const userLanguage = navigator.language || navigator.languages[0];
// i18n.changeLanguage(userLanguage);

root.render(
  <I18nextProvider i18n={i18n}>
    <UtilContextProvider>
      <LoadingProvider>
        <AuthProvider>
          <ToastContainer pauseOnHover />
          <App />
        </AuthProvider>
      </LoadingProvider>
    </UtilContextProvider>
  </I18nextProvider>
);


reportWebVitals();
