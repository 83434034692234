import React, { memo, useMemo } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/store/authContext";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import SignUIService from "../../services/ui-services/authService";
import { INavItemModel } from "./types";
import { PermissionTypes } from "../../permissions";
import { ProjectTypes } from "../../models/ProjectTypes";
import { useProject } from "../../context/store/projectContext";
import { useMobile } from "../../hooks/useMobile";
// import LanguageSelector from "../LanguageSelector";

export const menuItems = [
  {
    to: "/health-patients",
    label: "Patients",
    permissionType: PermissionTypes.ViewHealthPatientPages,
    projectType: ProjectTypes.Health,
  },
  {
    to: "/base-dashboard",
    label: "Dashboard",
    permissionType: PermissionTypes.ViewBaseDashboardPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/base-assignments",
    label: "Assignments",
    permissionType: PermissionTypes.ViewBaseAssignmentsPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/base-question-collections",
    label: "Question Collections",
    permissionType: PermissionTypes.ViewBaseQuestionCollectionPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/base-document-collections",
    label: "Document Collections",
    permissionType: PermissionTypes.ViewBaseDocumentCollectionsPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/base-query-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewBaseQueryLogsPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/base-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewBaseLLMLogsPage,
    projectType: ProjectTypes.Base,
  },
  {
    to: "/data-analysis",
    label: "Data Insights on Titanic Passengers",
    permissionType: PermissionTypes.ViewDataAnalysisPage,
    projectType: ProjectTypes.DataAnalysis,
  },
  {
    to: "/data-analysis-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewDataAnalysisLogsPage,
    projectType: ProjectTypes.DataAnalysis,
  },
  {
    to: "/data-analysis-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewDataAnalysisLLMLogsPage,
    projectType: ProjectTypes.DataAnalysis,
  },
  {
    to: "/health-query-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewHealthQueryLogsPage,
    projectType: ProjectTypes.Health,
  },
  {
    to: "/health-chat-query-logs",
    label: "Chat Logs",
    permissionType: PermissionTypes.ViewHealthChatQueryLogsPage,
    projectType: ProjectTypes.Health,
  },
  {
    to: "/health-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewHealthLLMLogsPage,
    projectType: ProjectTypes.Health,
  },
  {
    to: "/yevmiye",
    label: "Yevmiye",
    permissionType: PermissionTypes.ViewYevmiyePage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/yevmiye-query-logs",
    label: "Yevmiye Logs",
    permissionType: PermissionTypes.ViewYevmiyeQueryLogsPage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/yevmiye-llm-logs",
    label: "Yevmiye LLM Logs",
    permissionType: PermissionTypes.ViewYevmiyeLLMLogsPage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/yevmiye-sql",
    label: "Yevmiye SQL",
    permissionType: PermissionTypes.ViewYevmiyeSQLPage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/yevmiye-sql-query-logs",
    label: "Yevmiye SQL Logs",
    permissionType: PermissionTypes.ViewYevmiyeSQLQueryLogsPage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/yevmiye-sql-llm-logs",
    label: "Yevmiye SQL LLM Logs",
    permissionType: PermissionTypes.ViewYevmiyeSQLLLMLogsPage,
    projectType: ProjectTypes.Yevmiye,
  },
  {
    to: "/health-instructions",
    label: "Instructions",
    permissionType: PermissionTypes.ViewHealthInstructionsPages,
    projectType: ProjectTypes.Health,
  },
  {
    to: "/fund-query",
    label: "Yatırım Fonları Analizi",
    permissionType: PermissionTypes.ViewFundQueryPage,
    projectType: ProjectTypes.Fund,
  },
  {
    to: "/fund-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewFundLogsPage,
    projectType: ProjectTypes.Fund,
  },
  {
    to: "/fund-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewFundLLMLogsPage,
    projectType: ProjectTypes.Fund,
  },
  {
    to: "/hospital-query",
    label: "Hastane Veri Analizi",
    permissionType: PermissionTypes.ViewHospitalQueryPage,
    projectType: ProjectTypes.Hospital,
  },
  {
    to: "/hospital-dashboard",
    label: "Dashboard",
    permissionType: PermissionTypes.ViewHospitalDashboardPage,
    projectType: ProjectTypes.Hospital,
  },
  {
    to: "/hospital-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewHospitalLogsPage,
    projectType: ProjectTypes.Hospital,
  },
  {
    to: "/hospital-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewHospitalLLMLogsPage,
    projectType: ProjectTypes.Hospital,
  },
  {
    to: "/hospital-indicator-manager",
    label: "Indicator Manager",
    permissionType: PermissionTypes.ViewHospitalIndicatorManagerPage,
    projectType: ProjectTypes.Hospital,
  },
  {
    to: "/hospital-in-eng-query",
    label: "Hospital Data Analysis",
    permissionType: PermissionTypes.ViewHospitalInEngQueryPage,
    projectType: ProjectTypes.HospitalInEng,
  },
  {
    to: "/hospital-in-eng-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewHospitalInEngLogsPage,
    projectType: ProjectTypes.HospitalInEng,
  },
  {
    to: "/hospital-in-eng-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewHospitalInEngLLMLogsPage,
    projectType: ProjectTypes.HospitalInEng,
  },
  {
    to: "/workcube-query",
    label: "Query",
    permissionType: PermissionTypes.ViewWorkCubeQueryPage,
    projectType: ProjectTypes.WorkCube,
  },
  {
    to: "/workcube-dashboard",
    label: "Dashboard",
    permissionType: PermissionTypes.ViewWorkCubeDashboardPage,
    projectType: ProjectTypes.WorkCube,
  },
  {
    to: "/workcube-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewWorkCubeLogsPage,
    projectType: ProjectTypes.WorkCube,
  },
  {
    to: "/workcube-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewWorkCubeLLMLogsPage,
    projectType: ProjectTypes.WorkCube,
  },
  {
    to: "/workcube-indicator-manager",
    label: "Indicator Manager",
    permissionType: PermissionTypes.ViewWorkCubeIndicatorManagerPage,
    projectType: ProjectTypes.WorkCube,
  },
  {
    to: "/workcube-in-eng-query",
    label: "Query",
    permissionType: PermissionTypes.ViewWorkCubeInEngQueryPage,
    projectType: ProjectTypes.WorkCubeInEng,
  },
  {
    to: "/workcube-in-eng-logs",
    label: "Logs",
    permissionType: PermissionTypes.ViewWorkCubeInEngLogsPage,
    projectType: ProjectTypes.WorkCubeInEng,
  },
  {
    to: "/workcube-in-eng-llm-logs",
    label: "LLM Logs",
    permissionType: PermissionTypes.ViewWorkCubeInEngLLMLogsPage,
    projectType: ProjectTypes.WorkCubeInEng,
  },
];

const NavBar: React.FC = () => {
  const signUIService = useMemo(() => {
    return new SignUIService();
  }, []);

  const { t } = useTranslation();
  const { loggedInUserData, updateLoggedInUserData: setLoggedInUserData } =
    useAuth();
  const { projectType } = useProject();
  const { isMobile } = useMobile();

  const handleLogOut = async () => {
    await signUIService.logOut();
    setLoggedInUserData(null);
  };

  const navItems: INavItemModel[] = [...menuItems].filter(
    (item) =>
      (!item.permissionType ||
        (loggedInUserData &&
          loggedInUserData?.permissions?.includes(item.permissionType))) &&
      projectType === item.projectType
  );

  return (
    <header className="navbar-container">
      <div className="navbar-left-side">
        <a className="navbar-logo-wrapper" href="https://www.qandq.dev" target="_blank" rel="noopener noreferrer">
          <div className="navbar-logo">
            <img src="/qnq-ai-logo-CMYK-11.png" alt="Logo" />
          </div>
        </a>
        {!isMobile && (
          <nav className="navbar-pages">
            {navItems.map((item) => (
              <NavLink
                key={item.to}
                className={({ isActive }) => (isActive ? "active" : "")}
                to={`${item.to}?project_type=${projectType}`}
              >
                {item.label}
              </NavLink>
            ))}
          </nav>
        )}
      </div>
      <div className="navbar-right-side">
        {/* <LanguageSelector /> */}
        {loggedInUserData?.photoURL && (
          <div className="user-img">
            <img
              src={loggedInUserData.photoURL}
              alt={loggedInUserData.displayName || ""}
            />
          </div>
        )}
        <div className="user-name">
          {loggedInUserData?.displayName ?? loggedInUserData?.email}
        </div>
        {/* <Tooltip id="settings" />
        <div
          data-tooltip-id="settings"
          data-tooltip-content={t("navbar.settings")}
          onClick={() => {}}
          className="settings-button"
        >
          <FontAwesomeIcon size="xl" className="icon" icon={faGear} />
        </div> */}
        <Tooltip id="logout" />
        <div
          data-tooltip-id="logout"
          data-tooltip-content={t("navbar.signOut")}
          onClick={handleLogOut}
          className="logout-button"
        >
          <FontAwesomeIcon size="xl" className="icon" icon={faSignOutAlt} />
        </div>
      </div>
    </header>
  );
};

export default memo(NavBar);
