import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";
import { faClose } from "@fortawesome/free-solid-svg-icons";

interface ModalProps {
  className?: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  title?: string | number;
}

const Modal = ({ children, isOpen, title, className, onClose }: ModalProps) => {
  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className={`modal-container ${className}`}>
        <div className="modal-header">
          {!!title && title}
          <span onClick={onClose}>
            <FontAwesomeIcon icon={faClose} />
          </span>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
