import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import "./style.scss";
import TextArea from "../../../components/TextArea";
import { faCheck, faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";

interface Props {
  visible: boolean;
  closeModal: () => void;
  handleCreateQuestionPackage: (name: string, questions: string[]) => void;
}

const CreateModal: React.FC<Props> = ({
  visible,
  closeModal,
  handleCreateQuestionPackage,
}) => {
  const { t } = useTranslation();
  const questionsContainerRef = useRef<HTMLDivElement>(null);
  const [name, setName] = useState<string>("");
  const [questions, setQuestions] = useState<string[]>([]);
  const [newQuestion, setNewQuestion] = useState<string>("");

  const addNewQuestion = () => {
    setQuestions((prevState) => [...prevState, newQuestion]);
    setNewQuestion("");
  };

  const deleteQuestion = (index: number) => {
    const updatedQuestions = questions.filter((_, i) => i !== index);
    setQuestions(updatedQuestions);
  };

  const isFormValid = () => questions.length && name.length >= 3;

  useEffect(() => {
    if (questionsContainerRef.current) {
      const chatContainer = questionsContainerRef.current;
      const lastQuestion = chatContainer.lastElementChild as HTMLElement;
      if (lastQuestion) {
        lastQuestion.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [questions]);

  return (
    <Modal
      isOpen={visible}
      onClose={closeModal}
      title={t("questionPackages.createPackage")}
      className="create-question-package-modal"
    >
      <div className="modal-line">
        <Input
          error={name.length < 3}
          label={t("questionPackages.packageName")}
          value={name}
          onChange={(e) => setName(e as string)}
          errorMessage={t("questionPackages.collectionNameValidMessage")}
        />
      </div>

      <div className="modal-line">
        <div className="add-container">
          <TextArea
            label={t("questionPackages.ask")}
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
          />
          <Button
            onClick={addNewQuestion}
            className="save-button"
            disabled={!newQuestion}
            icon={faCheck}
            iconSize="2x"
          />
        </div>
      </div>

      <div className="questions" ref={questionsContainerRef}>
        {!!questions.length &&
          questions.map((question, index) => {
            return (
              <div key={index} className="question">
                <div className="text">
                  <b>{index + 1}) </b>
                  {question}
                </div>
                <div className="action">
                  <Button
                    onClick={() => deleteQuestion(index)}
                    className="delete-button"
                    icon={faTrash}
                  />
                </div>
              </div>
            );
          })}
      </div>

      <div className="footer">
        <Button
          onClick={() => handleCreateQuestionPackage(name, questions)}
          className="save-button"
          disabled={!isFormValid()}
          icon={faSave}
          iconSize="2x"
        />
      </div>
    </Modal>
  );
};

export default CreateModal;
