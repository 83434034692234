import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase-services";

import { CreatePatientDBPayloadModel, PatientDBModel } from "./types";

class PatientsDBService {
  private collectionName = "patients";
  private dbRef = collection(db, this.collectionName);

  async create(payload: CreatePatientDBPayloadModel): Promise<boolean> {
    try {
      await addDoc(this.dbRef, {
        ...payload,
        createdAt: new Date().toISOString(),
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAll(): Promise<PatientDBModel[]> {
    try {
      const querySnapshot = await getDocs(this.dbRef);
      const patients: PatientDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const patientData = doc.data() as PatientDBModel;
        patients.push({
          ...patientData,
          id: doc.id,
        });
      });
      return patients;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getById(id: string): Promise<PatientDBModel | null> {
    try {
      const docRef = doc(db, this.collectionName, id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const patientData = docSnapshot.data() as PatientDBModel;
        return {
          ...patientData,
          id: docSnapshot.id,
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getByPatientId(patientID: string): Promise<PatientDBModel | null> {
    try {
      const docSnapshot = await getDocs(
        query(this.dbRef, where("patientID", "==", patientID))
      );
      let patient = {} as PatientDBModel;

      docSnapshot.forEach((doc) => {
        const patientData = doc.data() as PatientDBModel;
        patient = {
          ...patientData,
          id: doc.id,
        };
      });
      return patient;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(
    id: string,
    payload: CreatePatientDBPayloadModel
  ): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);

      await updateDoc(docRef, { id, ...payload });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async setDiagnosis(id: string, diagnosis: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);

      await updateDoc(docRef, {
        id,
        diagnosis,
        diagnosisCreationDate: new Date().toISOString(),
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async setTriage(id: string, triage_level: string, triage_reasoning: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);

      await updateDoc(docRef, {
        id,
        triage_level,
        triage_reasoning,
        triageCreationDate: new Date().toISOString(),
      });
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.collectionName, id);
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export default PatientsDBService;
