import { FC } from 'react'
import { Avatar as AntdAvatar } from 'antd'
import { IProps } from './types'
import './styles.scss'
import { getFirstLetterOfDisplayname } from '../../utils/helpers'

const Avatar: FC<IProps> = ({ photoUrl, displayName, showDisplayName = true }) => {
    return (
        <div className="avatar_container">
            <AntdAvatar src={photoUrl}>{getFirstLetterOfDisplayname(displayName ?? 'U')}</AntdAvatar>
            {showDisplayName && <span className="avatar_container__display_name">{displayName?.toUpperCase()}</span>}
        </div>
    )
}

export default Avatar
