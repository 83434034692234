import { addDoc, and, collection, deleteDoc, doc, getDoc, getDocs, or, query, setDoc, where } from "firebase/firestore";
import { db } from "../../firebase-services";
import { CreateQuizDBPayload, QuizAuditDBModel, QuizResponseDBModel, QuizStatusTypes, CreateQuizDBExtendModel, QuizDBModel } from "./types";
import { QuestionDBModel, QuestionPackageResponseDBModel } from "../questionDBService/types";
import { PDFPackageResponseDBModel } from "../documentDBService/types";
import { getUserId, getUserInfo } from "../../firebase-services/auth";
import { UserModel } from "../userDBService/types";

class QuizDBServices {
  private questionsCollectionName = "questionsPackages";
  private quizCollectionName = "quizzes";

  async create(
    quizPayload: CreateQuizDBPayload,
    questionPackageId: string,
    assignedUserId: string,
    PDFPackageId: string
  ): Promise<QuizResponseDBModel> {
    try {
      const questionPackageCollection = collection(db, this.questionsCollectionName);

      const questionPackageDocRef = doc(questionPackageCollection, questionPackageId);

      const questionPackageSnapshot = await getDoc(questionPackageDocRef);

      const questionPackageData = questionPackageSnapshot.data() as QuestionPackageResponseDBModel;

      const PDFPackageCollection = collection(db, "PDFPackages");

      const PDFPackageDocRef = doc(PDFPackageCollection, PDFPackageId);

      const PDFPackageSnapshot = await getDoc(PDFPackageDocRef);

      const PDFPackageData = PDFPackageSnapshot.data() as PDFPackageResponseDBModel;

      const userSnapshot = await getDocs(query(collection(db, "users"), where("id", "==", assignedUserId)));

      const users: UserModel[] = [];

      userSnapshot.forEach((doc) => {
        const userData = doc.data() as UserModel;
        users.push({
          ...userData,
        });
      });

      const audit = {
        lastModifierUserInfo: getUserInfo(),
        lastModificationTime: new Date(),
        creationTime: new Date(),
        creatorUserInfo: getUserInfo(),
        assignedUserInfo: users[0],

        questionPackageInfo: {
          id: questionPackageId,
          name: questionPackageData.content.name,
        },
        PDFPackageInfo: {
          id: PDFPackageId,
          collectionId: PDFPackageData.content.collectionId,
          name: PDFPackageData.content.packageName,
        },
      } as QuizAuditDBModel;

      const quiz: CreateQuizDBExtendModel = {
        ...audit,
        content: {
          name: quizPayload.name,
          status: QuizStatusTypes.Pending,
          configration: quizPayload.configration,
          questions: questionPackageData.content.questions.map((question) => ({
            ask: question,
            answer: null,
            point: null,
          })),
        },
      };

      const quizDocRef = await addDoc(collection(db, "quizzes"), quiz);

      await setDoc(quizDocRef, { questionPackage: questionPackageDocRef }, { merge: true });

      return { ...quiz, id: quizDocRef.id };
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAll(): Promise<QuizResponseDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, this.quizCollectionName),
          or(
            where("assignedUserInfo.id", "==", getUserId()),
            where("creatorUserInfo.id", "==", getUserId())
          )
        )
      );
      const quizzes: QuizResponseDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const quizData = doc.data() as QuizResponseDBModel;
        quizzes.push({
          ...quizData,
          id: doc.id,
        });
      });
      return quizzes;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getCompletedQuizzes(): Promise<QuizResponseDBModel[]> {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, this.quizCollectionName),
          and(
            or(
              where("assignedUserInfo.id", "==", getUserId()),
              where("creatorUserInfo.id", "==", getUserId())
            ),
            where("content.status", "==", QuizStatusTypes.Completed)
          )
        )
      );
      const quizzes: QuizResponseDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const quizData = doc.data() as QuizResponseDBModel;
        quizzes.push({
          ...quizData,
          id: doc.id,
        });
      });

      return quizzes;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getById(id: string): Promise<QuizResponseDBModel> {
    try {
      const docRef = doc(db, this.quizCollectionName, id);
      const docSnapshot = await getDoc(docRef);

      const quizData = docSnapshot.data() as QuizResponseDBModel;

      const response = {
        ...quizData,
        id: docRef.id,
      };

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getByIds(id: string): Promise<QuizDBModel> {
    try {
      const docRef = doc(db, this.quizCollectionName, id);
      const docSnapshot = await getDoc(docRef);

      const quizData = docSnapshot.data() as QuizResponseDBModel;
      const quiz: QuizDBModel = {
        ...quizData.content,
        id: docSnapshot.id,
      };
      return quiz;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async update(id: string, updatedQuestions: QuestionDBModel[]): Promise<string> {
    try {
      const docRef = doc(db, this.quizCollectionName, id);
      await setDoc(
        docRef,
        { content: { questions: updatedQuestions, status: QuizStatusTypes.Completed } },
        { merge: true }
      );
      return docRef.id;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const docRef = doc(db, this.quizCollectionName, id);
      await deleteDoc(docRef);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }
}

export default QuizDBServices;