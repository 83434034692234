import React, { useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabItem } from "./types";

interface IProps {
  activeTab: number;
  tabs: TabItem[];
  onTabClick: (id: number) => void;
}

const Tab: React.FC<IProps> = ({ activeTab, tabs, onTabClick }) => {
  const [activeTabId, setActiveTabId] = useState<number>(activeTab);

  const handleTabClick = (tab: { name: string; id: number }) => {
    setActiveTabId(tab.id);
    onTabClick(tab.id);
  };

  useEffect(() => {
    setActiveTabId(activeTab);
  }, [activeTab]);

  return (
    <div className="tabs">
      {tabs.map((tab) => (
        <div
          key={tab.id}
          className={`tab ${tab.id === activeTabId ? "active" : ""} ${
            tab.disabled ? "disabled" : ""
          }`}
          onClick={() => !tab.disabled && handleTabClick(tab)}
          title={tab.title}
        >
          {tab.icon && (
            <span className="tab-icon">
              <FontAwesomeIcon icon={tab.icon} />
            </span>
          )}
          {tab.name}
          {!tab.icon && tab.customIcon && (
            <span className="tab-icon">{tab.customIcon}</span>
          )}
        </div>
      ))}
    </div>
  );
};

export default Tab;
