import React from "react";
// import { saveAs } from "file-saver";
import { useTranslation } from "react-i18next";
import { IQuizResponseModel } from "../../../services/ui-services/quizService/types";
import { IQuizQuestionModel } from "../../../components/QuizChat/types";
// import Button from "../../../components/Button";

interface TableProps {
  data: IQuizResponseModel[];
}

const PackagesTable: React.FC<TableProps> = ({ data }) => {
  const { t } = useTranslation();
  const quizzesInfo = data.map((item) => {
    return { name: item.content.name, key: item.id };
  });

  const allQuestions: IQuizQuestionModel[] =
    data.length > 0 && data[0].content.questions
      ? data[0].content.questions
      : [];

  const questionPoints: {
    [questionKey: string]: { [quizKey: string]: number };
  } = {};
  allQuestions.forEach((question) => (questionPoints[question.ask] = {}));

  allQuestions.forEach((question) => {
    data.forEach((item) => {
      const { content, id } = item;
      if (content.questions && id) {
        const point =
          content.questions.find((q) => q.ask === question.ask)?.point || 0;
        questionPoints[question.ask][id] = point;
      }
    });
  });

  // const handleDownload = () => {
  //   const csvData = [
  //     ["Soru", ...quizNames].map((col) => `"${col}"`).join("\t"), // Sütun başlıklarını tab boşluğuyla ayırarak birleştirin ve tırnak içine alın
  //     ...allQuestions.map((question) =>
  //       [question.ask, ...quizNames.map((quizName) => questionPoints[question.ask][quizName])].map((col) => `"${col}"`).join("\t")
  //     ),
  //   ].join("\n");

  //   // Her satırın sonuna boşluk ekleyin
  //   const csvContent = csvData.replace(/^(.*)$/gm, "$1 ");

  //   // Karakter kümesini tanımlayarak dosya başına ekleyin ve karakter kümesini UTF-16LE olarak ayarlayın
  //   const csvBlob = new Blob(["\uFEFF" + csvContent], { type: "text/csv;charset=utf-16le" });

  //   // Blob dosyasını indirme işlemini gerçekleştirin
  //   saveAs(csvBlob, "table_data.csv");
  // };

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "50%" }}>{t("dashboard.compareTable.ask")}</th>
            {quizzesInfo.map((quiz) => (
              <th style={{ width: "10%" }} key={quiz.key}>
                {quiz.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {allQuestions.map((question, index) => (
            <tr key={index}>
              <td>{question.ask}</td>
              {quizzesInfo.map((quiz) => (
                <td key={quiz.key}>{questionPoints[question.ask][quiz.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {/* <div className="download-button-container">
        <Button onClick={handleDownload}>Excel'e Aktar</Button>
      </div> */}
    </div>
  );
};

export default PackagesTable;
