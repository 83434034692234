import {
  CreateUpdateInstructionRequestModel,
  GetInstructionResponseModel,
} from "./types";
import HealthInstructionsDBServices from "../../db-services/healthInstructionsDBService";

class HealthInstructionsService {
  private dbService;

  constructor() {
    this.dbService = new HealthInstructionsDBServices();
  }

  async update(payload: CreateUpdateInstructionRequestModel): Promise<boolean> {
    try {
      await this.dbService.update({ ...payload, id: '354y8JbLsaY2zrtHRcZF' });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async get(): Promise<GetInstructionResponseModel | null> {
    try {
      const response = await this.dbService.get();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default HealthInstructionsService;
