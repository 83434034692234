import { IReferenceModel } from "../../services/ui-services/chatService/types";

export interface IActiveReferenceModel extends IReferenceModel {
  messageId: string;
  activeIndex: number;
}

export enum IConfigurationTypes {
  WindowSize = "windowSize",
  Vectorization = "vectorization",
}