import React from "react";
import Modal from "../../../components/Modal";
import "./style.scss";

interface IProps {
  visible: boolean;
  documentName: string;
  content: string;
  closeModal: () => void;
}

const SummaryModal: React.FC<IProps> = ({
  visible,
  documentName,
  content,
  closeModal,
}) => {
  return (
    <Modal
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      title="Summary"
      className="document-summary-modal"
    >
      <p className="sub-header">
        <b>Source: </b>
        {documentName}
      </p>

      <br />
      <span className="content">{content}</span>
    </Modal>
  );
};

export default SummaryModal;
