import React, { useCallback, useEffect, useMemo, useState } from "react";
import PatientService from "../../services/ui-services/patientService";
import { useNavigate, useParams } from "react-router-dom";
import { Skeleton } from "antd";
import { IPatientFormModel, convertPatientFormModelToPayloadModel } from "../HealthCreatePatient/CreateUpdatePatient/types";
import CreateUpdatePatientForm from "../HealthCreatePatient/CreateUpdatePatient";
import { toast } from "react-toastify";

const HealthUpdatePatient = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [patient, setPatient] = useState<IPatientFormModel | null>(null);

  const patientService = useMemo(() => {
    return new PatientService();
  }, []);

  const getPatient = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);
      const res = await patientService.getById(id);
      setPatient({ ...res });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id, patientService]);

  useEffect(() => {
    getPatient();
  }, [getPatient]);

  const onUpdate = async (values: IPatientFormModel) => {
    if (!id) return;
    try {
      setSubmitting(true);
      const payload = convertPatientFormModelToPayloadModel(values);
      await patientService.update(id, payload);
      toast("Success");
      navigate(-1);
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      setSubmitting(false);
    }
  };

  const onCancel = () => {
    navigate(-1);
  };

  if (loading) {
    return <Skeleton />;
  }

  if (!patient) {
    return <div>Patient not found!</div>;
  }

  return (
    <CreateUpdatePatientForm
      initialValues={patient}
      onCreate={onUpdate}
      title="Update"
      submitting={submitting}
      updateMode
      onCancel={onCancel}
    />
  );
};

export default HealthUpdatePatient;
