import React, { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import MessageBox from "../MessageBox";
import ChatService from "../../services/ui-services/chatService";
import Input from "../Input";
import QuizService from "../../services/ui-services/quizService";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { generateUniqueId } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import {
  IQuizResponseModel,
  QuizStatusTypes,
} from "../../services/ui-services/quizService/types";
import { IMessageModel } from "../../services/ui-services/chatService/types";
import Loader from "../Loading/Loader";
import { IActiveReferenceModel } from "../Chat/types";
import { IQuizMessageModel } from "./types";

interface IProps {
  activeReference?: IActiveReferenceModel;
  quizInfo?: IQuizResponseModel;
  handleReference: (data: IActiveReferenceModel) => void;
}

const QuizChat: FC<IProps> = ({
  activeReference,
  handleReference,
  quizInfo,
}) => {
  const quizService = useMemo(() => {
    return new QuizService();
  }, [])

  const chatService = useMemo(() => {
    return new ChatService()
  }, [])

  const navigate = useNavigate();
  const { t } = useTranslation();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IQuizMessageModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const askQuizQuestionsCallback = useCallback(async () => {
    if (quizInfo?.content.questions.length) {
      try {
        setIsLoading(true);
        for (let i = 0; i < quizInfo?.content.questions.length; i++) {
          const element = quizInfo?.content.questions[i];

          const currentMessages: IQuizMessageModel[] = [];

          if (element.answer) {
            const message: IQuizMessageModel = {
              id: element.ask,
              ask: element.ask,
              answer: element.answer,
              point: element.point,
            };
            currentMessages.push(message);
          } else {
            await chatService.askCollectionVectorization(
              element.ask,
              quizInfo.content.configration,
              quizInfo.PDFPackageInfo.collectionId
            ).then(async (response) => {
              if (!response.result_status) {
                toast.error(response.error_message);
              } else {
                const message: IQuizMessageModel = {
                  id: element.ask,
                  ask: element.ask,
                  answer: response.response,
                  point: element.point,
                };
                currentMessages.push(message);
              }
            });
          }

          setMessages((prev) => [...prev, ...currentMessages]);
        }
      } catch (error) {
        toast.error(t("error.tryAgain"));
      } finally {
        setIsLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quizInfo]);

  useEffect(() => {
    askQuizQuestionsCallback();
  }, [askQuizQuestionsCallback]);

  const isValidRatingSaveButton = () => {
    return !messages?.find((question) => question.point === null) && !isLoading;
  };

  const onChangeMessagePoint = (messageId: string, value: number) => {
    var currentMessages: IQuizMessageModel[] = [...messages];
    const messageIndex = currentMessages.findIndex(
      (message) => message.id === messageId
    );
    const currentValue = value > 10 ? 10 : value < 1 ? 1 : value;
    currentMessages[messageIndex].point = currentValue as number;

    setMessages(currentMessages);
  };

  const handleUpdateRate = async () => {
    try {
      setIsLoading(true);

      const payload = messages.map((question) => {
        const updatedAnswer: IMessageModel | null = question.answer
          ? {
              ...question.answer,
              id: generateUniqueId(),
            }
          : null;
        return {
          ask: question.ask,
          point: question.point || null,
          answer: updatedAnswer,
        };
      });

      await quizService.updateQuizQuestions(quizInfo?.id!, payload);
      navigate("/base-dashboard");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Son mesaja scroll yap
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      const lastMessage = chatContainer.lastElementChild as HTMLElement;
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [messages.length]);

  const onClickBox = (data: IMessageModel, index: number) => {
    if (activeReference?.messageId === data.id && data.sources) {
      handleReference({
        messageId: data.id,
        activeIndex: index,
        ...data.sources[index],
      });
    } else if (data.sources) {
      handleReference({
        messageId: data.id,
        activeIndex: 0,
        ...data.sources[0],
      });
    }
  };

  return (
    <div className="quiz-chat-container">
      <div className="messages" ref={chatContainerRef}>
        {messages.map((message, index) => {
          const askMessage = {
            answer: message.ask,
            is_question: true,
            id: message.ask,
            point: message.point,
          };
          const answerMessage: IMessageModel | null = message.answer
            ? {
                ...message.answer,
                id: message.answer.answer,
              }
            : null;
          return (
            <div key={index} className="message">
              <MessageBox
                onClickBox={onClickBox}
                data={askMessage}
                activeReference={activeReference}
              />
              {answerMessage && (
                <MessageBox
                  onClickBox={onClickBox}
                  data={answerMessage}
                  activeReference={activeReference}
                />
              )}

              <div className="point-container">
                <Input
                  error={message.point === null}
                  max={10}
                  min={1}
                  key={message.id}
                  type="number"
                  value={message.point || 0}
                  onChange={(e) =>
                    onChangeMessagePoint(message.id, e as number)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>

      {isLoading && (
        <div className="loading-container">
          <Loader />
        </div>
      )}

      {quizInfo?.content.status === QuizStatusTypes.Pending && (
        <div className="rating-save-container">
          <Button
            onClick={handleUpdateRate}
            className="save-button"
            disabled={!isValidRatingSaveButton()}
            iconSize="2x"
            children={t("chat.saveButton")}
          />
        </div>
      )}
    </div>
  );
};

export default memo(QuizChat);
