import { Button, Card, Input, Select, Skeleton, Slider, Tooltip } from "antd";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import PatientService from "../../../services/ui-services/patientService";
import { IPatientFormModel } from "../../HealthCreatePatient/CreateUpdatePatient/types";
import { toast } from "react-toastify";
import { IDiagnosisSidebar } from "..";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { DATETIME_FORMAT } from "../../../constants";
import { useAuth } from "../../../context/store/authContext";
import TriageDiagnosisCommentModal from "../TriageDiagnosisCommentModal";
import { IInstructionModel } from "../../../services/db-services/healthInstructionsDBService/types";
import type { InputNumberProps } from "antd";

type Props = {
  onClose: () => void;
  patient: IPatientFormModel | null;
  id: string;
  existDiagnosis: IDiagnosisSidebar | null;
  onUpdateDiagnosis: (value: IDiagnosisSidebar) => void;
  instructions: IInstructionModel[];
  onSaveInstruction: (
    index: number,
    content: string,
    callback: Function
  ) => Promise<void>;
};

const { Option } = Select;
const { TextArea } = Input;

const modelOptions = ["gpt-4o-mini", "gpt-4o"];

const DiagnosisSidebar: FC<Props> = ({
  onClose,
  patient,
  id,
  existDiagnosis,
  onUpdateDiagnosis,
  instructions,
  onSaveInstruction,
}) => {
  const [showCommentModal, setShowCommentModal] = useState(false);
  const { loggedInUserData } = useAuth();
  const [instruction, setInstruction] = useState("");
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState<
    undefined | number
  >();
  const [temperature, setTemperature] = useState(0.2);

  const patientService = useMemo(() => {
    return new PatientService();
  }, []);

  const [loading, setLoading] = useState(false);
  const [diagnosis, setDiagnosis] = useState("");
  const [lastResultDate, setLastResultDate] = useState("");
  const [model, setModel] = useState("gpt-4o-mini");
  const [updatingInstruction, setUpdatingInstruction] = useState(false);

  const getDiagnosis = useCallback(async () => {
    try {
      setLoading(true);
      const res = await patientService.makeDiagnosis({
        firebase_document_id: id,
        patient_id: patient?.patientID ?? "",
        email: loggedInUserData?.email ?? "",
        instructions: instruction,
        temperature,
        model,
      });
      setDiagnosis(res?.answer ?? "");
      const diagnosisDate = new Date().toISOString();
      setLastResultDate(diagnosisDate);
      onUpdateDiagnosis({ date: diagnosisDate, value: res?.answer ?? "" });
    } catch (error) {
      console.log(error);
      toast.warn((error as Error).message);
    } finally {
      setLoading(false);
    }
  }, [
    id,
    patient?.patientID,
    patientService,
    onUpdateDiagnosis,
    loggedInUserData?.email,
    temperature,
    instruction,
    model,
  ]);

  const onRefresh = () => {
    setShowCommentModal(true);
  };

  const onCloseCommentModal = () => {
    setShowCommentModal(false);
    getDiagnosis();
  };

  useEffect(() => {
    if (existDiagnosis) {
      setDiagnosis(existDiagnosis.value);
      setLastResultDate(existDiagnosis.date);
    } else {
      getDiagnosis();
    }
  }, [getDiagnosis, existDiagnosis]);

  useEffect(() => {
    setInstruction(instructions.find((inst) => inst.isDefault)?.content ?? "");
    setSelectedInstructionIndex(
      instructions.findIndex((inst) => inst.isDefault)
    );
  }, [instructions]);

  const onChangeInstruction = (index: number) => {
    setSelectedInstructionIndex(index);
    setInstruction(instructions[index]?.content ?? "");
  };

  const onChangeTemperature: InputNumberProps["onChange"] = (newValue) => {
    setTemperature(newValue as number);
  };

  const onChangeModel = (value: string) => {
    setModel(value);
  };

  const onClickSaveInstruction = () => {
    setUpdatingInstruction(true);
    onSaveInstruction(selectedInstructionIndex!, instruction, () => {
      setUpdatingInstruction(false);
    });
  };

  return (
    <>
      <Card
        className="patient-side-bar"
        extra={
          <Button onClick={onClose} icon={<FontAwesomeIcon icon={faClose} />} />
        }
      >
        <Slider
          min={0}
          max={2}
          onChange={onChangeTemperature}
          value={typeof temperature === "number" ? temperature : 0}
          step={0.01}
        />
        <Select
          style={{ width: "100%", marginBottom: "5px" }}
          value={model}
          onChange={onChangeModel}
        >
          {modelOptions.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          style={{ width: "100%", marginBottom: "5px" }}
          value={selectedInstructionIndex}
          onChange={onChangeInstruction}
        >
          {instructions.map((inst, index) => (
            <Option value={index}>{inst.title}</Option>
          ))}
        </Select>
        <TextArea
          onChange={(e) => setInstruction(e.target.value)}
          value={instruction}
          rows={12}
          placeholder="Instruction"
          style={{ resize: "none", marginBottom: "10px" }}
        />
        <Button
          onClick={onClickSaveInstruction}
          type="primary"
          disabled={!instruction || updatingInstruction}
          loading={updatingInstruction}
        >
          Save Instruction
        </Button>
        {loading ? (
          <Skeleton active />
        ) : (
          <div style={{ width: "100%", position: "relative" }}>
            <div
              style={{
                width: "100%",
                position: "relative",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontWeight: "bold" }}>
                {`Result Date: ${moment(lastResultDate).format(
                  DATETIME_FORMAT
                )}`}
              </span>
              <Tooltip title="Refresh">
                <Button
                  icon={<FontAwesomeIcon icon={faRefresh} />}
                  onClick={onRefresh}
                />
              </Tooltip>
            </div>
            <div
              style={{ width: "100%", position: "relative" }}
              dangerouslySetInnerHTML={{ __html: diagnosis }}
            />
          </div>
        )}
      </Card>
      {showCommentModal && (
        <TriageDiagnosisCommentModal
          show
          onClose={onCloseCommentModal}
          patient_id={patient?.patientID || ""}
          query_type="diagnosis"
        />
      )}
    </>
  );
};

export default DiagnosisSidebar;
