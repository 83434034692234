import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import React, { CSSProperties, FC } from "react";

type Props = {
    src: string;
    alt: string;
    style?: CSSProperties;
}

const DownloadableImage: FC<Props> = ({ src, alt, style }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = src;
    link.download = "image";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <img src={src} alt={alt} style={style} />
      <Button
        onClick={handleDownload}
        style={{
          position: "absolute",
          top: "10px",
          right: "10px",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          borderRadius: "4px",
          padding: "5px 10px",
          cursor: "pointer",
        }}
      >
        <FontAwesomeIcon icon={faDownload} />
      </Button>
    </div>
  );
};

export default DownloadableImage;
