import { collection, doc, getDocs, query, updateDoc } from "firebase/firestore";
import { db } from "../../firebase-services";
import { IInstructionDBModel } from "./types";

class HealthInstructionsDBServices {
  private collectionName = "healthInstructions";
  private dbRef = collection(db, this.collectionName);

  async update(payload: IInstructionDBModel): Promise<void> {
    try {
      if (!payload?.id) {
        throw new Error("Id is required");
      }
      const docRef = doc(db, this.collectionName, payload.id!);

      await updateDoc(docRef, { ...payload });
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async get(): Promise<IInstructionDBModel> {
    try {
      const querySnapshot = await getDocs(query(this.dbRef));

      const instructions: IInstructionDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as IInstructionDBModel;
        instructions.push({
          ...packageData,
          id: doc.id,
        });
      });
      return instructions[0];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default HealthInstructionsDBServices;
