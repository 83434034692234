import React from "react";
import "./style.scss";
import Button from "../Button";
import {
  faFilePen,
  faMessage,
  faPen,
  faPlus,
  faSpinner,
  faTrash,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  IDocumentModel,
  IPDFPackageResponseModel,
} from "../../services/ui-services/documentPackageService/types";
import { Tooltip } from "react-tooltip";
import { StatusTypes } from "../../models/Status";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  packageInfo: IPDFPackageResponseModel;
  onDeletePDFPackage: (selectedData: IPDFPackageResponseModel) => void;
  onAddDocument: (selectedData: IPDFPackageResponseModel) => void;
  updateCollectionContext: (selectedData: IPDFPackageResponseModel) => void;
  onClickSummary: (
    selectedData: IPDFPackageResponseModel,
    document: IDocumentModel
  ) => void;
  onDeleteDocumentFromCollection: (
    selectedData: IPDFPackageResponseModel,
    document: IDocumentModel
  ) => void;
}

const PDFPackageBox: React.FC<Props> = ({
  packageInfo,
  onDeletePDFPackage,
  onAddDocument,
  onDeleteDocumentFromCollection,
  onClickSummary,
  updateCollectionContext,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="package-box-container">
      <div className="box-header">{packageInfo.content.packageName}</div>
      <div className="box-content">
        <div className="context-info">
          <span className="label">
            <Tooltip id="edit" content={t("pdfPackageBox.editCollection")} />
            <Button
              data-tooltip-id="edit"
              onClick={() => updateCollectionContext(packageInfo)}
              className="edit-context-button"
              icon={faPen}
            />
            {t("pdfPackageBox.context")}:
          </span>
          <span className="value">{packageInfo.content.context}</span>
        </div>
        {!!packageInfo.content.PDFS?.length &&
          packageInfo.content.PDFS.map((document, pdfIndex) => (
            <div key={document.docId} className="document-container">
              <span className="name">
                <b>{pdfIndex + 1})</b> {document.name}
                {document.docStatus === StatusTypes.InProgress && (
                  <>
                    <Tooltip id="spinnerDoc" />
                    <div
                      data-tooltip-id="spinnerDoc"
                      data-tooltip-content={t("pdfPackageBox.spinner")}
                      className="spinner"
                    >
                      {<FontAwesomeIcon icon={faSpinner} />}
                    </div>
                  </>
                )}
                {document.docStatus === StatusTypes.Error && (
                  <>
                    <Tooltip id="errorDoc" />
                    <div
                      data-tooltip-id="errorDoc"
                      data-tooltip-content={t("pdfPackageBox.errorDoc")}
                      className="error-document"
                    >
                      {<FontAwesomeIcon icon={faWarning} />}
                    </div>
                  </>
                )}
              </span>

              <div className="actions">
                <Tooltip
                  key={document.url}
                  id={document.url}
                  content={
                    document.status === StatusTypes.InProgress
                      ? t("pdfPackageBox.spinner")
                      : document.status === StatusTypes.Error
                      ? t("pdfPackageBox.errorSummary")
                      : t("pdfPackageBox.summary")
                  }
                />
                <Button
                  data-tooltip-id={document.url}
                  onClick={() => onClickSummary(packageInfo, document)}
                  className="action-button"
                  icon={faFilePen}
                  iconSize="xs"
                  status={document.status}
                  disabled={document.docStatus !== StatusTypes.Done}
                />

                <Tooltip id="deleteDocument" />
                <Button
                  onClick={() =>
                    onDeleteDocumentFromCollection(packageInfo, document)
                  }
                  data-tooltip-id="deleteDocument"
                  data-tooltip-content={t("pdfPackageBox.deleteDocument")}
                  className="action-button"
                  icon={faTrash}
                  iconSize="xs"
                />
              </div>
            </div>
          ))}
      </div>
      <div className="box-footer">
        {
          <div className="actions">
            <Tooltip id="ask" content={t("pdfPackageBox.ask")} />
            <Button
              data-tooltip-id="ask"
              onClick={() => navigate(`/base-collections/${packageInfo.id}`)}
              className="action-button"
              icon={faMessage}
              disabled={
                !packageInfo.content.PDFS.length ||
                !packageInfo.content.PDFS.find(
                  (document) => document.docStatus === StatusTypes.Done
                )
              }
            />
            <Tooltip
              id="delete"
              content={t("pdfPackageBox.deleteCollection")}
            />
            <Button
              data-tooltip-id="delete"
              onClick={() => onDeletePDFPackage(packageInfo)}
              className="action-button"
              icon={faTrash}
            />

            {packageInfo.content.PDFS.length === 10 ? (
              <>
                <Tooltip id="add" content={t("pdfPackageBox.maxAdd")} />
                <Button
                  data-tooltip-id="add"
                  disabled
                  className="action-button"
                  icon={faPlus}
                />
              </>
            ) : (
              <>
                <Tooltip id="add" content={t("pdfPackageBox.addPDF")} />
                <Button
                  data-tooltip-id="add"
                  onClick={() => onAddDocument(packageInfo)}
                  className="action-button"
                  icon={faPlus}
                />
              </>
            )}
          </div>
        }
      </div>
    </div>
  );
};

export default PDFPackageBox;
