import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import "./style.scss";
import QuizBox from "../../components/QuizBox";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import QuizService from "../../services/ui-services/quizService";
import Button from "../../components/Button";
import CreateModal from "./CreateModal";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import QuestionService from "../../services/ui-services/questionService";
import PDFPackageService from "../../services/ui-services/documentPackageService";
import UserService from "../../services/ui-services/userService";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { getUserId } from "../../services/firebase-services/auth";
import {
  IQuizPayload,
  IQuizResponseModel,
  QuizStatusTypes,
} from "../../services/ui-services/quizService/types";
import { IAskConfigration } from "../../services/ui-services/chatService/types";
import { Tooltip } from "react-tooltip";

interface ISelectedCard {
  id: string;
  isChecked: boolean;
}

const BaseAssignments: React.FC = () => {
  const questionService = useMemo(() => {
    return new QuestionService();
  }, [])

  const quizService = useMemo(() => {
    return new QuizService();
  }, [])

  const userService = useMemo(() => {
    return new UserService();
  }, [])

  const pdfPackageService = useMemo(() => {
    return new PDFPackageService();
  }, [])

  const { t } = useTranslation();
  const { onCompleted, onLoading } = useLoading();
  const [createModalVibisle, setCreateModalVibisle] = useState<boolean>(false);
  const [quizzes, setQuizzes] = useState<IQuizResponseModel[]>([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState<IQuizResponseModel[]>(
    []
  );
  const defaultFilterDropdownOption: DropdownOption = {
    value: "all",
    label: t("dashboard.all"),
  };
  const filterDropdownOptions: DropdownOption[] = [
    { value: "all", label: t("quizzes.filterMenu.all") },
    { value: "assignedToMe", label: t("quizzes.filterMenu.assignedToMe") },
    { value: "myCreations", label: t("quizzes.filterMenu.myCreations") },
  ];
  const [filterBy, setFilterBy] = useState<DropdownOption | undefined>(
    undefined
  );
  const [selectedQuizzes, setSelectedQuizzes] = useState<ISelectedCard[]>([]);
  const [questionsPackages, setQuestionsPackages] = useState<DropdownOption[]>(
    []
  );
  const [PDFPackages, setPDFPackages] = useState<DropdownOption[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<DropdownOption[]>([]);

  const getQuizzes = async () => {
    const fetchedQuizzes = await quizService.getAllQuizzes();
    setQuizzes(fetchedQuizzes);
    setFilteredQuizzes(fetchedQuizzes);
    setSelectedQuizzes(
      fetchedQuizzes.map((quiz) => ({ id: quiz.id, isChecked: false }))
    );
  };

  const getQuestions = async () => {
    const packages = await questionService.getAllQuestions();
    const dropdownPackages = packages?.map((questionPackage) => {
      return {
        value: questionPackage.id as string,
        label: questionPackage.content.name,
      };
    });
    setQuestionsPackages(dropdownPackages || []);
  };

  const getPDFPackages = async () => {
    const packages = await pdfPackageService.getAllPackages();
    const dropdownPackages = packages?.map((questionPackage) => {
      return {
        value: questionPackage.id as string,
        label: questionPackage.content.packageName,
      };
    });
    setPDFPackages(dropdownPackages || []);
  };

  const getUsers = async () => {
    const users = await userService.getAllUsers();
    const dropdownUsers = users?.map((user) => {
      return {
        value: user.id as string,
        label: user.email!,
      };
    });
    setAssignedUsers(dropdownUsers || []);
  };

  const loadComponentCallback = useCallback(async () => {
    try {
      onLoading();
      await getQuizzes();
      await getUsers();
      await getQuestions();
      await getPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted, onLoading]);

  useEffect(() => {
    loadComponentCallback();
  }, [loadComponentCallback]);

  useEffect(() => {
    setFilterBy(defaultFilterDropdownOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    switch (filterBy && filterBy.value) {
      case "all":
        setFilteredQuizzes(quizzes);
        break;
      case "assignedToMe":
        const assignedToMe = quizzes.filter(
          (quiz) => quiz.assignedUserInfo.id === getUserId()
        );
        setFilteredQuizzes(assignedToMe);
        break;
      case "myCreations":
        const myCreations = quizzes.filter(
          (quizzes) => quizzes.creatorUserInfo.id === getUserId()
        );
        setFilteredQuizzes(myCreations);
        break;
      default:
        break;
    }
  }, [filterBy, quizzes]);

  const handleCreateQuiz = async (
    name: string,
    configration: IAskConfigration,
    questionPackeId: string,
    assignedUserId: string,
    pdfPackeId: string
  ) => {
    try {
      onLoading();
      const payload: IQuizPayload = {
        name: name,
        configration: { ...configration, context: "" },
        status: QuizStatusTypes.Pending,
      };
      await quizService.createQuiz(
        payload,
        questionPackeId,
        assignedUserId,
        pdfPackeId
      );
      await getQuizzes();
      setCreateModalVibisle(false);
    } catch (error) {
      console.log(error);
    } finally {
      onCompleted();
    }
  };

  return (
    <div className="quizzes-container">
      <div className="actions-container">
        <Button
          onClick={async () => {
            onLoading();
            const deleteQuizPromises = selectedQuizzes
              .filter((quiz) => quiz.isChecked)
              .map((quiz) => quiz.id)
              .map(async (quizId) => {
                await quizService.deleteQuizById(quizId);
              });
            await Promise.all(deleteQuizPromises);
            await getQuizzes();
            onCompleted();
          }}
          disabled={
            selectedQuizzes
              .filter((quiz) => quiz.isChecked)
              .map((quiz) => quiz.id).length < 1
          }
        >
          {t("quizzes.deleteSelected")}
        </Button>
        <Dropdown
          className="filter-dropdown"
          options={filterDropdownOptions}
          onSelect={setFilterBy}
          value={filterBy}
        />

        <Tooltip id="add" />
        <Button
          data-tooltip-id="add"
          data-tooltip-content={t("page.addNew")}
          onClick={() => setCreateModalVibisle(!createModalVibisle)}
          icon={faPlus}
        />
      </div>
      <div className="quizzes-content">
        {!!filteredQuizzes.length &&
          filteredQuizzes.map((quiz, index) => {
            return (
              <QuizBox
                checked={
                  selectedQuizzes.find((q) => q.isChecked && q.id === quiz.id)
                    ? true
                    : false
                }
                onDeleteCard={(isSelected) => {
                  const selectedQuiz = selectedQuizzes.find(
                    (q) => q.id === quiz.id
                  );
                  if (selectedQuiz) {
                    selectedQuiz.isChecked = isSelected;
                    setSelectedQuizzes((prev) => [...prev]);
                  }
                }}
                key={index}
                quiz={quiz}
              />
            );
          })}
      </div>

      {createModalVibisle && (
        <CreateModal
          PDFPackageDropdownData={PDFPackages}
          questionsPackagesDropdownData={questionsPackages}
          usersDropdownData={assignedUsers}
          visible={createModalVibisle}
          closeModal={() => setCreateModalVibisle(!createModalVibisle)}
          onCreateHandler={handleCreateQuiz}
        />
      )}
    </div>
  );
};

export default memo(BaseAssignments);
