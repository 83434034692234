import React, { FC } from "react";
import "./style.scss";

import { FileUploader } from "react-drag-drop-files";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface IProps {
  isMultiple?: boolean;
  handleChange: (fileList: FileList | File) => void;
  maxSize?: number;
  minSize?: number;
}

const DropZone = () => {
  const { t } = useTranslation();
  return (
    <div className="dropzone">
      <p>{t("fileInput.dropzone")}</p>
    </div>
  );
};

const FileInput: FC<IProps> = ({
  handleChange,
  isMultiple,
  maxSize,
  minSize,
}) => {
  return (
    <FileUploader
      maxSize={maxSize}
      minSize={minSize}
      multiple={isMultiple}
      className="file-input"
      handleChange={handleChange}
      name="file"
      children={<DropZone />}
      types={["pdf"]}
      onSizeError={(message:string) => toast.error(message)}
    />
  );
};

export default FileInput;
