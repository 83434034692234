import { FC, memo, useCallback, useEffect, useState } from "react";
import DinamicTable from "../../../DinamicTable";
import { IExecuteGeneratedCodeResponseModel } from "../../../../services/ui-services/queryLogsService/types";
import { Button, Result, Skeleton } from "antd";
import DownloadableImage from "../../../DownloadableImage";
import Plot from 'react-plotly.js';

type Props = {
  generaterFunction?: () => Promise<IExecuteGeneratedCodeResponseModel>;
};

const IndicatorContainer: FC<Props> = ({
  generaterFunction,
}) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [answer, setAnswer] = useState<string | number | any[]>("");
  const [resultType, setResultType] = useState<string>();

  const execute = useCallback(async () => {
    if (!generaterFunction) return;
    try {
      setLoading(true);
      const res = await generaterFunction();
      setAnswer(res.answer);
      setResultType(res.result_type);
    } catch (error) {
      setIsError(true);
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [generaterFunction]);

  useEffect(() => {
    execute();
  }, [execute]);

  const render = useCallback(() => {
    if (Array.isArray(answer)) {
      return (
        <div>
          <DinamicTable items={answer} />
        </div>
      );
    } else if (resultType === "plot") {
      return (
        <div>
          <DownloadableImage
            src={`https://dev-information-retrieval-store.s3.us-west-2.amazonaws.com/data_insight_images/${answer}`}
            alt="generated_img"
            style={{ width: "100%" }}
          />
        </div>
      );
    } else if (resultType === "plotly") {
      return (
        <div>
          <Plot
            {...JSON.parse(answer as any)}
            style={{ width: '100%' }}
          />
        </div>
      );
    } else {
      return (
        <div>
          <p>{answer}</p>
        </div>
      );
    }
  }, [answer, resultType]);

  if (!generaterFunction) {
    return null;
  }

  if (loading) {
    return <Skeleton active />;
  }

  if (isError) {
    return (
      <Result
        status="error"
        title="Something went wrong"
        subTitle="Please try again by clicking the button below."
        extra={
          <Button type="primary" onClick={execute}>
            Try Again
          </Button>
        }
      />
    );
  }

  return <>{render()}</>;
};

export default memo(IndicatorContainer);
