import { FC, ReactNode, memo, useEffect, useMemo } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../context/store/authContext";
import {
  listenToEmailVerifiedChanges,
  resendEmailVerification,
} from "../../services/firebase-services/auth";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { User } from "firebase/auth";
import UserService from "../../services/ui-services/userService";
import { UserRoleTypes } from "../../services/db-services/userDBService/types";

interface ILayoutProps {
  children: ReactNode;
}

const EmailVerification: FC<ILayoutProps> = ({ children }) => {
  const userService = useMemo(() => {
    return new UserService();
  }, [])

  const { loggedInUserData, updateLoggedInUserData } = useAuth();
  const { t } = useTranslation();

  const resendEmail = async () => {
    try {
      await resendEmailVerification(loggedInUserData!);
      toast.success(t("success.send"));
    } catch (error) {
      toast.error(t("error.tryAgain"));
      console.log(error);
    }
  };

  const handleEmailVerificationChange = async (user: User) => {
    const userDBData = await userService.getUser(user);
    const currentUser = {
      ...user,
      role: userDBData.role || UserRoleTypes.Standart,
    };
    updateLoggedInUserData(currentUser);
  };

  useEffect(() => {
    if (!loggedInUserData?.emailVerified) {
      const unsubscribe = listenToEmailVerifiedChanges(
        loggedInUserData!,
        handleEmailVerificationChange
      );
      return () => unsubscribe();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedInUserData]);

  return !loggedInUserData?.emailVerified ? (
    <div className="verification-container">
      <div className="verification-bar">
        <span className="verification-info">
          <FontAwesomeIcon icon={faWarning} />
          {t("emailVerification.info")}
        </span>
        <button onClick={resendEmail} className="resend-button">
          {t("emailVerification.resendEmail")}
        </button>
      </div>
      {children}
    </div>
  ) : (
    <>{children}</>
  );
};

export default memo(EmailVerification);
