import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import "./style.scss";
import QuizBox from "../../components/QuizBox";
import QuizService from "../../services/ui-services/quizService";
import Dropdown, { DropdownOption } from "../../components/Dropdown";
import QuestionService from "../../services/ui-services/questionService";
import UserService from "../../services/ui-services/userService";
import Button from "../../components/Button";
import CompareModal from "./CompareModal";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { IQuizResponseModel } from "../../services/ui-services/quizService/types";

const BaseDashboard: React.FC = () => {
  const questionService = useMemo(() => {
    return new QuestionService();
  }, [])

  const quizService = useMemo(() => {
    return new QuizService();
  }, [])

  const userService = useMemo(() => {
    return new UserService();
  }, [])

  const { t } = useTranslation();
  const { onCompleted, onLoading } = useLoading();
  const defaultFilterDropdownOption: DropdownOption = {
    value: 0,
    label: t("dashboard.all"),
  };

  const [quizzes, setQuizzes] = useState<IQuizResponseModel[]>([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState<
    IQuizResponseModel[]
  >([]);

  const [questionsOptions, setQuestionsOptions] = useState<DropdownOption[]>(
    []
  );
  const [assignedUsersOptions, setAssignedUsersOptions] = useState<
    DropdownOption[]
  >([]);
  const [filterByCreator, setFilterByCreator] = useState<
    DropdownOption | undefined
  >(undefined);
  const [filterByAssigned, setFilterByAssigned] = useState<
    DropdownOption | undefined
  >(undefined);
  const [filterByPackages, setFilterByPackages] = useState<
    DropdownOption | undefined
  >(undefined);
  const [selectedQuizzes, setSelectedQuizzes] = useState<
    IQuizResponseModel[]
  >([]);
  const [compareModalVisible, setCompareModalVisible] =
    useState<boolean>(false);

  const getQuizzes = async () => {
    const fetchedQuizzes = await quizService.getCompletedQuizzess();
    setQuizzes(fetchedQuizzes);
    setFilteredQuizzes(fetchedQuizzes);
  };

  const getQuestions = async () => {
    const response = await questionService.getAllQuestions();
    const dropdownQuestions = response?.map((packages) => {
      return {
        value: packages.id as string,
        label: packages.content.name!,
      };
    });
    dropdownQuestions && setQuestionsOptions(dropdownQuestions);
  };

  const getUsers = async () => {
    const users = await userService.getAllUsers();
    const dropdownUsers = users?.map((user) => {
      return {
        value: user.id as string,
        label: user.email!,
      };
    });
    dropdownUsers && setAssignedUsersOptions(dropdownUsers);
  };

  const loadComponentCallback = useCallback(async () => {
    try {
      onLoading();
      await getQuizzes();
      await getQuestions();
      await getUsers();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted, onLoading]);

  useEffect(() => {
    loadComponentCallback();
  }, [loadComponentCallback]);

  useEffect(() => {
    setFilterByAssigned(defaultFilterDropdownOption);
    setFilterByCreator(defaultFilterDropdownOption);
    setFilterByPackages(defaultFilterDropdownOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    const filteredData = quizzes
      .filter((quiz) =>
        filterByPackages?.value
          ? quiz.questionPackageInfo.id === filterByPackages.value
          : quiz
      )
      .filter((quiz) =>
        filterByCreator?.value
          ? quiz.creatorUserInfo.id === filterByCreator.value
          : quiz
      )
      .filter((quiz) =>
        filterByAssigned?.value
          ? quiz.assignedUserInfo.id === filterByAssigned.value
          : quiz
      );
    setFilteredQuizzes(filteredData);
  }, [
    filterByAssigned?.value,
    filterByCreator?.value,
    filterByPackages,
    quizzes,
  ]);

  const handleSelectQuiz = (e: IQuizResponseModel) => {
    const isSelected = selectedQuizzes.find((quiz) => quiz === e);
    const coPackage = selectedQuizzes.find(
      (quiz) => quiz.questionPackageInfo.id === e.questionPackageInfo.id
    );
    if (isSelected) {
      const newData = selectedQuizzes.filter((quiz) => quiz !== e);
      setSelectedQuizzes(newData);
    } else {
      if (coPackage || !selectedQuizzes.length) {
        setSelectedQuizzes((prevState) => [...prevState, e]);
      } else {
        toast.error(t("dashboard.error.compareQuiz"));
      }
    }
  };

  return (
    <div className="dashboard-container">
      <div className="actions-container">
        <Button
          onClick={() => setCompareModalVisible(true)}
          disabled={selectedQuizzes.length < 1}
        >
          {t("dashboard.filterMenu.compare")}
        </Button>
        <Dropdown
          className="filter-dropdown"
          options={[defaultFilterDropdownOption, ...questionsOptions]}
          onSelect={setFilterByPackages}
          value={filterByPackages}
          label={t("dashboard.filterMenu.filterByPackage")}
          key="packages"
        />
        <Dropdown
          className="filter-dropdown"
          options={[defaultFilterDropdownOption, ...assignedUsersOptions]}
          onSelect={setFilterByCreator}
          value={filterByCreator}
          label={t("dashboard.filterMenu.filterByCreator")}
          key="creator"
        />
        <Dropdown
          className="filter-dropdown"
          options={[defaultFilterDropdownOption, ...assignedUsersOptions]}
          onSelect={setFilterByAssigned}
          value={filterByAssigned}
          label={t("dashboard.filterMenu.filterByAssigned")}
          key="assigned"
        />
      </div>
      <div className="quizzes-content">
        {!!filteredQuizzes.length &&
          filteredQuizzes.map((quiz, index) => {
            return (
              <QuizBox
                handleSelectCart={handleSelectQuiz}
                key={index}
                quiz={quiz}
                checked={!!selectedQuizzes.find((e) => e === quiz)}
              />
            );
          })}
      </div>

      {compareModalVisible && (
        <CompareModal
          selectedQuizzes={selectedQuizzes}
          closeModal={() => setCompareModalVisible(false)}
          visible={compareModalVisible}
        />
      )}
    </div>
  );
};

export default memo(BaseDashboard);
