import { FC, ReactNode } from "react";
import "./style.scss";
import PageContent from "./PageContent";
import NavBar from "../NavBar";
import EmailVerification from "../EmailVerification";
import LayoutSider from "./Sider";

interface ILayoutProps {
  children: ReactNode;
}

const Layout: FC<ILayoutProps> = ({ children }) => {
  return (
    <div className="layout-container">
      <NavBar />
      <LayoutSider>
        <PageContent>
          <EmailVerification>{children}</EmailVerification>
        </PageContent>
      </LayoutSider>
    </div>
  );
};

export default Layout;
