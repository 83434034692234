import React, {
  useRef,
  useEffect,
  ChangeEventHandler,
  TextareaHTMLAttributes,
  ReactNode,
} from "react";
import "./style.scss";

interface AutoSizeTextAreaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  className?: string;
  value: string;
  label?: ReactNode;
  error?: boolean;
  errorMessage?: string;
  autoSize?: boolean;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
}

const AutoSizeTextArea: React.FC<AutoSizeTextAreaProps> = ({
  value,
  onChange,
  className,
  label,
  error,
  errorMessage,
  autoSize = true,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  let defaultClassName = "auto-size-textarea";

  useEffect(() => {
    if (autoSize) {
      resizeTextArea();
    }
  }, [value, autoSize]);

  const resizeTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${
        textareaRef.current.scrollHeight - 32
      }px`;
    }
  };

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    onChange(e);
  };

  return (
    <fieldset
      className={`${defaultClassName} ${
        error ? `${defaultClassName}-error` : ""
      } ${className}
      
    `}
    >
      <legend className="legend">
        <label>{label}</label>
      </legend>
      <textarea
        ref={textareaRef}
        value={value}
        onChange={handleChange}
        {...rest}
      />
      {errorMessage && error && (
        <label className="error-message">{errorMessage}</label>
      )}
    </fieldset>
  );
};

export default AutoSizeTextArea;
