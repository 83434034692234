import React, { memo, useEffect, useMemo, useState } from "react";
import { useLoading } from "../../context/store/loadingContext";
import CreateModal from "./CreateModal";
import Button from "../../components/Button";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import PDFPackageService from "../../services/ui-services/documentPackageService";
import CollectionAWSService from "../../services/ui-services/collectionService/index";
import "./style.scss";
import PDFPackageBox from "../../components/PDFPackageBox";
import { generateUniqueId } from "../../utils/helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  IDocumentModel,
  IPDFPackagePayloadModel,
  IPDFPackageResponseModel,
  IUpdatePDFPackageModel,
} from "../../services/ui-services/documentPackageService/types";
import AddDocumentModal from "./AddDocumentModal";
import SummaryModal from "./DocumentSummaryModal";
import { StatusTypes } from "../../models/Status";
import { Tooltip } from "react-tooltip";
import EditCollectionContextModalVisible from "./EditCollectionContextModalVisible";

const BaseDocumentCollections: React.FC = () => {
  const collectionAWSService = useMemo(() => {
    return new CollectionAWSService();
  }, [])

  const pdfPackageService = useMemo(() => {
    return new PDFPackageService();
  }, [])

  const { t } = useTranslation();
  const { onCompleted, onLoading } = useLoading();
  const [packages, setPackages] = useState<IPDFPackageResponseModel[]>([]);
  const [createModalVisible, setCreateModalVisible] = useState<boolean>(false);
  const [addDocumentModalVisible, setAddDocumentModalVisible] =
    useState<boolean>(false);
  const [
    editCollectionContextModalVisible,
    setEditCollectionContextModalVisible,
  ] = useState<boolean>(false);
  const [documentSummaryModalVisible, setDocumentSummaryModalVisible] =
    useState<boolean>(false);
  const [documentSummaryInfo, setDocumentSummaryInfo] = useState<
    | {
        content: string;
        document: IDocumentModel;
      }
    | undefined
  >(undefined);
  const [selectPackage, setSelectedPackage] = useState<
    IPDFPackageResponseModel | undefined
  >(undefined);

  const getAllPDFPackages = async () => {
    try {
      const pdfPackages = await pdfPackageService.getAllPackages();
      setPackages(pdfPackages || []);
    } catch (error) {
      toast.error(t("error.tryAgain"));
    }
  };

  useEffect(() => {
    const inProgressPackages = packages.filter((e) => {
      return e.content.PDFS.some(
        (document) =>
          document?.docStatus === StatusTypes.InProgress ||
          document?.status === StatusTypes.InProgress
      );
    });

    const listenToData = async () => {
      await pdfPackageService.listenToPackagesChanges(async (packages) => {
        await getAllPDFPackages();
      });
    };
    if (inProgressPackages.length > 0) {
      listenToData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packages]);

  useEffect(() => {
    const loadComponent = async () => {
      onLoading();
      await getAllPDFPackages();
      onCompleted();
    };
    loadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deletePDFPackage = async (
    selectedPackage: IPDFPackageResponseModel
  ) => {
    try {
      onLoading();
      await collectionAWSService.deleteCollection(
        selectedPackage.content.collectionId
      ).then((response) => {
        if (!response.result_status) {
          toast.error(response.error_message);
        }
      });
      await pdfPackageService.deleteCollection(selectedPackage.id);
      await getAllPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  const handleSummarizeDocument = async (
    packageData: IPDFPackageResponseModel,
    selectedDocument: IDocumentModel
  ) => {
    try {
      onLoading();

      if (selectedDocument.status === StatusTypes.Done) {
        if (selectedDocument.summary) {
          setDocumentSummaryInfo({
            content: selectedDocument.summary,
            document: selectedDocument,
          });
          setDocumentSummaryModalVisible(true);
        }
      } else {
        await collectionAWSService.summarizeDocument(
          packageData.id,
          packageData.content.collectionId,
          selectedDocument.url!,
          selectedDocument.docId
        ).then((response) => {
          if (!response.result_status) {
            toast.error(response.error_message);
          }
        });
        await pdfPackageService.listenToPackageDocumentSummaryStatusChanges(
          packageData.id,
          selectedDocument.docId,
          () => {
            getAllPDFPackages();
          }
        );
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };
  const deleteDocumentFromPackage = async (
    selectedPackage: IPDFPackageResponseModel,
    document: IDocumentModel
  ) => {
    try {
      onLoading();
      await collectionAWSService.deleteDocumentFromCollection(
        selectedPackage.content.collectionId,
        document.url!
      ).then((response) => {
        if (!response.result_status) {
          toast.error(response.error_message);
        }
      });
      await pdfPackageService.deleteDocumentFromCollection(
        selectedPackage.id,
        document.docId
      );
      await getAllPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  const addPDFToPackage = async (newPDF: File) => {
    try {
      onLoading();
      const docId = generateUniqueId();
      const payload: IDocumentModel = {
        docId: docId,
        name: newPDF.name,
      };
      await pdfPackageService.addPDF(selectPackage!.id, payload);

      await collectionAWSService.createCollectionForUploadFile(
        selectPackage!.content.collectionId,
        newPDF,
        docId,
        selectPackage!.id
      ).then((response) => {
        if (!response.result_status) {
          toast.error(response.error_message);
        }
      });

      await pdfPackageService.listenToPackageDocumentStatusChanges(
        selectPackage!.id,
        docId,
        () => {
          getAllPDFPackages();
        }
      );

      await getAllPDFPackages();
      setAddDocumentModalVisible(false);
      setSelectedPackage(undefined);
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };
  const addUrlToPackage = async (url: string) => {
    try {
      onLoading();
      const docId = generateUniqueId();
      const payload: IDocumentModel = {
        docId: docId,
        name: url,
      };

      await pdfPackageService.addPDF(selectPackage!.id, payload);

      await collectionAWSService.createCollectionForFromUrl(
        selectPackage!.content.collectionId,
        url,
        docId,
        selectPackage!.id
      ).then((response) => {
        if (!response.result_status) {
          toast.error(response.error_message);
        }
      });

      await pdfPackageService.listenToPackageDocumentStatusChanges(
        selectPackage!.id,
        docId,
        () => {
          getAllPDFPackages();
        }
      );

      setAddDocumentModalVisible(false);
      setSelectedPackage(undefined);
      await getAllPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  const createPackage = async (packageName: string, context: string) => {
    try {
      onLoading();

      const packagePayload: IPDFPackagePayloadModel = {
        collectionId: generateUniqueId(),
        packageName: packageName,
        PDFS: [],
        context: context,
      };

      await pdfPackageService.createPackage(packagePayload);

      setCreateModalVisible(false);
      await getAllPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  const handleChangeCollectionContext = async (context: string) => {
    try {
      onLoading();

      const packagePayload: IUpdatePDFPackageModel = {
        ...selectPackage?.content!,
        id: selectPackage?.id!,
        context: context,
      };

      await pdfPackageService.update(packagePayload);
      await getAllPDFPackages();
      setEditCollectionContextModalVisible(false);
      await getAllPDFPackages();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
  };

  return (
    <div className="pdf-package-container">
      <div className="add-package-container">
        <Tooltip id="add" />
        <Button
          data-tooltip-id="add"
          data-tooltip-content={t("page.addNew")}
          onClick={() => setCreateModalVisible(!createModalVisible)}
          icon={faPlus}
        />
      </div>
      <div className="pdf-packages-boxes">
        {!!packages.length &&
          packages.map((pdfPackage, index) => {
            return (
              <PDFPackageBox
                key={index}
                packageInfo={pdfPackage}
                onAddDocument={(selectedData) => {
                  setSelectedPackage(selectedData);
                  setAddDocumentModalVisible(true);
                }}
                updateCollectionContext={(selectedData) => {
                  setSelectedPackage(selectedData);
                  setEditCollectionContextModalVisible(true);
                }}
                onClickSummary={handleSummarizeDocument}
                onDeleteDocumentFromCollection={deleteDocumentFromPackage}
                onDeletePDFPackage={deletePDFPackage}
              />
            );
          })}
      </div>
      {createModalVisible && (
        <CreateModal
          handleCreatePackage={createPackage}
          visible={createModalVisible}
          closeModal={() => setCreateModalVisible(false)}
        />
      )}
      {addDocumentModalVisible && (
        <AddDocumentModal
          handleAddPDF={addPDFToPackage}
          handleAddUrl={addUrlToPackage}
          visible={addDocumentModalVisible}
          closeModal={() => setAddDocumentModalVisible(false)}
        />
      )}
      {editCollectionContextModalVisible && (
        <EditCollectionContextModalVisible
          contextValue={selectPackage?.content.context || ""}
          onChangeContext={(context) => handleChangeCollectionContext(context)}
          visible={editCollectionContextModalVisible}
          closeModal={() => setEditCollectionContextModalVisible(false)}
        />
      )}
      {documentSummaryModalVisible && documentSummaryInfo && (
        <SummaryModal
          content={documentSummaryInfo.content}
          documentName={documentSummaryInfo.document.name}
          visible={documentSummaryModalVisible}
          closeModal={() => setDocumentSummaryModalVisible(false)}
        />
      )}
    </div>
  );
};

export default memo(BaseDocumentCollections);
