import { Timestamp } from "firebase/firestore";
import { IJsonModel } from "flexlayout-react";


export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePassword = (password: string, confirmPassword?: string): boolean => {
  const passwordRegex = /^.{6,}$/;
  const validConfirm = (confirmPassword || confirmPassword === "") ? confirmPassword === password : true
  return passwordRegex.test(password) && validConfirm;

};

export function generateUniqueId<T = string>(prefix: string = 'id'): T {
  const timestamp = Date.now().toString(16);
  const random = Math.random().toString(36).substr(2, 5);
  return `${prefix}-${timestamp}-${random}` as T;
}

export function isWikiUrlValid(urlToCheck: string, baseUrl: string): boolean {
  try {

    const baseUrlUrlSliceStart = baseUrl.slice(0, 8);
    const baseUrlUrlSliceEnd = baseUrl.slice(11, baseUrl.length);
    const baseUrlPrefix = baseUrlUrlSliceStart + baseUrlUrlSliceEnd
    const baseCombinedUrl = new URL(baseUrlPrefix);

    const validUrlSliceStart = urlToCheck.slice(0, 8);
    const validUrlSliceEnd = urlToCheck.slice(11, urlToCheck.length);
    const validUrlPrefix = validUrlSliceStart + validUrlSliceEnd
    const validCombinedUrl = new URL(validUrlPrefix);

    if (validCombinedUrl.href.startsWith(baseCombinedUrl.href)) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export function formatPrice(number: number): string {
  const formattedPrice = number.toFixed(2);
  const currencySymbol = '$';
  return `${currencySymbol}${formattedPrice}`;
}

export const getFirstLetterOfDisplayname = (name: string): string => name.substring(0, 1).toUpperCase();

export function timestampToDate(timestamp:Timestamp) {
  if (!timestamp || !timestamp.seconds) {
    return null;
  }
  
  const milliseconds = timestamp.seconds * 1000 + (timestamp.nanoseconds / 1000000);
  return new Date(milliseconds);
}

export function isJobSpiderUrlValid(urlToCheck: string, baseUrl: string): boolean {
  try {
    const validCombinedUrl = new URL(urlToCheck);
    const baseCombinedUrl = new URL(baseUrl);


    if (
      validCombinedUrl.href.startsWith(baseCombinedUrl.href)
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}


export function checkFileSize(file: File, maxFileSizeMB: number): boolean {
  const maxFileSizeBytes = maxFileSizeMB * 1024 * 1024;

  if (file.size > maxFileSizeBytes) {
    return false;
  }

  return true;
}

export const mapFlexLayoutItems = (model: IJsonModel) => {
  const layoutChildren = model?.layout?.children;

  if (!layoutChildren || layoutChildren.length === 0) {
    return [];
  }

  const mapItems = (children: any[]): {id: string, name: string}[] => {
    // eslint-disable-next-line array-callback-return
    return children.map((child) => {
      if (child.type === "tabset") {
        return child.children.map((tab: any) => {
          return {
            id: tab.id,
            name: tab.name,
            selected: child.selected,
          };
        });
      } else if (child.children && child.children.length > 0) {
        return mapItems(child.children);
      }
    });
  };

  return mapItems(layoutChildren).flat(Infinity);
};