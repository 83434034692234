import React, { useEffect, useMemo, useState } from "react";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faMicrophone,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { Button, Input } from "antd";

const { TextArea } = Input;

const queryPrompt: string[] = [
  "100 hesabından 7 bin tl ve üzeri çıkışları (alacak kayıtlarını) listele.",
  "100 hesabından bin tl ve üzeri çıkışları (alacak kayıtlarını) listele.",
  "770 numaralı hesaba 1500 tl ve üzeri girişleri (borç kayıtlarını) listele.",
  "100 hesabından 1 bin tl ve üzeri çıkışları (alacak kayıtlarını) listele.",
  "331 numaralı hesaba 100 bin tl ve üzeri girişleri (borç kayıtlarını) listele.",
  "190 numaralı hesaba 2 bin tl ve üzeri girişleri (borç kayıtlarını) listele.",
  "190 hesabına yapılan kayıtların sayısı nedir?",
  "190 hesabına yapılan kayıtlar nelerdir?",
  "100 hesabına yapılan kayıtların sayısı nedir?",
  "100 hesabına yapılan Borç kayıtlarının ve Alacak kayıtlarının aritmetik ortalaması kaçtır?",
  "190 numaralı hesaba yapılan Borç kayıtlarının ve Alacak kayıtlarının ortalaması kaçtır?",
];

const queryPromptTwo: string[] = [
  "Açılış fişindeki 100 numaralı hesabın borç toplamı nedir?",
  "100 numaralı hesabın aylık bakiye raporunu hazırla.",
  "Tüm hesapların aylık bakiye raporunu hazırla.",
  "3 aylık mizan raporunu hazırla.",
  "3-6-9 aylık mizanları oluştur.",
];

const queryPromptThree: string[] = [
  "BANKALAR hesabından SATICILAR hesabına yapılan ödeme var mı?",
  "BANKALAR hesabından SATICILAR hesabına yapılan 20 bin TL ödeme var mı?",
  "BANKALAR hesabından SATICILAR hesabına yapılan 396477 TL ödeme var mı?",
  "BANKALAR hesabından SATICILAR hesabına 100 bin TL den fazla bir miktarda yapılan ödeme var mı?",
];

const queryPromptFour: string[] = [
  "2023 yılı ilk çeyrekteki Ortalama Stokta Bekleme Süresini hesapla.",
  "2023 yılı Stok Devir Hızını hesapla.",
  "Sermaye Çarpanını hesapla.",
  "2023 yılı Sermaye Çarpanını hesapla.",
  "2023-04-01 ile 2023-07-01 arasındaki Asit-Test Oranını hesapla.",
  "01.04.2023 ile 01.07.2023 arasındaki Net Çalışma Sermayesi nedir?",
];

const queryPromptForFund: string[] = [
  "Yıl başından bu yana, yüzde on üstünde getiri sağlamış, 5 ve altında riske sahip fonlardan son 1 yıl getirisi en fazla olan hangisidir?",
  "Son 3 senede , enag enflasyonunu geçmiş olan hisse senedi fonlarını listele (enag enflasyonu = %45).",
  "Para piyasası fonlarından son bir aylık getiride ilk yirmide olanların, mevduat, finansman bonosu ve ters repo içeriklerinin dağılımını bar grafiği olarak çiz.",
  "Nurol Para piyasası fonuna ait menkul değerler nelerdir?",
  "Ata portföyün yönettiği fonlar içinde, en yüksek 10 sharpe değerine sahip fonlardan, en az beş tane menkul değer içerenleri listele.",
  "Yerli hisse senedi fonlarının sharpe değerlerini bir çan eğrisi şeklinde çizebilir misin. Sharpe degerinin cozunurlugu 0.5 olsun",
  "Yerli hisse senedi fonlarının sharpe değerlerini bir çan eğrisi şeklinde çizebilir misin. Sharpe degerinin cozunurlugu 0.5 olsun. Histogram frekansını görmek istiyorum",
];

const queryPromptForDataAnalysis: string[] = [
  "What is the median age of the passengers?",
  "What is the shape of the dataframe?",
  "What are the feature names of the dataframe?",
  "How many passengers did survive?",
  "Plot a chart for the number of survived passengers in Pclass.",
  "Create a pie chart to represent the distribution of passengers by gender.",
  "Plot the correlation heatmap of features.",
  "Plot the age distribution of the number of passengers with 10 bins."
];

const queryPromptForWorkCube: string[] = [
  "Son üç ayda satışlarını artıran satışçılar kimlerdir?",
  "En fazla satış yapılan 10 müşteriyi grafikte göster.",
  "En fazla satış yapan kategori hangisidir?",
  "Satışların illere göre dağılımını grafikte göster."
];

const queryPromptForWorkCubeInEng: string[] = [
  "Which salespeople have increased their sales in the last three months?",
  "Show the top 10 customers with the highest sales on a chart.",
  "Which is the category with the highest sales?",
  "Show the distribution of sales by cities on a chart."
];

const queryPromptForHospital: string[] = [
  "Geliş tipi klinik olan hastaların hekimlere göre toplam ciro dağılımına baktığımızda cirosu en yüksek hekimin adı nedir?",
  "Geliş tipi klinik olan hastaların hekimlere göre toplam ciro dağılımına baktığımızda cirosu en yüksek 5 hekimin adı, hasta sayısı ve ciro tutarını tablo halinde göster.",
  "Geliş tipi poliklinik olan hastaların, branş ve doktor dağılımını gösteren pivot tablonun grafiğini çiz.",
];

const queryPromptForHospitalTwo: string[] = [
  "Ağustos ayında ilaçların Stok kategorisine göre dağılımı nedir?",
  "Stok çıkışlarının kaynak depoya göre sıralaması ve oranları nedir?",
  "Stok hareketlerine göre KDV’siz son fiyat ve alış fiyatına göre kar durumu ve bunların stok tipine göre dağılım oranı nedir?",
];

const queryPromptForHospitalInEng: string[] = [
  "When we look at the total turnover distribution of patients with clinic type of admission visits according to doctors, what is the name of the doctor with the highest turnover?",
  "When we look at the total turnover distribution of patients with clinic type of admission visits according to doctors, show the names of the top 5 doctors with the highest turnover, number of patients, and turnover amounts in a table format.",
  "Draw the graph of a pivot table showing the distribution of branch and doctor for patients with outpatient clinic visits.",
];

const queryPromptForHospitalInEngTwo: string[] = [
  "What is the distribution of medications according to the Stock category in August?",
  "What is the ranking of stock outputs according to source depot and what are their percentages?",
  "According to stock movements, what is the profit situation and distribution ratio according to stock type based on final price without VAT and purchase price?",
];

export enum TextQueryType {
  Yevmiye = "yevmiye",
  Fund = "fund",
  Hospital = "hospital",
  HospitalInEng = "hospital-in-eng",
  WorkCube = "workcube",
  WorkCubeInEng = "workcube-in-eng",
  DataAnalysis = "data-analysis"
}

interface IProps {
  isLoading: boolean;
  handleTextQuery: (val: string, successCallback: VoidFunction) => void;
  queryType?: TextQueryType;
}

const TextQueryForm: React.FC<IProps> = ({
  handleTextQuery,
  isLoading,
  queryType = TextQueryType.Yevmiye,
}) => {
  const [textQuery, setTextQuery] = useState("");
  const [listening, setListening] = useState(false);
  const { t } = useTranslation();

  const speechRecognition = useMemo(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    if (!SpeechRecognition) return null;
    const instance = new SpeechRecognition();
    instance.continuous = true;
    instance.lang = "tr";
    return instance;
  }, []);

  const onClickMicrophone = () => {
    if (listening) {
      speechRecognition?.stop();
      setListening(false);
    } else {
      speechRecognition?.start();
      setListening(true);
    }
  };

  useEffect(() => {
    if (speechRecognition) {
      speechRecognition.onresult = (event: any) => {
        const current = event.resultIndex;
        const { transcript } = event.results[current][0];
        setTextQuery((prevState) => `${prevState} ${transcript}`);
      };
    }
  }, [speechRecognition]);

  const onSubmit = () => {
    if (listening) {
      setListening(false);
      speechRecognition?.stop();
    }

    handleTextQuery(textQuery, () => {
      setTextQuery("");
    });
  };

  useEffect(() => {
    return () => {
      speechRecognition?.stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="form-container" style={{ pointerEvents: isLoading ? 'none' : undefined }}>
      <div className="form-line">
        <div className="prompt-container">
          {queryType === TextQueryType.Fund ? (
            <ul>
              {queryPromptForFund.map((prompt) => {
                return (
                  <li key={prompt} onClick={() => setTextQuery(prompt)}>
                    {prompt}
                  </li>
                );
              })}
            </ul>
          ) : queryType === TextQueryType.DataAnalysis ? (
            <ul>
              {queryPromptForDataAnalysis.map((prompt) => {
                return (
                  <li key={prompt} onClick={() => setTextQuery(prompt)}>
                    {prompt}
                  </li>
                );
              })}
            </ul>
          ) :
          queryType === TextQueryType.WorkCube ? (
            <ul>
              {queryPromptForWorkCube.map((prompt) => {
                return (
                  <li key={prompt} onClick={() => setTextQuery(prompt)}>
                    {prompt}
                  </li>
                );
              })}
            </ul>
          ) :
          queryType === TextQueryType.WorkCubeInEng ? (
            <ul>
              {queryPromptForWorkCubeInEng.map((prompt) => {
                return (
                  <li key={prompt} onClick={() => setTextQuery(prompt)}>
                    {prompt}
                  </li>
                );
              })}
            </ul>
          ) : queryType === TextQueryType.Hospital ? (
            <>
              <ul>
                {queryPromptForHospital.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
              <br />
              <hr />
              <br />
              <ul>
                {queryPromptForHospitalTwo.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
            </>
          ) : queryType === TextQueryType.HospitalInEng ? (
            <>
              <ul>
                {queryPromptForHospitalInEng.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
              <br />
              <hr />
              <br />
              <ul>
                {queryPromptForHospitalInEngTwo.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
            </>
          ) : (
            <>
              <ul>
                {queryPrompt.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
              <br />
              <hr />
              <br />
              <ul>
                {queryPromptTwo.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
              <br />
              <hr />
              <br />
              <ul>
                {queryPromptThree.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
              <br />
              <hr />
              <br />
              <ul>
                {queryPromptFour.map((prompt) => {
                  return (
                    <li key={prompt} onClick={() => setTextQuery(prompt)}>
                      {prompt}
                    </li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
        <div>
          <div className="label-container">
            <Tooltip id="topN" content={t("dataAnalysis.query.label")} />
            <FontAwesomeIcon data-tooltip-id="topN" icon={faInfoCircle} />
            {speechRecognition && (
              <FontAwesomeIcon
                icon={faMicrophone}
                className={`microphone ${listening && "active-microphone"}`}
                onClick={onClickMicrophone}
              />
            )}
          </div>
          <TextArea
            className="text-input"
            value={textQuery}
            onChange={(e) => setTextQuery(e.target.value)}
            autoSize={false}
            rows={5}
            style={{ resize: 'none', marginTop: '5px' }}
            placeholder="How can I help you?"
          />
        </div>
      </div>
      <div className="send-button">
        <Button
          disabled={textQuery.length < 1}
          onClick={onSubmit}
          className="save-button"
          icon={<FontAwesomeIcon icon={faPaperPlane} />}
          size="large"
        />
      </div>
    </div>
  );
};

export default TextQueryForm;
