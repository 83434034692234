import React, { FC, memo, useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
import AutoSizeTextArea from "../TextArea";
import { faGear, faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import MessageBox from "../MessageBox";
import ChatService from "../../services/ui-services/chatService";
import ConfigrationModal from "./ConfigrationModal";
import Button from "../Button";
import { toast } from "react-toastify";
import { generateUniqueId } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import {
  IAskConfigration,
  IMessageModel,
} from "../../services/ui-services/chatService/types";
import Loader from "../Loading/Loader";
import { IActiveReferenceModel, IConfigurationTypes } from "./types";
import { IPDFPackageResponseModel } from "../../services/ui-services/documentPackageService/types";

interface IProps {
  collectionInfo: IPDFPackageResponseModel;
  activeReference?: IActiveReferenceModel;
  handleReference: (data: IActiveReferenceModel) => void;
}

const Chat: FC<IProps> = ({
  collectionInfo,
  activeReference,
  handleReference,
}) => {
  const chatService = useMemo(() => {
    return new ChatService()
  }, [])

  const { t } = useTranslation();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<IMessageModel[]>([]);
  const [newMessage, setNewMessage] = useState<string>("");
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [configType, setConfigType] = useState<IConfigurationTypes>(
    IConfigurationTypes.Vectorization
  );
  const [askConfigration, setAskConfigration] = useState<IAskConfigration>({
    embed_model_number: 4,
    top_k: 10,
    top_n: 5,
    engine_name: "gpt-3.5-turbo-16k",
    llm_temp: 0,
    reduction_type: "stuff",
    context: collectionInfo.content.context,
  });

  const handleMessageSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const newMessageId: string = generateUniqueId();
    const message: IMessageModel = {
      id: newMessageId,
      answer: newMessage,
      is_question: true,
    };
    try {
      setIsLoading(true);
      setMessages((prevState) => [...prevState, message]);
      setNewMessage("");
      if (configType === IConfigurationTypes.Vectorization) {
        await chatService.askCollectionVectorization(
          newMessage,
          askConfigration,
          collectionInfo.content.collectionId
        ).then(async (result) => {
          if (!result.result_status) {
            toast.error(result.error_message);
          } else {
            const response = result.response;
            if (response?.answer) {
              setMessages((prevState) => [
                ...prevState,
                { ...response, id: response.answer },
              ]);
            }

            if (response?.sources?.length) {
              await handleReference({
                messageId: response.answer,
                activeIndex: 0,
                ...response.sources[0],
              });
            }
          }
        });

        // .then(async (response) => {
        //   if (!response.result_status) {
        //     toast.error(response.error_message);
        //   } else {

        //   }
        // });
      } else {
        await chatService.askCollectionWindowSize(
          newMessage,
          askConfigration.context,
          collectionInfo.content.collectionId
        ).then(async (result) => {
          if (!result.result_status) {
            toast.error(result.error_message);
          } else {
            const response = result.response;
            if (response?.answer) {
              setMessages((prevState) => [
                ...prevState,
                { ...response, id: response.answer },
              ]);
            }

            if (response?.sources?.length) {
              await handleReference({
                messageId: response.answer,
                activeIndex: 0,
                ...response.sources[0],
              });
            }
          }
        });
      }
    } catch (error) {
      toast.error(t("error.tryAgain"));
      const updatedMessages = messages.filter((item) => item.id !== message.id);
      setMessages(updatedMessages);
      setNewMessage(message.answer);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Son mesaja scroll yap
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;
      const lastMessage = chatContainer.lastElementChild as HTMLElement;
      if (lastMessage) {
        lastMessage.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    }
  }, [messages]);

  const onClickBox = (data: IMessageModel, index: number) => {
    if (activeReference?.messageId === data.id && data.sources) {
      handleReference({
        messageId: data.id,
        activeIndex: index,
        ...data.sources[index],
      });
    } else if (data.sources) {
      handleReference({
        messageId: data.id,
        activeIndex: 0,
        ...data.sources[0],
      });
    }
  };

  return (
    <div className="chat-container">
      <div className="collection-info">
        {collectionInfo.content.packageName}
      </div>
      <div className="messages" ref={chatContainerRef}>
        {messages.map((message, index) => (
          <div key={index} className="message">
            <MessageBox
              onClickBox={onClickBox}
              data={message}
              activeReference={activeReference}
            />
          </div>
        ))}
      </div>
      {isLoading && (
        <div className="loading-container">
          <Loader />
        </div>
      )}
      <div className="send-message-wrapper">
        <form className="send-message" onSubmit={handleMessageSubmit}>
          <AutoSizeTextArea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            className="text-input"
          />
          <Button
            className="send-button"
            disabled={isLoading || !newMessage.length}
            type="submit"
            icon={faPaperPlane}
          />

          <Button
            type="button"
            className="send-button"
            onClick={() => setIsModalVisible(true)}
            disabled={isLoading}
            icon={faGear}
          />
        </form>
      </div>

      <ConfigrationModal
        visible={isModalVisible}
        closeModal={() => setIsModalVisible(!isModalVisible)}
        configType={configType}
        onChangeConfigType={setConfigType}
        configInfo={askConfigration}
        onChangeConfig={setAskConfigration}
      />
    </div>
  );
};

export default memo(Chat);
