import { Modal, Rate } from "antd";
import React, { FC, useEffect, useState } from "react";
import AutoSizeTextArea from "../../../../components/TextArea";
import { ChatCommentModel } from "..";

type Props = {
  onClose: () => void;
  existComment?: ChatCommentModel;
  onSave: (
    args: Omit<ChatCommentModel, "index">,
    successCallback: VoidFunction,
    finallyFunc: VoidFunction
  ) => void;
};

const ChatCommentModal: FC<Props> = ({ onClose, existComment, onSave }) => {
  const [rating, setRating] = useState<number | undefined>();
  const [comment, setComment] = useState<string>("");
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (existComment) {
      setRating(existComment.rating);
      setComment(existComment.comment);
    }
  }, [existComment]);

  const isValid = () => comment && rating && !sending;

  const onClickSave = async () => {
    setSending(true);
    onSave(
      { rating: rating!, comment },
      () => {
        onClose();
      },
      () => {
        setSending(false);
      }
    );
  };

  return (
    <Modal
      closable={false}
      open
      onCancel={onClose}
      title="Please Rate Your Experience"
      okText="Send"
      okButtonProps={{
        onClick: onClickSave,
        disabled: !isValid(),
        loading: sending,
      }}
      cancelButtonProps={{
        disabled: sending,
      }}
    >
      <div className="modal_fields">
        <Rate value={rating} onChange={(value) => setRating(value)} />
      </div>
      <div className="modal_fields">
        <AutoSizeTextArea
          className="text-input"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          autoSize={false}
          rows={5}
        />
      </div>
    </Modal>
  );
};

export default ChatCommentModal;
