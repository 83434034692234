import React, { InputHTMLAttributes } from "react";
import "./style.scss";

interface IProps {
  id?: string;
  changed?: InputHTMLAttributes<HTMLInputElement>["onChange"];
  isSelected?: boolean;
  label?: string;
  value?: InputHTMLAttributes<HTMLInputElement>["value"];
}

const RadioButton: React.FC<IProps> = ({
  changed,
  id,
  isSelected,
  label,
  value,
}) => {
  return (
    <div className="radio-button">
      <input
        id={id}
        onChange={changed}
        value={value}
        type="radio"
        checked={isSelected}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default RadioButton;
