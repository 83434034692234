import { Api } from "../../../api";
import { ICreateCollectionForFromUrlResponse, ICreateCollectionForUploadFileResponseModel, IDeleteDocumentResponse, IFundQueryResponseModel, ISummarizeDocumentResponse, IQueryResponseModel } from "./types";

class CollectionAWSService {
    async createCollectionForUploadFile(
        collectionId: string,
        uploadedFile: File,
        docId: string,
        firebaseId: string
    ): Promise<ICreateCollectionForUploadFileResponseModel> {
        try {
            const queryParams = new URLSearchParams({ collection_id: collectionId, firebase_id: firebaseId, doc_id: docId });
            const formData = new FormData();
            formData.append("uploaded_file", uploadedFile);

            const response = await Api.post(
                `/process/upload_file_process_send_s3?${queryParams}`,
                formData,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async createCollectionForFromUrl(
        collectionId: string,
        url: string,
        docId: string,
        firebaseId: string
    ): Promise<ICreateCollectionForFromUrlResponse> {
        try {

            const payload = {
                collection_id: collectionId,
                url: url,
                doc_id: docId,
                firebase_id: firebaseId
            }

            const response = await Api.post(
                `/process/produce_pdf_from_url_to_s3`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }



    async deleteCollection(collectionId: string): Promise<IDeleteDocumentResponse> {
        try {
            const payload = {
                collection_id: collectionId
            }
            const response = await Api.post(
                `/del_s3_collection`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async deleteDocumentFromCollection(collectionId: string, documentId: string): Promise<IDeleteDocumentResponse> {
        try {
            const payload = {
                collection_id: collectionId,
                file_name_with_extension: documentId,
            }
            const response = await Api.post(
                `/del_doc_with_index_from_s3_collection`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async summarizeDocument(
        firebasePackageId: string,
        collectionId: string,
        docUrl: string,
        docId: string
    ): Promise<ISummarizeDocumentResponse> {
        try {
            const payload = {
                firebase_id: firebasePackageId,
                collection_id: collectionId,
                file_name_with_extension: docUrl,
                doc_id: docId,
                llm: "openai"
            }

            const response = await Api.post(
                `/process/doc_summarizer`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async accountingDataInsight(
        query: string, email: string
    ): Promise<IQueryResponseModel> {
        try {
            const payload = {
                query: query,
                llm: "openai",
                email
            }

            const response = await Api.post(
                `/accounting_data_insight`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async tabularDataInsight(
        query: string, email: string, isInteractive: boolean, csv_file_name: string
    ): Promise<IFundQueryResponseModel> {
        try {
            const payload = {
                query: query,
                llm: "deepseek-coder",
                email,
                plot_type: isInteractive ? "plotly" : undefined,
                csv_file_name
            }

            const response = await Api.post(
                `/tabular_data_insight_with_python`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

    async accountingDataInsightWithSQL(
        query: string, email: string
    ): Promise<IQueryResponseModel> {
        try {
            const payload = {
                query: query,
                llm: "openai",
                email
            }

            const response = await Api.post(
                `/accounting_data_insight_with_sql`,
                payload,
                {}
            );
            return response;
        } catch (error) {
            console.error(error);
            throw error
        }
    }

}

export default CollectionAWSService;
