import React, { useEffect, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";

export interface ITableColumn {
  label: string;
  key: string;
  width: string;
  sortable?: boolean;
  render?: (data: any) => React.ReactNode;
  sortType?: "string" | "date" | "number";
}

interface IProps {
  columns: ITableColumn[];
  data: any[];
  expandContent?: (rowData: any) => any;
}

const Table: React.FC<IProps> = ({ columns, data, expandContent }) => {
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [sortColumn, setSortColumn] = useState("");
  const [sortAscending, setSortAscending] = useState(true);
  const [sortedData, setSortedData] = useState([...data]);

  const toggleRowExpansion = (rowIndex: number) => {
    if (expandedRows.includes(rowIndex)) {
      setExpandedRows(expandedRows.filter((index) => index !== rowIndex));
    } else {
      setExpandedRows([...expandedRows, rowIndex]);
    }
  };

  const handleSort = (
    columnKey: string,
    sortType: "string" | "date" | "number"
  ) => {
    if (sortColumn === columnKey) {
      setSortAscending(!sortAscending);
    } else {
      setSortColumn(columnKey);
      setSortAscending(true);
    }

    // Yeni sıralanmış verileri oluşturun ve güncelleyin
    const newSortedData = [...data].sort((a, b) => {
      const aValue = a[columnKey];
      const bValue = b[columnKey];

      if (sortType === "date") {
        return sortAscending
          ? new Date(aValue).getTime() - new Date(bValue).getTime()
          : new Date(bValue).getTime() - new Date(aValue).getTime();
      } else if (sortType === "number") {
        const numA = parseFloat(aValue);
        const numB = parseFloat(bValue);
        return sortAscending ? numA - numB : numB - numA;
      } else {
        return sortAscending
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }
    });

    setSortedData(newSortedData);
  };

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                style={{ width: column.width }}
                onClick={() =>
                  column.sortable &&
                  handleSort(column.key, column.sortType || "string")
                }
              >
                {column.label}
                {column.sortable && (
                  <span className="sort-icon">
                    {sortColumn === column.key && (
                      <FontAwesomeIcon
                        icon={sortAscending ? faChevronUp : faChevronDown}
                      />
                    )}
                  </span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                {columns.map((column, colIndex) => (
                  <td key={colIndex} style={column.sortType === 'number' ? { textAlign: 'right' } : {}}>
                    {column.key === "expand" ? (
                      <span
                        onClick={() => toggleRowExpansion(rowIndex)}
                        style={{ cursor: "pointer" }}
                      >
                        {expandedRows.includes(rowIndex) ? "▼" : "▶"}
                      </span>
                    ) : column.render ? (
                      column.render(row)
                    ) : (
                      row[column.key]
                    )}
                  </td>
                ))}
              </tr>
              {expandedRows.includes(rowIndex) && (
                <tr>
                  <td colSpan={columns.length}>
                    <div className="expandable-content">
                      {expandContent ? expandContent(row) : null}
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
