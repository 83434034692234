import { collection, doc, getDoc, getDocs, setDoc } from "firebase/firestore";
import { db } from "../../firebase-services";
import { UserModel } from "./types";
import { User } from "firebase/auth";

class UsersDBServices {
    private collectionName = "users";
    private dbRef = collection(db, this.collectionName);

    async create(userPayload: UserModel): Promise<UserModel> {
        try {
            const userUid = userPayload.id;
            const userDocRef = doc(this.dbRef, userUid);
            await setDoc(userDocRef, userPayload);

            return userPayload;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getAll(): Promise<UserModel[]> {
        try {
            const querySnapshot = await getDocs(this.dbRef);
            const users = querySnapshot.docs.map((doc) => {
                return {
                    ...doc.data(),
                };
            });

            return users as UserModel[];
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async getById(user: User): Promise<UserModel> {
        try {
            const userDocRef = doc(this.dbRef, user.uid);
            const userDocSnapshot = await getDoc(userDocRef);

            const userData = userDocSnapshot.data();
            return userData as UserModel;

        } catch (error) {

            console.error(error);
            throw error;
        }
    }

}

export default UsersDBServices;
