import React, { FC, ReactNode } from "react";
import "./style.scss";

interface IPageContentProps {
  children: ReactNode;
}

const PageContent: FC<IPageContentProps> = ({ children }) => {
  return <div className="page-container">{children}</div>;
};

export default PageContent;
