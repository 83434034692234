import { Api } from "../../../api";
import QueryLogDBService from "../../db-services/queryLogDBService";
import {
  ICreateUpdateCommentInputModel,
  IExecuteGeneratedCodeInputModel,
  IExecuteGeneratedCodeResponseModel,
  IQueryLogResponseModel,
  IUpdateIndicatorInputModel,
} from "./types";

class QueryLogService {
  private dbService;

  constructor() {
    this.dbService = new QueryLogDBService();
  }

  getYevmiyeCsvCollectionName = () => this.dbService.getYevmiyeCsvCollectionName();

  getYevmiyeSqlCollectionName = () => this.dbService.getYevmiyeSqlCollectionName();

  getFundCollectionName = () => this.dbService.getFundCollectionName();

  getWorkCubeCollectionName = () => this.dbService.getWorkCubeCollectionName();

  getWorkCubeInEngCollectionName = () => this.dbService.getWorkCubeInEngCollectionName();

  getHospitalCollectionName = () => this.dbService.getHospitalCollectionName();

  getHospitalInEngCollectionName = () => this.dbService.getHospitalInEngCollectionName();

  getBaseCollectionName = () => this.dbService.getBaseCollectionName();

  getDataAnalysisCollectionName = () => this.dbService.getDataAnalysisCollectionName();

  async getAllQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllQueryLogsWithSQL(
    dayAgo: number
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllQueryLogsWithSQL(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllFundQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllFundQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllWorkCubeQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllWorkCubeQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllWorkCubeInEngQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllWorkCubeInEngQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllDataAnalysisQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllDataAnalysisQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllBaseQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllBaseQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllHospitalQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getAllHospitalInEngQueryLogs(dayAgo: number): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getAllHospitalInEngQueryLogs(dayAgo);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsByEmail(email: string): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogsSQLByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getQueryLogsSQLByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFundQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getFundQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeInEngQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeInEngQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogsByResultStatus(
    result_status?: boolean
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeQueryLogsByResultStatus(result_status);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeInEngQueryLogsByResultStatus(
    result_status?: boolean
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeInEngQueryLogsByResultStatus(result_status);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogsByStepCount(
    stepCount?: number
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeQueryLogsByStepCount(stepCount);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeInEngQueryLogsByStepCount(
    stepCount?: number
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeInEngQueryLogsByStepCount(stepCount);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDataAnalysisQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getDataAnalysisQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBaseQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getBaseQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getHospitalQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalInEngQueryLogsByEmail(
    email: string
  ): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getHospitalInEngQueryLogsByEmail(email);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getQueryLogSQLById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getByIdWithSQL(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getFundQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getFundLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getWorkCubeLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeInEngQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getWorkCubeInEngLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getDataAnalysisQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getDataAnalysisLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getBaseQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getBaseLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getHospitalLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalInEngQueryLogById(id: string): Promise<IQueryLogResponseModel | null> {
    try {
      const response = await this.dbService.getHospitalInEngLogById(id);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getWorkCubeIndicators(): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getWorkCubeIndicators();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async getHospitalIndicators(): Promise<IQueryLogResponseModel[]> {
    try {
      const response = await this.dbService.getHospitalIndicators();
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateCommentWithSQL(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateByIdWithSQL(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateFundComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateFundLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateDataAnalysisComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateDataAnalysisLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateWorkCubeComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateWorkCubeLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateWorkCubeInEngComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateWorkCubeInEngLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateWorkCubeIndicator(
    id: string,
    data: IUpdateIndicatorInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateWorkCubeLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateWorkCubeInEngIndicator(
    id: string,
    data: IUpdateIndicatorInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateWorkCubeInEngLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateHospitalComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateHospitalLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateHospitalIndicator(
    id: string,
    data: IUpdateIndicatorInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateHospitalLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async createUpdateHospitalInEngComment(
    id: string,
    data: ICreateUpdateCommentInputModel
  ): Promise<void> {
    try {
      await this.dbService.updateHospitalInEngLogById(id, data);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedCodeWithSQL(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_plpgsql_code", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async executeGeneratedTabularDataCode(
    input: IExecuteGeneratedCodeInputModel
  ): Promise<IExecuteGeneratedCodeResponseModel> {
    try {
      const response = await Api.post("/exec_python_code_for_tabular_data", input, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default QueryLogService;
