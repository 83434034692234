import HealthLogDBService from "../../db-services/healthLogDBService";
import { ILogResponseModel } from "../types";


class HealthLogService {
    private dbService;

    constructor() {
        this.dbService = new HealthLogDBService();
    }

    async getAllLogs(dayAgo: number): Promise<ILogResponseModel[]> {
        try {
            const response = await this.dbService.getAll(dayAgo)
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

}


export default HealthLogService;
