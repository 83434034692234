import React, { ReactNode, useEffect, useRef, useState } from "react";
import "./style.scss";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface DropdownOption {
  value: string | number;
  label: string | React.ReactNode;
}

interface DropdownProps {
  options: DropdownOption[];
  onSelect: (selectedValue: DropdownOption) => void;
  value?: DropdownOption;
  error?: boolean;
  disabled?: boolean;
  className?: string;
  label?: ReactNode;
  errorMessage?:string
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  value,
  error,
  disabled,
  className,
  label,
  errorMessage
}) => {
  const [selectedOption, setSelectedOption] = useState<
    DropdownOption | undefined
  >(value || undefined);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: DropdownOption) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    const addOutsideClickListener = () => {
      document.addEventListener("mousedown", handleOutsideClick);
    };

    const removeOutsideClickListener = () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };

    if (isOpen) {
      addOutsideClickListener();
    } else {
      removeOutsideClickListener();
    }

    return () => {
      removeOutsideClickListener();
    };
  }, [isOpen]);

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  return (
    <div
      className={`dropdown-container ${className || ""}  ${
        error ? "dropdown-container-error" : ""
      }`}
      ref={dropdownRef}
      onClick={() => setIsOpen(!isOpen)}
    >
      <button
        className={`dropdown-toggle ${isOpen ? "open" : ""} ${
          error ? "error" : ""
        }`}
        type="button"
        disabled={disabled}
      >
        {selectedOption ? (
          <span>{selectedOption.label}</span>
        ) : (
          "Select an option"
        )}
        {!disabled && (
          <FontAwesomeIcon icon={isOpen ? faCaretUp : faCaretDown} />
        )}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          {options.map((option, index) => (
            <li key={index} onClick={() => handleOptionClick(option)}>
              {option.label}
            </li>
          ))}
        </ul>
      )}

      <legend className="legend">
        <label>{label}</label>
      </legend>

      {errorMessage && error && (
        <label className="error-message">{errorMessage}</label>
      )}
    </div>
  );
};

export default Dropdown;
