import React from "react";
import "./style.scss";
import Spinner from "./Spinner";

const Loading: React.FC = () => {
  return (
    <div className="loading-wrapper">
      <Spinner />
    </div>
  );
};

export default Loading;
