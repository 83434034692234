import QuizDBServices from "../../db-services/quizDBService";
import { IQuestionModel } from "../questionService/types";
import { IQuizPayload, IQuizResponseModel } from "./types";


class QuizService {
    private dbService;

    constructor() {
        this.dbService = new QuizDBServices();
    }

    async createQuiz(quizPayload: IQuizPayload, questionPackageId: string, assignedUserId: string, PDFPackageId: string): Promise<IQuizResponseModel> {
        try {
            const response = await this.dbService.create(quizPayload, questionPackageId, assignedUserId, PDFPackageId)
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async getAllQuizzes(): Promise<IQuizResponseModel[]> {
        try {
            const response = await this.dbService.getAll()
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async getCompletedQuizzess(): Promise<IQuizResponseModel[]> {
        try {
            const response = await this.dbService.getCompletedQuizzes()
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async getQuizById(id: string): Promise<IQuizResponseModel> {
        try {
            const response = await this.dbService.getById(id)
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async updateQuizQuestions(quizId: string, questions: IQuestionModel[]): Promise<string> {
        try {
            const response = await this.dbService.update(quizId, questions)
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };

    async deleteQuizById(id: string): Promise<boolean> {
        try {
            const response = await this.dbService.delete(id)
            return response
        } catch (error) {
            console.error(error);
            throw error
        }
    };
}

export default QuizService