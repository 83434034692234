import React, { useState } from "react";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import "./style.scss";
import { faSave, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import FileInput from "../../../components/FileInput";
import { useTranslation } from "react-i18next";
import Dropdown, { DropdownOption } from "../../../components/Dropdown";
import { isJobSpiderUrlValid, isWikiUrlValid } from "../../../utils/helpers";

interface IProps {
  visible: boolean;
  closeModal: () => void;
  handleAddPDF: (document: File) => void;
  handleAddUrl: (url: string) => void;
}

const AddDocumentModal: React.FC<IProps> = ({
  visible,
  handleAddPDF,
  handleAddUrl,
  closeModal,
}) => {
  const { t } = useTranslation();
  const wikiBaseUrl = "https://tr.wikipedia.org/wiki/";
  const jobBaseUrl = "https://www.jobspider.com/";
  const [document, setDocument] = useState<File | null>(null);
  const [URL, setURL] = useState<string>("");
  const [selectedCreateType, setSelectedCreateType] = useState<DropdownOption>({
    value: "pdf",
    label: "PDF",
  });

  const handleDeletePdf = () => {
    setDocument(null);
  };

  const handleCreatePackage = () => {
    if (selectedCreateType.value === "pdf") {
      handleAddPDF(document!);
    } else {
      handleAddUrl(URL);
    }
  };

  const isValidForm = () =>
    selectedCreateType.value === "pdf"
      ? document
      : isWikiUrlValid(URL, wikiBaseUrl) ||
        isJobSpiderUrlValid(URL, jobBaseUrl);

  return (
    <Modal
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      title={t("PDFPackages.addDocumentToPackage")}
      className="create-package-modal"
    >
      <div className="modal-line">
        <Dropdown
          label={t("PDFPackages.documentType")}
          options={[
            { value: "pdf", label: "PDF" },
            { value: "url", label: "URL" },
          ]}
          onSelect={setSelectedCreateType}
          value={selectedCreateType}
        />
      </div>
      {selectedCreateType.value === "pdf" ? (
        <>
          {!document && (
            <div className="modal-line">
              <FileInput
                maxSize={5}
                handleChange={(pdf) => setDocument(pdf as File)}
              />
            </div>
          )}
        </>
      ) : (
        <div className="modal-line">
          <Input
            label={t("PDFPackages.url")}
            // placeholder={wikiBaseUrl}
            error={
              !(
                isWikiUrlValid(URL, wikiBaseUrl) ||
                isJobSpiderUrlValid(URL, jobBaseUrl)
              )
            }
            value={URL}
            onChange={(e) => setURL(e as string)}
            errorMessage={t("PDFPackages.urlValidMessage")}
          />

          <div className="supported-urls-container">
            <br />
            <b className="label">Supported URLS</b>
            <br />
            <br />
            <span>https://www.jobspider.com/</span>
            <br />
            <span>https://**/.wikipedia.org/wiki/</span>
          </div>
        </div>
      )}
      {document && selectedCreateType.value === "pdf" && (
        <div className="pdfs">
          <div className="pdf">
            <div className="text">{document.name}</div>
            <div className="action">
              <Button
                onClick={() => handleDeletePdf()}
                className="delete-button"
                icon={faTrash}
              />
            </div>
          </div>
        </div>
      )}

      <div className="footer">
        <Button
          onClick={handleCreatePackage}
          className="save-button"
          disabled={!isValidForm()}
          icon={faSave}
          iconSize="2x"
        />
      </div>
    </Modal>
  );
};

export default AddDocumentModal;
