import { collection, getDocs, query, where, Timestamp, orderBy } from "firebase/firestore";
import { db } from "../../firebase-services";
import { LogDBModel } from "../types";



class HospitalInEngLogDBServices {
  private collectionName = "hospitalEnglishLLMLogs";
  private dbRef = collection(db, this.collectionName);

  async getAll(dayAgo = 3): Promise<LogDBModel[]> {
    try {
      const date = new Date();
      date.setDate(date.getDate() - dayAgo);

      const querySnapshot = await getDocs(
        query(this.dbRef,
        where('requestTime', '>=', Timestamp.fromDate(date)),
        orderBy('requestTime', 'desc'))
      );

      const logs: LogDBModel[] = [];

      querySnapshot.forEach((doc) => {
        const packageData = doc.data() as LogDBModel;
        logs.push({
          ...packageData,
          id: doc.id,
        });
      });
      return logs;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
}

export default HospitalInEngLogDBServices;