import React, { ChangeEventHandler, InputHTMLAttributes } from "react";
import "./style.scss";

interface CheckboxInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const CheckboxInput: React.FC<CheckboxInputProps> = ({
  label,
  checked,
  className,
  onChange,
  ...rest
}) => {
  return (
    <div className={`checkbox-input ${className}`}>
      <label>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          {...rest}
        />
        {label}
      </label>
    </div>
  );
};

export default CheckboxInput;