import { LoadError } from "@react-pdf-viewer/core";
import "./style.scss";

export const renderError = (error: LoadError) => {
  let message = "";
  switch (error.name) {
    case "InvalidPDFException":
      message = "The document is invalid or corrupted";
      break;
    case "MissingPDFException":
      message = "The document is missing";
      break;
    case "UnexpectedResponseException":
      message = "This document is no longer in the collection.";
      break;
    default:
      message = "Cannot load the document";
      break;
  }

  return (
    <div className="pdf-viewer-error-container">
      <div className="error-message">{message}</div>
    </div>
  );
};
