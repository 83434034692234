import QuestionDBServices from "../../db-services/questionDBService";
import { IQuestionPackagePayloadModel, IQuestionPackageResponseModel } from "./types";


class QuestionService {
    private dbService;

    constructor() {
        this.dbService = new QuestionDBServices();
    }

    async createQuestions(questionPackage: IQuestionPackagePayloadModel): Promise<string> {
        try {
            const response = await this.dbService.create(questionPackage)
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    async getAllQuestions(): Promise<IQuestionPackageResponseModel[]> {
        try {
            const response = await this.dbService.getAll()
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

}

export default QuestionService;
