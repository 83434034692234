import { toast } from "react-toastify";
import { createUser, githubLogIn, googleLogIn, logIn, logOut } from "../../firebase-services/auth";
import UserService from "../userService";
import { UserRoleTypes } from "../../db-services/userDBService/types";
import { UserResponseModel } from "./types";
import { User } from "firebase/auth";

export default class SignUIService {
  private userService: UserService | null = null
  
  constructor() {
    this.userService = new UserService();
  }

  async checkInUser(
    user: User): Promise<UserResponseModel> {
    try {
      const userDBData = await this.userService?.getUser(user)
      if (!userDBData) {
        await this.userService?.create(
          {
            id: user.uid,
            email: user.email!,
            role: UserRoleTypes.Standart
          }
        )
      }
      const currentUser = { ...user, role: userDBData?.role || UserRoleTypes.Standart }
      localStorage.setItem("userInfo", JSON.stringify(currentUser));

      return currentUser ?? null;
    } catch (error: any) {
      throw error;
    }
  }

  async logIn(
    email: string,
    password: string
  ): Promise<UserResponseModel> {
    try {
      const result = await logIn(email, password);
      const currentUser = await this.checkInUser(result.user)
      return currentUser ?? null;
    } catch (error: any) {
      toast.error(error?.message)
      throw error;
    }
  }

  async googleLogIn(
  ): Promise<UserResponseModel> {
    try {
      const result = await googleLogIn();
      const currentUser = await this.checkInUser(result.user)
      return currentUser ?? null;
    } catch (error: any) {
      throw error;
    }
  }

  async githubLogIn(
  ): Promise<UserResponseModel> {
    try {
      const result = await githubLogIn();
      const currentUser = await this.checkInUser(result.user)
      return currentUser ?? null;
    } catch (error: any) {
      toast.error(error)
      throw error;
    }
  }


  async logOut(): Promise<boolean> {
    try {
      localStorage.clear()
      await logOut();
      return true;
    } catch (error: any) {
      throw error;
    }
  }

  async signUp(
    email: string,
    password: string
  ): Promise<UserResponseModel> {
    try {

      var result = await createUser(email, password);
      const currentUser = await this.checkInUser(result.user)
      return currentUser ?? null;

    } catch (error: any) {
      toast.error(error?.message)
      throw error;
    }
  }
}
