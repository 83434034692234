import React, { memo, useCallback, useLayoutEffect, useMemo, useState } from "react";
import PDFViewer from "../../components/PDFViewer/PDFViewer";
import "./style.scss";
import { useParams } from "react-router-dom";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import QuizService from "../../services/ui-services/quizService";
import QuizChat from "../../components/QuizChat";
import PDFPackageService from "../../services/ui-services/documentPackageService";
import { useTranslation } from "react-i18next";
import { IQuizResponseModel } from "../../services/ui-services/quizService/types";
import { IPDFPackageResponseModel } from "../../services/ui-services/documentPackageService/types";
import { IActiveReferenceModel } from "../../components/Chat/types";

const BaseAssignmentDetail: React.FC = () => {
  const quizService = useMemo(() => {
    return new QuizService();
  }, [])

  const pdfPackageService = useMemo(() => {
    return new PDFPackageService();
  }, [])

  const { t } = useTranslation();
  let { quizId } = useParams();
  const { onCompleted, onLoading } = useLoading();
  const [quizInfo, setQuizInfo] = useState<IQuizResponseModel | undefined>(
    undefined
  );
  const [activeReference, setActiveReference] = useState<
    IActiveReferenceModel | undefined
  >(undefined);
  const [PDFPackageInfo, setPDFPackage] = useState<
    IPDFPackageResponseModel | undefined
  >(undefined);

  const getQuiz = async () => {
    try {
      const quiz = await quizService.getQuizById(quizId!);
      setQuizInfo(quiz);
      const PDFPackage = await pdfPackageService.getQuizById(
        quiz.PDFPackageInfo.id
      );
      setPDFPackage(PDFPackage!);
    } catch (error) {
      toast.error(t("error.tryAgain"));
    }
  };

  const loadComponentCallback = useCallback(async () => {
    try {
      onLoading();
      await getQuiz();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted, onLoading]);

  useLayoutEffect(() => {
    loadComponentCallback();
  }, [loadComponentCallback]);

  return (
    <div className="quiz-detail-page-container">
      <div className="left-side">
        <QuizChat
          quizInfo={quizInfo}
          activeReference={activeReference}
          handleReference={setActiveReference}
        />
      </div>
      <div className="right-side">
        {PDFPackageInfo && (
          <PDFViewer
            fileUrl={`${process.env.REACT_APP_S3_BASE_URL}${
              PDFPackageInfo?.content.collectionId
            }/${
              activeReference?.ref_file_name ??
              PDFPackageInfo?.content.PDFS[0].url!
            }`}
            searchKeys={activeReference?.ref_text_lines}
            pageNumber={activeReference?.ref_page}
          />
        )}
      </div>
    </div>
  );
};

export default memo(BaseAssignmentDetail);
