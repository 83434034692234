// @ts-nocheck
import React, { FC, useEffect } from "react";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Card,
  Row,
  Col,
  Space,
  Divider,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "./style.scss";
import { IPatientFormModel } from "./types";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import { DATETIME_FORMAT, DATE_FORMAT } from "../../../constants";

dayjs.extend(customParseFormat);

const { Option } = Select;

type Props = {
  onCreate: (values: IPatientFormModel) => void;
  initialValues?: IPatientFormModel;
  submitting?: boolean;
  title: string;
  updateMode?: boolean;
  onCancel: () => void;
};

const CreateUpdatePatientForm: FC<Props> = ({
  onCreate,
  initialValues,
  submitting,
  title,
  updateMode = false,
  onCancel
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialValues) {
      const convertedInitialValues = {
        ...initialValues,
        admission: initialValues?.admission
          ? dayjs(initialValues.admission, DATE_FORMAT)
          : null,
        vitals: initialValues?.vitals?.map((vital) => ({
          ...vital,
          date: vital.date
            ? dayjs(moment(vital.date).format(DATETIME_FORMAT), DATETIME_FORMAT)
            : null,
        })),
        labs: initialValues?.labs?.map((lab) => ({
          ...lab,
          date: lab.date
            ? dayjs(moment(lab.date).format(DATETIME_FORMAT), DATETIME_FORMAT)
            : null,
        })),
        recent_changes: initialValues?.recent_changes?.map((change) => ({
          ...change,
          date: change.date
            ? dayjs(moment(change.date).format(DATETIME_FORMAT), DATETIME_FORMAT)
            : null,
        })),
      };
      form.setFieldsValue(convertedInitialValues);
    }
  }, [form, initialValues, updateMode]);

  const onFinish = (values) => {
    onCreate(values);
  };

  return (
    <div style={{ padding: "24px", maxWidth: "1200px", margin: "0 auto" }}>
      <Card title={title}>
        <Form
          form={form}
          layout="vertical"
          name="full_page_form"
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="patientID"
                label="Patient ID"
                rules={[
                  {
                    required: true,
                    message: "Please input the patient ID!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="age"
                label="Age"
              >
                <InputNumber min={0} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="sex"
                label="Sex"
              >
                <Select placeholder="Select a option">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                  <Option value="Other">Other</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="admission"
                label="Admission Date"
                // rules={[
                //   {
                //     required: true,
                //     message: "Please select the admission date!",
                //   },
                // ]}
              >
                <DatePicker style={{ width: "100%" }} format={DATE_FORMAT} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Form.Item
                name="patient_history"
                label="Patient History"
              >
                <Input.TextArea cols={5} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col span={24}>
              <Form.Item
                name="short_notes"
                label="Short Notes"
              >
                <Input.TextArea cols={5} />
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Form.List name="past_medical_history">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Medical History ${i + 1}`}
                    // required
                  >
                    <Input.Group compact style={{ width: "100%" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "icd_code"]}
                        fieldKey={[fieldKey, "icd_code"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the medical icd_code!",
                          },
                        ]}
                        style={{ width: "30%" }}
                      >
                        <Input placeholder="ICD Code" style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "description"]}
                        fieldKey={[fieldKey, "description"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the description!",
                          },
                        ]}
                        style={{ width: "65%" }}
                      >
                        <Input placeholder="Description" style={{ width: "100%" }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Medical History
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="allergies">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Allergy ${i + 1}`}
                    // required
                  >
                    <Form.Item
                      {...restField}
                      name={[name]}
                      fieldKey={[fieldKey]}
                      rules={[
                        {
                          required: true,
                          message: "Please input the allergy!",
                        },
                      ]}
                    >
                      <Input placeholder="Allergy" style={{ width: "100%" }} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Allergy
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="diet">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Diet ${i + 1}`}
                    // required
                  >
                    <Form.Item
                      {...restField}
                      name={[name]}
                      fieldKey={[fieldKey]}
                      rules={[
                        { required: true, message: "Please input the diet!" },
                      ]}
                    >
                      <Input placeholder="Diet" style={{ width: "100%" }} />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Diet
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="vitals">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Vital ${i + 1}`}
                    // required
                  >
                    <Input.Group compact style={{ width: "100%" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "test_name"]}
                        fieldKey={[fieldKey, "test_name"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the test name!",
                          },
                        ]}
                        style={{ width: "25%" }}
                      >
                        <Input placeholder="Test Name" style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "date"]}
                        fieldKey={[fieldKey, "date"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the change date!",
                          },
                        ]}
                        style={{ width: "25%" }}
                      >
                        <DatePicker
                          placeholder="Date"
                          style={{ width: "100%" }}
                          showTime
                          format={DATETIME_FORMAT}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "value"]}
                        fieldKey={[fieldKey, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the value!",
                          },
                        ]}
                        style={{ width: "25%" }}
                      >
                        <Input placeholder="Value" style={{ width: "100%" }} />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "unit"]}
                        fieldKey={[fieldKey, "unit"]}
                        rules={[
                          { required: true, message: "Please input the unit!" },
                        ]}
                        style={{ width: "15%" }}
                      >
                        <Input placeholder="Unit" style={{ width: "100%" }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Vital
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="meds">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Medication ${i + 1}`}
                    // required
                  >
                    <Form.Item
                      {...restField}
                      name={[name]}
                      fieldKey={[fieldKey]}
                      rules={[
                        {
                          required: true,
                          message: "Please input the medication!",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Medication"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                    <MinusCircleOutlined onClick={() => remove(name)} />
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Medication
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="labs">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <div key={key}>
                    <Space
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item
                        {...restField}
                        name={[name, "date"]}
                        fieldKey={[fieldKey, "date"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the lab date!",
                          },
                        ]}
                      >
                        <DatePicker
                          placeholder="Date"
                          showTime
                          format={DATETIME_FORMAT}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "source"]}
                        fieldKey={[fieldKey, "source"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the source!",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Source"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Space>
                    <Form.List
                      name={[name, "results"]}
                      fieldKey={[fieldKey, "results"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input results!",
                        },
                      ]}
                    >
                      {(subFields, { add: subAdd, remove: subRemove }) => (
                        <>
                          {subFields.map(
                            ({
                              key: subKey,
                              name: subName,
                              fieldKey: subFieldKey,
                              ...subRestField
                            }) => (
                              <Space
                                key={subKey}
                                style={{ display: "flex", marginBottom: 8 }}
                                align="baseline"
                              >
                                <Form.Item
                                  {...subRestField}
                                  name={[subName, "key"]}
                                  fieldKey={[subFieldKey, "key"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the key!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Key"
                                    style={{ width: "100px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  {...subRestField}
                                  name={[subName, "value"]}
                                  fieldKey={[subFieldKey, "value"]}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input the value!",
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder="Value"
                                    style={{ width: "100px" }}
                                  />
                                </Form.Item>
                                <MinusCircleOutlined
                                  onClick={() => subRemove(subName)}
                                />
                              </Space>
                            )
                          )}
                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => subAdd()}
                              block
                              icon={<PlusOutlined />}
                              style={{ width: "50px" }}
                            />
                          </Form.Item>
                        </>
                      )}
                    </Form.List>
                  </div>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Lab Result
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <Divider />
          <Form.List name="recent_changes">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }, i) => (
                  <Form.Item
                    key={key}
                    label={`Change ${i + 1}`}
                    // required
                  >
                    <Input.Group compact style={{ width: "100%" }}>
                      <Form.Item
                        {...restField}
                        name={[name, "date"]}
                        fieldKey={[fieldKey, "date"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the change date!",
                          },
                        ]}
                        style={{ width: "15%" }}
                      >
                        <DatePicker
                          placeholder="Date"
                          style={{ width: "100%" }}
                          showTime
                          format={DATETIME_FORMAT}
                        />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "complaint"]}
                        fieldKey={[fieldKey, "complaint"]}
                        rules={[
                          {
                            required: true,
                            message: "Please input the vital complaint!",
                          },
                        ]}
                        style={{ width: "30%" }}
                      >
                        <Input placeholder="Complaint" style={{ width: "100%" }} />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        name={[name, "action"]}
                        fieldKey={[fieldKey, "action"]}
                        style={{ width: "50%" }}
                      >
                        <Input placeholder="Action" style={{ width: "100%" }} />
                      </Form.Item>
                      <MinusCircleOutlined onClick={() => remove(name)} />
                    </Input.Group>
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Recent Change
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={submitting}
              disabled={submitting}
              style={{ marginRight: '10px' }}
            >
              Submit
            </Button>
            <Button
              disabled={submitting}
              onClick={onCancel}
              type="text"
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateUpdatePatientForm;
