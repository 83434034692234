import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import IndicatorLayout from "../../components/IndicatorLayout";
import QueryLogService from "../../services/ui-services/queryLogsService";
import { IIndicatorModel } from "../../components/IndicatorLayout/types";
import { IJsonModel } from "flexlayout-react";
import { Skeleton } from "antd";
import "./style.scss";
import LayoutService from "../../services/ui-services/layoutService";
import { ProjectTypes } from "../../models/ProjectTypes";

const WorkCubeDashboard = () => {
  const queryLogService = useMemo(() => {
    return new QueryLogService();
  }, []);

  const layoutService = useMemo(() => {
    return new LayoutService();
  }, []);

  const [indicators, setIndicators] = useState<IIndicatorModel[]>([]);
  const [jsonLayout, setJsonLayout] = useState<IJsonModel>();
  const [loadingIndicators, setLoadingIndicators] = useState(false);
  const [loadingLayout, setLoadingLayout] = useState(false);

  const getIndicators = useCallback(async () => {
    try {
      setLoadingIndicators(true);
      const res = await queryLogService.getWorkCubeIndicators();
      setIndicators(
        res.map((indicator, i) => ({
          id: indicator?.id ?? "",
          name: (i + 1).toString(),
          content: indicator?.user_query ?? '',
          generaterFunction: () =>
            queryLogService.executeGeneratedTabularDataCode({
              fb_path:`${queryLogService.getWorkCubeCollectionName()}/${indicator.id}`,
            })
        }))
      );
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingIndicators(false);
    }
  }, [queryLogService]);

  useEffect(() => {
    getIndicators();
  }, [getIndicators]);

  const getLayout = useCallback(async () => {
    try {
      setLoadingLayout(true);
      const res = await layoutService.getAll(ProjectTypes.WorkCube);
      if (res.length) {
        setJsonLayout(JSON.parse(res[0].content));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingLayout(false);
    }
  }, [layoutService]);

  useEffect(() => {
    getLayout();
  }, [getLayout]);

  if (loadingIndicators || loadingLayout) {
    return <Skeleton active />;
  }

  return (
    <IndicatorLayout
      indicators={indicators}
      jsonLayout={jsonLayout}
      isPreview
    />
  );
};

export default memo(WorkCubeDashboard);
