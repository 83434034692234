import { Api } from "../../../api";
import { IAskCollectionPayloadModel, IAskCollectionResponseModel, IAskCollectionWindowSizePayloadModel, IAskConfigration } from "./types";

class ChatService {

  async askCollectionVectorization(
    question: string, configration: IAskConfigration, collectionId: string
  ): Promise<IAskCollectionResponseModel> {
    try {
      const payload: IAskCollectionPayloadModel = {
        collection_id: collectionId,
        embed_model_number: configration.embed_model_number,
        top_k: configration.top_k,
        top_n: configration.top_n,
        llm: "openai",
        engine_name: configration.engine_name,
        llm_temp: configration.llm_temp,
        reduction_type: configration.reduction_type,
        question: question,
        context: configration.context
      };

      const response = await Api.post('/ask_to_llm_with_local_collection2', payload, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error
    }
  }

  async askCollectionWindowSize(
    question: string, context: string | null, collectionId: string
  ): Promise<IAskCollectionResponseModel> {
    try {
      const payload: IAskCollectionWindowSizePayloadModel = {
        collection_id: collectionId,
        question: question,
        context: context
      };

      const response = await Api.post('/doc_qa_large_window_llm', payload, {});

      return response;
    } catch (error) {
      console.error(error);
      throw error
    }
  }

}

export default ChatService