import React from "react";
import Dropdown from "../../Dropdown";
import Modal from "../../Modal";
import Input from "../../Input";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { embedModels } from "../../../data";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import { IAskConfigration } from "../../../services/ui-services/chatService/types";
import { IConfigurationTypes } from "../types";
import RadioButton from "../../RadioButton";

interface IProps {
  configType: IConfigurationTypes;
  configInfo: IAskConfigration;
  visible: boolean;
  onChangeConfigType: (e: IConfigurationTypes) => void;
  onChangeConfig: (e: IAskConfigration) => void;
  closeModal: () => void;
}

const ConfigrationModal: React.FC<IProps> = ({
  configInfo,
  visible,
  configType,
  onChangeConfigType,
  onChangeConfig,
  closeModal,
}) => {
  const { t } = useTranslation();

  const configurationTypes = [
    {
      label: "Large context window",
      value: IConfigurationTypes.WindowSize,
    },
    {
      label: "Vectorization",
      value: IConfigurationTypes.Vectorization,
    },
  ];

  const reductionTypes = [
    {
      label: "Map Reduce",
      value: "map_reduce",
      description: t("askConfig.reductionType.mapReduce.label"),
    },
    {
      label: "Stuff",
      value: "stuff",
      description: t("askConfig.reductionType.stuff.label"),
    },
    {
      label: "Refine",
      value: "refine",
      description: t("askConfig.reductionType.refine.label"),
    },
    {
      label: "Map Rerank",
      value: "map_rerank",
      description: t("askConfig.reductionType.mapRerank.label"),
    },
  ];

  const radioChangeHandler = (e: any) => {
    onChangeConfigType(e.target?.value as IConfigurationTypes);
  };

  return (
    <Modal
      className="ask-modal-container"
      isOpen={visible}
      onClose={closeModal}
      title={t("askConfig.modalName")}
    >
      <div className="config-modal-form">
        <div className="types-container">
          {configurationTypes.map((type) => {
            return (
              <RadioButton
                key={type.value}
                changed={radioChangeHandler}
                id={type.value}
                isSelected={configType === type.value}
                label={type.label}
                value={type.value}
              />
            );
          })}
        </div>
        {configType === IConfigurationTypes.Vectorization ? (
          <>
            <div className="modal-line">
              <Dropdown
                label={t("askConfig.engine.name")}
                disabled
                options={[{ value: "gpt-3.5-turbo-16k", label: "GPT 3.5 Turbo 16k" }]}
                onSelect={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    engine_name: e.value as string,
                  })
                }
                value={{
                  value: configInfo.engine_name,
                  label: configInfo.engine_name,
                }}
              />
            </div>
            <div className="modal-line">
              <Dropdown
                label={
                  <div className="label-container">
                    <span>{t("askConfig.model.name")}</span>
                    <Tooltip id="model" content={t("askConfig.model.label")} />
                    <FontAwesomeIcon
                      data-tooltip-id="model"
                      icon={faInfoCircle}
                    />
                  </div>
                }
                options={embedModels}
                onSelect={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    embed_model_number: e.value as number,
                  })
                }
                value={{
                  value: configInfo.embed_model_number,
                  label: embedModels.find((model) => model.value === configInfo.embed_model_number)?.label,
                }}
              />
            </div>
            <div className="modal-line">
              <Input
                label={
                  <div className="label-container">
                    <span>{t("askConfig.topK.name")}</span>
                    <Tooltip id="topK" content={t("askConfig.topK.label")} />
                    <FontAwesomeIcon
                      data-tooltip-id="topK"
                      icon={faInfoCircle}
                    />
                  </div>
                }
                max={10}
                min={0}
                type="number"
                value={configInfo.top_k}
                onChange={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    top_k:
                      JSON.parse(e as string) < configInfo.top_n
                        ? configInfo.top_n
                        : (e as number),
                  })
                }
              />
            </div>
            <div className="modal-line">
              <Input
                label={
                  <div className="label-container">
                    <span>{t("askConfig.topN.name")}</span>
                    <Tooltip id="topN" content={t("askConfig.topN.label")} />
                    <FontAwesomeIcon
                      data-tooltip-id="topN"
                      icon={faInfoCircle}
                    />
                  </div>
                }
                max={10}
                min={0}
                type="number"
                value={configInfo.top_n}
                onChange={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    top_n:
                      JSON.parse(e as string) > configInfo.top_k
                        ? configInfo.top_k
                        : (e as number),
                  })
                }
              />
            </div>

            <div className="modal-line">
              <Input
                label={
                  <div className="label-container">
                    <span>{t("askConfig.llmTemp.name")}</span>
                    <Tooltip
                      id="llmTemp"
                      content={t("askConfig.llmTemp.label")}
                    />
                    <FontAwesomeIcon
                      data-tooltip-id="llmTemp"
                      icon={faInfoCircle}
                    />
                  </div>
                }
                type="number"
                value={configInfo.llm_temp}
                onChange={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    llm_temp: e as number,
                  })
                }
                decimalIncrease
                min={0}
                max={1}
              />
            </div>
            <div className="modal-line">
              <Dropdown
                label={
                  <div className="label-container">
                    <span>{t("askConfig.reductionType.name")}</span>
                  </div>
                }
                options={reductionTypes.map((reduction) => {
                  return {
                    value: reduction.value,
                    label: (
                      <>
                        <span className="reduction-dropdown-select">
                          {reduction.label}
                          <Tooltip
                            id={reduction.value}
                            content={reduction.description}
                          />
                          <FontAwesomeIcon
                            data-tooltip-id={reduction.value}
                            icon={faInfoCircle}
                          />
                        </span>
                      </>
                    ),
                  };
                })}
                onSelect={(e) =>
                  onChangeConfig({
                    ...configInfo,
                    reduction_type: e.value as string,
                  })
                }
                value={{
                  value: configInfo.reduction_type,
                  label: reductionTypes.find(
                    (type) => type.value === configInfo.reduction_type
                  )!.label,
                }}
              />
            </div>
          </>
        ) : (
          <>
            <span className="collection-context">{configInfo.context}</span>
          </>
        )}
      </div>
    </Modal>
  );
};

export default ConfigrationModal;
