import { User } from "firebase/auth";
import { UserRoleTypes } from "../../db-services/userDBService/types";
import { PermissionTypes } from "../../../permissions";

export interface UserResponseModel extends User {
    role: UserRoleTypes,
    permissions?: PermissionTypes[]
}

export const canSeeHospitalData = (user: UserResponseModel | null) => {
    if (!user) return false;
    return user.permissions?.includes(PermissionTypes.ViewHospitalLogsPage) ?? false;
}

export const canSeeHospitalInEngData = (user: UserResponseModel | null) => {
    if (!user) return false;
    return user.permissions?.includes(PermissionTypes.ViewHospitalInEngLogsPage) ?? false;
}