import { FC } from 'react'
import './style.scss'
import { IProps } from './types'
import { Popconfirm } from 'antd'

const Confirm: FC<IProps> = ({ children, onSubmit, title, description }) => {
    const onConfirm = () => {
        onSubmit()
    }

    return (
        <Popconfirm title={title} description={description} onConfirm={onConfirm} okText="Yes" cancelText="No">
            {children}
        </Popconfirm>
    )
}

export default Confirm
