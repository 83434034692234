import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useLoading } from "../../context/store/loadingContext";
import { toast } from "react-toastify";
import "./style.scss";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateModal from "./CreateModal";
import QuestionBox from "../../components/QuestionBox";
import QuestionService from "../../services/ui-services/questionService";
import Button from "../../components/Button";
import { useTranslation } from "react-i18next";
import { IQuestionPackageModel } from "../../services/ui-services/questionService/types";
import { Tooltip } from "react-tooltip";

const BaseQuestionCollections: React.FC = () => {
  const questionService = useMemo(() => {
    return new QuestionService();
  }, [])

  const { t } = useTranslation();
  const { onCompleted, onLoading } = useLoading();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IQuestionPackageModel[]>([]);

  const fetchQuestions = async () => {
    const response = await questionService.getAllQuestions();
    const packages = response.map((data) => data.content);
    setQuestions(packages);
  };

  const loadComponent = useCallback(async () => {
    try {
      onLoading();
      await fetchQuestions();
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      onCompleted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onCompleted, onLoading]);

  useEffect(() => {
    loadComponent();
  }, [loadComponent]);

  const createQuestionPackage = async (name: string, questions: string[]) => {
    try {
      onLoading();
      const payload = {
        name,
        questions,
        creatorUserInfo: {} as any,
      };
      await questionService.createQuestions(payload);
    } catch (error) {
      toast.error(t("error.tryAgain"));
    } finally {
      await fetchQuestions();
      setModalVisible(!modalVisible);
      onCompleted();
    }
  };

  return (
    <div className="questions-container">
      <div className="add-question-container">
        <Tooltip id="add" />
        <Button
          data-tooltip-id="add"
          data-tooltip-content={t("page.addNew")}
          onClick={() => setModalVisible(!modalVisible)}
          icon={faPlus}
        />
      </div>
      <QuestionBox questionPackages={questions} />
      {modalVisible && (
        <CreateModal
          visible={modalVisible}
          closeModal={() => setModalVisible(!modalVisible)}
          handleCreateQuestionPackage={createQuestionPackage}
        />
      )}
    </div>
  );
};

export default memo(BaseQuestionCollections);
