import { createContext, useState, useContext, useEffect } from "react";
import { ProjectTypes } from "../../models/ProjectTypes";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "./authContext";
import { PermissionTypes } from "../../permissions";

export interface ProjectContextType {
  projectType: ProjectTypes;
  onChangeProjectType: (type: ProjectTypes) => void;
}

let ProjectContext = createContext<ProjectContextType>(null!);

const setPermissionBasedTab = (permissions: PermissionTypes[]) => {
  if (permissions.includes(PermissionTypes.ViewBasePagesTab)) {
    return ProjectTypes.Base;
  } else if (permissions.includes(PermissionTypes.ViewYevmiyePagesTab)) {
    return ProjectTypes.Yevmiye;
  } else if (permissions.includes(PermissionTypes.ViewDataAnalysisPagesTab)) {
    return ProjectTypes.DataAnalysis;
  } else if (permissions.includes(PermissionTypes.ViewHealthPagesTab)) {
    return ProjectTypes.Health;
  } else if (permissions.includes(PermissionTypes.ViewFundPagesTab)) {
    return ProjectTypes.Fund;
  } else if (permissions.includes(PermissionTypes.ViewHospitalPagesTab)) {
    return ProjectTypes.Hospital;
  } else if (permissions.includes(PermissionTypes.ViewHospitalInEngPagesTab)) {
    return ProjectTypes.HospitalInEng;
  } else if (permissions.includes(PermissionTypes.ViewWorkCubePagesTab)) {
    return ProjectTypes.WorkCube;
  } else if (permissions.includes(PermissionTypes.ViewWorkCubeInEngPagesTab)) {
    return ProjectTypes.WorkCubeInEng;
  }

  return ProjectTypes.Base;
};

const setInitialProjectType = (
  searchParams: URLSearchParams,
  permissions?: PermissionTypes[]
) => {
  return searchParams.get("project_type")
    ? (Number(searchParams.get("project_type")) as unknown as ProjectTypes)
    : setPermissionBasedTab(permissions ?? []);
};

function ProjectProvider({ children }: { children: React.ReactNode }) {
  const { loggedInUserData } = useAuth();
  const [searchParams] = useSearchParams();
  const [projectType, setProjectType] = useState<ProjectTypes>(
    setInitialProjectType(searchParams, loggedInUserData?.permissions)
  );

  useEffect(() => {
    setProjectType(
      setInitialProjectType(searchParams, loggedInUserData?.permissions)
    );
  }, [loggedInUserData?.permissions, searchParams]);

  const onChangeProjectType = (type: ProjectTypes) => {
    setProjectType(type);
  };

  let value = { projectType, onChangeProjectType };

  return (
    <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>
  );
}

const useProject = (): ProjectContextType => {
  return useContext(ProjectContext);
};

export { useProject, ProjectProvider };
