import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Input, Select, Slider } from "antd";
import React, { FC, useEffect, useMemo, useState } from "react";
import QueryContainer from "./QueryContainer";
import { IQueryModel } from "./QueryItems/types";
import QueryItems from "./QueryItems";
import QueryInput from "./QueryInput";
import "./style.scss";
import { IPatientFormModel } from "../../HealthCreatePatient/CreateUpdatePatient/types";
import { useAuth } from "../../../context/store/authContext";
import { toast } from "react-toastify";
import HealthAssistantService from "../../../services/ui-services/healthAssistantService";
import { AssistantInfoModel } from "..";
import { IInstructionModel } from "../../../services/db-services/healthInstructionsDBService/types";
import type { InputNumberProps } from "antd";
import ChatCommentModal from "./CommentModal";
import QueryHealthChatLogService from "../../../services/ui-services/healthQueryChatLogsService";

type Props = {
  id: string;
  onClose: VoidFunction;
  queries: IQueryModel[];
  onChangeQueries: (args: IQueryModel[]) => void;
  patient: IPatientFormModel;
  assistantInfo: AssistantInfoModel;
  onChangeAssistantInfo: (args: AssistantInfoModel) => void;
  instructions: IInstructionModel[];
  chatComments: ChatCommentModel[];
  onChangeChatComments: (args: ChatCommentModel[]) => void;
  onChangeChatLogId: (id: string) => void;
  existChatLogId?: string;
  onSaveInstruction: (
    index: number,
    content: string,
    callback: Function
  ) => Promise<void>;
};

export interface ChatCommentModel {
  index: number;
  rating: number;
  comment: string;
}

const { TextArea } = Input;

const { Option } = Select;

const modelOptions = ["gpt-4o-mini", "gpt-4o"];

const ChatSidebar: FC<Props> = ({
  onClose,
  queries,
  onChangeQueries,
  patient,
  id,
  assistantInfo,
  onChangeAssistantInfo,
  instructions,
  chatComments,
  onChangeChatComments,
  onChangeChatLogId,
  existChatLogId,
  onSaveInstruction,
}) => {
  const { loggedInUserData } = useAuth();
  const [instruction, setInstruction] = useState("");
  const [temperature, setTemperature] = useState(0.2);
  const [model, setModel] = useState("gpt-4o-mini");
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState<
    undefined | number
  >();
  const [visibleCommentModal, setVisibleCommentModal] = useState<{
    index: number | undefined;
  }>({
    index: undefined,
  });
  const [updatingInstruction, setUpdatingInstruction] = useState(false);

  const healthAssistantService = useMemo(
    () => new HealthAssistantService(),
    []
  );

  const queryHealthChatLogService = useMemo(
    () => new QueryHealthChatLogService(),
    []
  );

  useEffect(() => {
    setInstruction(instructions.find((inst) => inst.isDefault)?.content ?? "");
    setSelectedInstructionIndex(
      instructions.findIndex((inst) => inst.isDefault)
    );
  }, [instructions]);

  const placeholder = () => (
    <div className="placeholder-text">How can I help you?</div>
  );

  const onSearch = async (query: string) => {
    try {
      onChangeQueries([
        ...queries,
        {
          answer: "",
          inProgress: true,
          query,
        },
      ]);
      const payload = {
        firebase_document_id: id,
        patient_id: patient?.patientID ?? "",
        email: loggedInUserData?.email ?? "",
        query,
        instructions: instruction,
        model,
        temperature,
        ...assistantInfo,
      };
      const res = await healthAssistantService.sendQuery(payload);
      onChangeAssistantInfo(res.answer);
      let existQueries = [...queries];
      existQueries.push({
        answer: res.answer.answer,
        inProgress: false,
        query,
      });
      onChangeQueries(existQueries);
    } catch (error) {
      console.log(error);
      toast.warn((error as Error).message);
    }
  };

  const onChangeInstruction = (index: number) => {
    setSelectedInstructionIndex(index);
    setInstruction(instructions[index]?.content ?? "");
  };

  const onChangeTemperature: InputNumberProps["onChange"] = (newValue) => {
    setTemperature(newValue as number);
  };

  const onLike = (index: number) => {
    setVisibleCommentModal({ index });
  };

  const showCommentModal = () => visibleCommentModal.index !== undefined;

  const setExistCommentContent = () =>
    chatComments?.find(
      (comment) => comment.index === visibleCommentModal.index
    );

  const onCloseCommentModal = () =>
    setVisibleCommentModal({ index: undefined });

  const onSave = async (
    args: Omit<ChatCommentModel, "index">,
    successCallback: VoidFunction,
    finallyFunc: VoidFunction
  ) => {
    try {
      let existComments = [...chatComments];
      if (
        existComments.some(
          (comment) => comment.index === visibleCommentModal.index
        )
      ) {
        existComments[
          existComments.findIndex(
            (comment) => comment.index === visibleCommentModal.index
          )
        ].comment = args.comment;
        existComments[
          existComments.findIndex(
            (comment) => comment.index === visibleCommentModal.index
          )
        ].rating = args.rating;
      } else {
        existComments.push({
          comment: args.comment,
          rating: args.rating,
          index: visibleCommentModal.index!,
        });
      }
      if (existChatLogId) {
        await queryHealthChatLogService.updateCommentsById(
          existChatLogId,
          existComments
        );
      } else {
        const logId = await queryHealthChatLogService.createComments(
          existComments
        );
        onChangeChatLogId(logId);
      }
      onChangeChatComments([...existComments]);
      successCallback();
      toast("Thanks for feedbacks!");
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      finallyFunc();
    }
  };

  const onChangeModel = (value: string) => {
    setModel(value);
  };

  const onClickSaveInstruction = () => {
    setUpdatingInstruction(true);
    onSaveInstruction(selectedInstructionIndex!, instruction, () => {
      setUpdatingInstruction(false);
    });
  };

  return (
    <>
      <Card
        className="patient-side-bar"
        extra={
          <Button onClick={onClose} icon={<FontAwesomeIcon icon={faClose} />} />
        }
      >
        <Slider
          min={0}
          max={2}
          onChange={onChangeTemperature}
          value={typeof temperature === "number" ? temperature : 0}
          step={0.01}
        />
        <Select
          style={{ width: "100%", marginBottom: "5px" }}
          value={model}
          onChange={onChangeModel}
        >
          {modelOptions.map((option, index) => (
            <Option key={index} value={option}>
              {option}
            </Option>
          ))}
        </Select>
        <Select
          style={{ width: "100%", marginBottom: "5px" }}
          value={selectedInstructionIndex}
          onChange={onChangeInstruction}
        >
          {instructions.map((inst, index) => (
            <Option value={index}>{inst.title}</Option>
          ))}
        </Select>
        <TextArea
          onChange={(e) => setInstruction(e.target.value)}
          value={instruction}
          rows={12}
          placeholder="Instruction"
          style={{ resize: "none", marginBottom: "10px" }}
        />
        <Button
          onClick={onClickSaveInstruction}
          type="primary"
          disabled={!instruction || updatingInstruction}
          loading={updatingInstruction}
        >
          Save Instruction
        </Button>
        <div className="chat-content">
          <div className="chat-content__content">
            <QueryContainer itemsLength={queries.length}>
              {queries.length ? (
                <QueryItems
                  items={queries}
                  isLoading={false}
                  onRetry={onSearch}
                  onLike={onLike}
                />
              ) : (
                placeholder()
              )}
            </QueryContainer>
          </div>
          <div className="chat-content__bottom">
            <QueryInput
              onSearch={onSearch}
              disabled={
                queries.some((query) => query.inProgress) || !instruction
              }
            />
          </div>
        </div>
      </Card>
      {showCommentModal() && (
        <ChatCommentModal
          existComment={setExistCommentContent()}
          onClose={onCloseCommentModal}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default ChatSidebar;
