import { QuestionDBModel } from "../../db-services/questionDBService/types";
import { UserAuditDBModel, UserModel } from "../../db-services/userDBService/types";

export interface IQuizModel {
  name: string;
  status: QuizStatusTypes;
  configration: {
    embed_model_number: number;
    top_k: number;
    top_n: number;
    engine_name: string;
    llm_temp: number;
    reduction_type: string;
    context: string | null
  };
  questions: QuestionDBModel[];
}

export interface IQuizPayload {
  name: string;
  status: QuizStatusTypes;
  configration: {
    embed_model_number: number;
    top_k: number;
    top_n: number;
    engine_name: string;
    llm_temp: number;
    reduction_type: string;
    context:""
  };
}

export interface IQuizAuditModel extends UserAuditDBModel {
  assignedUserInfo: UserModel,
  questionPackageInfo: IQuizPackageInfoModel
  PDFPackageInfo: IQuizPackageInfoModel;
}

export interface IQuizResponseModel extends IQuizAuditModel {
  id: string;
  content: IQuizModel;
}

interface IQuizPackageInfoModel {
  id: string;
  collectionId: string;
  name: string;
}

export enum QuizStatusTypes {
  Pending = "pending",
  Completed = "completed",
}
