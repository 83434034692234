import moment from "moment";
import { ICreateUpdatePatientRequestModel } from "../../../services/ui-services/patientService/types";
import { DATE_FORMAT } from "../../../constants";

export interface IPatientFormModel {
  patientID?: string;
  age?: number;
  sex?: string;
  patient_history?: string;
  short_notes?: string;
  admission?: string;
  past_medical_history?: {
    icd_code: string;
    description: string;
  }[];
  allergies?: string[];
  diet?: string[];
  vitals?: {
    test_name: string;
    date: string;
    value: string;
    unit: string;
  }[];
  meds?: string[];
  labs?: {
    date: string;
    source?: string;
    results: {
      key: string;
      value: string;
    }[];
  }[];
  recent_changes?: {
    date: string;
    complaint: string;
    action: string;
  }[];
}

export const convertPatientFormModelToPayloadModel = (
  values: IPatientFormModel
): ICreateUpdatePatientRequestModel => ({
  patientID: values?.patientID ?? "",
  age: values?.age ?? 0,
  sex: values?.sex ?? "",
  patient_history: values?.patient_history ?? "",
  short_notes: values?.short_notes ?? "",
  admission: values.admission
    ? moment(new Date(values.admission)).format(DATE_FORMAT)
    : "",
  allergies: values?.allergies ?? [],
  diet: values?.diet ?? [],
  labs:
    values?.labs?.map((lab) => ({
      ...lab,
      date: new Date(lab.date).toISOString(),
      source: lab?.source ?? ''
    })) ?? [],
  meds: values?.meds ?? [],
  past_medical_history: values?.past_medical_history ?? [],
  recent_changes:
    values?.recent_changes?.map((change) => ({
      ...change,
      date: new Date(change.date).toISOString(),
    })) ?? [],
  vitals:
    values?.vitals?.map((vital) => ({
      ...vital,
      date: new Date(vital.date).toISOString(),
    })) ?? [],
});
