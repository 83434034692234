import { Modal, Rate } from "antd";
import React, { FC, useMemo, useState } from "react";
import AutoSizeTextArea from "../../../components/TextArea";
import { toast } from "react-toastify";
import QueryHealthLogService from "../../../services/ui-services/healthQueryLogsService";

type Props = {
  show: boolean;
  onClose: VoidFunction;
  patient_id: string;
  query_type: "diagnosis" | "triage";
};

const TriageDiagnosisCommentModal: FC<Props> = ({
  show,
  onClose,
  patient_id,
  query_type,
}) => {
  const [rating, setRating] = useState<number | undefined>();
  const [comment, setcomment] = useState<string>("");
  const [sending, setSending] = useState(false);
  const queryHealthLogService = useMemo(() => new QueryHealthLogService(), []);

  const isValid = () => comment && rating && !sending;

  const onClickSave = async () => {
    try {
      setSending(true);
      await queryHealthLogService.sendComment({
        patient_id,
        query_type,
        request_comment: comment,
        request_rating: rating!,
      });
      toast("Thanks for feedbacks!");
      onClose();
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      setSending(false);
    }
  };

  return (
    <Modal
      closable={false}
      open={show}
      onCancel={onClose}
      title="Please Rate Your Experience"
      cancelText="Close without feedback"
      okText="Send"
      okButtonProps={{
        onClick: onClickSave,
        disabled: !isValid(),
        loading: sending,
      }}
      cancelButtonProps={{
        disabled: sending,
      }}
    >
      <div className="modal_fields">
        <Rate value={rating} onChange={(value) => setRating(value)} />
      </div>
      <div className="modal_fields">
        <AutoSizeTextArea
          className="text-input"
          value={comment}
          onChange={(e) => setcomment(e.target.value)}
          autoSize={false}
          rows={5}
        />
      </div>
    </Modal>
  );
};

export default TriageDiagnosisCommentModal;
