import React, { useState } from "react";
import Modal from "../../../components/Modal";
import "./style.scss";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";
import AutoSizeTextArea from "../../../components/TextArea";

interface IProps {
  visible: boolean;
  contextValue: string | null;
  closeModal: () => void;
  onChangeContext: (context: string) => void;
}

const EditCollectionContextModalVisible: React.FC<IProps> = ({
  visible,
  contextValue,
  onChangeContext,
  closeModal,
}) => {
  const { t } = useTranslation();
  const [context, setContext] = useState<string>(contextValue || "");

  const isValidForm = () => context.length > 10 && context.length < 500;

  return (
    <Modal
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      title={t("PDFPackages.createPackage")}
      className="edit-context-modal"
    >
      <div className="modal-line">
        <AutoSizeTextArea
          rows={5}
          error={context.length <= 10 || context.length >= 500}
          errorMessage={t("PDFPackages.collectionContextValidMessage")}
          value={context}
          onChange={(e) => setContext(e.target.value)}
          label={t("PDFPackages.context")}
        />
      </div>

      <div className="footer">
        <Button
          onClick={() => onChangeContext(context)}
          className="save-button"
          disabled={!isValidForm()}
          icon={faSave}
          iconSize="2x"
        />
      </div>
    </Modal>
  );
};

export default EditCollectionContextModalVisible;
