import React from "react";
import "./style.scss";
import { IQuestionPackageModel } from "../../services/ui-services/questionService/types";

interface Props {
  questionPackages: IQuestionPackageModel[];
}

const QuestionBox: React.FC<Props> = ({ questionPackages }) => {
  return (
    <div className="question-boxes">
      {!!questionPackages.length &&
        questionPackages.map((packageInfo, index) => {
          return (
            <div key={index} className="question-box-container">
              <div className="box-header">{packageInfo.name}</div>
              <div className="box-content">
                {!!packageInfo.questions?.length &&
                  packageInfo.questions.map((question, index) => {
                    return (
                      <div key={index} className="question">
                        <b>{index + 1}) </b>
                        {question}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default QuestionBox;
