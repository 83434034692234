import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './en.json';
import translationTR from './tr.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: translationEN,
            },
            tr: {
                translation: translationTR,
            },
        },
        lng: 'en', // Varsayılan dil
        fallbackLng: 'en', // Desteklenmeyen bir dil seçildiğinde kullanılacak dil
        debug: false, // Geliştirme amaçlı hata ayıklama
        interpolation: {
            escapeValue: false, // HTML veya özel karakterleri kaçırmak için kullanılır
        },
    });

export default i18n;
