import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenStatic } from 'axios'

export interface HttpClientOptions {
    requestInterceptors: any[]
    responseInterceptors: any[]
    injectTokenHeaderRequestInterceptor?: boolean
    injectTokenRefreshResponseInterceptor?: boolean
    document?: any
    tokenKey?: string
    [key: string]: any
}

export default class HttpClient {
    private readonly axios: AxiosInstance
    private readonly document: any
    CancelToken: CancelTokenStatic

    constructor(baseURL: string, authToken: string) {



        this.CancelToken = axios.CancelToken

        this.axios = axios.create({
            baseURL,
            headers: {
                "X-Authorization": authToken
            }
        })
    }
    async request(options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.request(options)

        return response.data
    }

    async get(path: string, options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.get(path, options)

        return response.data
    }

    async post(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.post(path, data, options)

        return response.data
    }

    async put(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.put(path, data, options)

        return response.data
    }

    async patch(path: string, data: any, options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.patch(path, data, options)

        return response.data
    }

    async delete(path: string, options: AxiosRequestConfig): Promise<AxiosResponse["data"]> {
        const response = await this.axios.delete(path, options)

        return response.data
    }

    getAxiosInstance(): AxiosInstance {
        return this.axios
    }

    static isCancel(error: any): boolean {
        return axios.isCancel(error)
    }
}
