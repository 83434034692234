import React, { FC } from "react";
import "./style.scss";
import Table, { ITableColumn } from "../../../components/Table";
import { IWorkCubeLogTableModel } from "../types";
import { formatPrice, timestampToDate } from "../../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../../../context/store/authContext";
import { useNavigate } from "react-router-dom";
import { PermissionTypes } from "../../../permissions";
import { ProjectTypes } from "../../../models/ProjectTypes";

interface IProps {
  logs: IWorkCubeLogTableModel[];
}

const LogsTable: FC<IProps> = ({ logs }) => {
  const navigate = useNavigate();
  const { loggedInUserData } = useAuth();

  const quizForColumns: ITableColumn[] = [
    {
      label: "Model",
      key: "model",
      width: "20%",
      sortable: true,
    },
    {
      label: "Request Time",
      key: "requestTime",
      width: "15%",
      sortable: true,
      sortType: "date",
      render: (data: any) => new Date(data.requestTime).toLocaleDateString(),
    },
    {
      label: "LLM Response Time (Avg.)",
      key: "llm_resp_time",
      width: "10%",
      sortType: "number",
      sortable: true,
    },
    {
      label: "Completion Tokens",
      key: "completionTokens",
      width: "10%",
      sortType: "number",
      sortable: true,
    },
    {
      label: "Prompt Tokens",
      key: "promptTokens",
      width: "15%",
      sortType: "number",
      sortable: true,
    },

    {
      label: "Total Cost",
      key: "totalCost",
      width: "15%",
      sortable: true,
      sortType: "number",
      render: (data: any) => formatPrice(data.totalCost),
    },

    {
      label: "Total Tokens",
      key: "totalTokens",
      width: "15%",
      sortable: true,
      sortType: "number",
    },
    {
      label: "",
      key: "expand",
      width: "5%",
      sortable: false,
    },
  ];

  const onClickShowMore = (id?: string) => {
    if (!id) return;
    if (
      !loggedInUserData?.permissions?.includes(PermissionTypes.ViewWorkCubeInEngLogsPage)
    )
      return;

    navigate(`/workcube-in-eng-logs?project_type=${ProjectTypes.WorkCubeInEng}&log_id=${id}`);
  };

  const quizForData = logs.map((column) => {
    return {
      model: column.modelName,
      requestTime: column.requestTime.toString(),
      completionTokens: column.totalCompletionTokens,
      promptTokens: column.totalPromptTokens,
      totalCost: column.totalCost,
      llm_resp_time: (
        (column.llm_resp_time as number) / column.logs.length
      ).toFixed(2),
      totalTokens: column.totalTokens,
      logs: column.logs,
    };
  });

  const customExpandContent = (rowData: IWorkCubeLogTableModel) => (
    <div className="expand-container">
      {rowData.logs
        .sort(
          (a, b) =>
            timestampToDate(b.requestTime)!.getTime() -
            timestampToDate(a.requestTime)!.getTime()
        )
        .map((log, index) => {
          return (
            <tr key={index} className="log-info">
              <td className="text">{log.model}</td>
              <td className="text">
                {timestampToDate(log.requestTime)!.toLocaleTimeString()}
              </td>
              <td className="num">
                {(log.llm_resp_time as number).toFixed(2)}
              </td>
              <td className="num">{log.completion_tokens}</td>
              <td className="num">{log.prompt_tokens}</td>
              <td className="num">{log.total_cost.toFixed(4)}</td>
              <td className="num">
                <div style={{ position: "relative", width: "100%" }}>
                  {log.total_tokens}
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      position: "absolute",
                      top: "50%",
                      right: "10px",
                      transform: "translateY(-50%)",
                    }}
                    onClick={() => onClickShowMore(log.query_fb_path)}
                  />
                </div>
              </td>
            </tr>
          );
        })}
    </div>
  );

  return (
    <div className="analysis-table-container">
      <Table
        data={quizForData}
        columns={quizForColumns}
        expandContent={customExpandContent}
      />
    </div>
  );
};

export default LogsTable;
