import DataAnalysisLogDBService from "../../db-services/dataAnalysisLogDBService";
import { ILogResponseModel } from "../types";


class DataAnalysisLogService {
    private dbService;

    constructor() {
        this.dbService = new DataAnalysisLogDBService();
    }

    async getAllLogs(dayAgo: number): Promise<ILogResponseModel[]> {
        try {
            const response = await this.dbService.getAll(dayAgo)
            return response
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

}


export default DataAnalysisLogService;
