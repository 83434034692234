import React from "react";
import Modal from "../../../components/Modal";
import "./style.scss";
import Table, { ITableColumn } from "../../../components/Table";
import PackagesTable from "../PackagesTable/PackagesTable";
import { useTranslation } from "react-i18next";
import { IQuizResponseModel } from "../../../services/ui-services/quizService/types";
import { IQuizQuestionModel } from "../../../components/QuizChat/types";

interface IProps {
  selectedQuizzes: IQuizResponseModel[];
  visible: boolean;
  closeModal: () => void;
}

const CompareModal: React.FC<IProps> = ({
  visible,
  selectedQuizzes,
  closeModal,
}) => {
  const { t } = useTranslation();
  
  const calculateAveragePoint = (questions: IQuizQuestionModel[]) => {
    if (questions.length === 0) {
      return 0; // Eğer soru yoksa ortalamayı sıfır olarak döndür
    }

    const isCompleted = !questions.find((question) => question.point === null);

    const totalPoints = questions.reduce(
      (sum, question) => sum + (question.point ?? 0),
      0
    );
    const averagePoint = totalPoints / questions.length;
    return isCompleted ? averagePoint.toFixed(1) : "N/A";
  };

  const quizForColumns: ITableColumn[] = [
    {
      label: t("dashboard.compareTable.name"),
      key: "name",
      width: "10%",
      sortable: true,
    },
    {
      label: t("dashboard.compareTable.questionPackageName"),
      key: "questionPackage",
      width: "10%",
    },
    {
      label: t("dashboard.compareTable.embedModel"),
      key: "embedModel",
      width: "5%",
    },
    { label: t("dashboard.compareTable.topK"), key: "topk", width: "5%" },
    { label: t("dashboard.compareTable.topN"), key: "topn", width: "5%" },
    { label: t("dashboard.compareTable.llmTemp"), key: "llmTemp", width: "5%" },
    {
      label: t("dashboard.compareTable.reductionType"),
      key: "reductionType",
      width: "5%",
    },
    {
      label: t("dashboard.compareTable.creator"),
      key: "creator",
      width: "10%",
    },
    {
      label: t("dashboard.compareTable.assigned"),
      key: "assigned",
      width: "10%",
    },
    {
      label: t("dashboard.compareTable.average"),
      key: "averagePoint",
      width: "5%",
    },
  ];

  const quizForData = selectedQuizzes.map((quiz) => {
    return {
      name: quiz.content.name,
      questionPackage: quiz.questionPackageInfo.name,
      embedModel: quiz.content.configration.embed_model_number,
      topk: quiz.content.configration.top_k,
      topn: quiz.content.configration.top_n,
      llmTemp: quiz.content.configration.llm_temp,
      reductionType: quiz.content.configration.reduction_type,
      creator: quiz.creatorUserInfo.email,
      assigned: quiz.assignedUserInfo.email,
      averagePoint: calculateAveragePoint(quiz.content.questions),
    };
  });

  return (
    <Modal
      isOpen={visible}
      onClose={() => {
        closeModal();
      }}
      className="compare-modal"
    >
      <Table data={quizForData} columns={quizForColumns} />
      <PackagesTable data={selectedQuizzes} />
    </Modal>
  );
};

export default CompareModal;
