import React, { ButtonHTMLAttributes, ReactNode } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { StatusTypes } from "../../models/Status";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  icon?: IconProp;
  iconSize?: SizeProp;
  children?: ReactNode;
  status?: StatusTypes | null;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  icon,
  className,
  children,
  iconSize = "1x",
  status,
  ...rest
}) => {
  return (
    
      <button
        disabled={status === StatusTypes.InProgress ?? rest.disabled}
        className={`button ${className} ${status+"-button"}`}
        onClick={onClick}
        {...rest}
      >
        {icon && (
          <FontAwesomeIcon
            size={iconSize}
            icon={status === StatusTypes.InProgress ? faSpinner : icon}
          />
        )}
        {children}
      </button>
  );
};

export default Button;
