import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import {
  Card,
  List,
  Descriptions,
  Divider,
  Skeleton,
  Button,
  Input,
} from "antd";
import {
  IPatientFormModel,
  convertPatientFormModelToPayloadModel,
} from "../HealthCreatePatient/CreateUpdatePatient/types";
import PatientService from "../../services/ui-services/patientService";
import { useParams } from "react-router-dom";
import "./style.scss";
import moment from "moment";
import DiagnosisSidebar from "./DiagnosisSidebar";
import { DATETIME_FORMAT } from "../../constants";
import TriageSidebar from "./TriageSidebar";
import ChatSidebar, { ChatCommentModel } from "./ChatSidebar";
import { IQueryModel } from "./ChatSidebar/QueryItems/types";
import HealthAssistantService from "../../services/ui-services/healthAssistantService";
import { useAuth } from "../../context/store/authContext";
import { toast } from "react-toastify";
import HealthInstructionsService from "../../services/ui-services/healthInstructionsService";
import { GetInstructionResponseModel } from "../../services/ui-services/healthInstructionsService/types";

export type IDiagnosisSidebar = {
  value: string;
  date: string;
};

export type ITriageSidebar = {
  reason: string;
  date: string;
};

enum SidebarTypes {
  Triage = "triage",
  Diagnosis = "diagnosis",
  Chat = "chat",
}

interface Instruction {
  title: string;
  content: string;
  isDefault: boolean;
}

interface IInstructionModel {
  chatInstructions: Instruction[];
  triageInstructions: Instruction[];
  diagnosisInstructions: Instruction[];
}

export type AssistantInfoModel = {
  assistant_id: string;
  message_file_id: string;
  thread_id: string;
};

let assistantInfoForDeletion = {
  assistant_id: "",
  message_file_id: "",
  thread_id: "",
  email: "",
  patient_id: "",
};

let fb_id: string;

const HealthPatientDetail = () => {
  const healthAssistantService = useMemo(
    () => new HealthAssistantService(),
    []
  );
  const patientService = useMemo(() => new PatientService(), []);
  const healthInstructionsService = useMemo(
    () => new HealthInstructionsService(),
    []
  );

  const { loggedInUserData } = useAuth();

  const { id } = useParams();
  const [patientData, setPatientData] = useState<IPatientFormModel | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = useState<null | SidebarTypes>(null);
  const [existDiagnosis, setExistDiagnosis] =
    useState<null | IDiagnosisSidebar>(null);
  const [existTriage, setExistTriage] = useState<null | ITriageSidebar>(null);
  const [queries, setQueries] = useState<IQueryModel[]>([]);
  const [assistantInfo, setAssistantInfo] = useState<AssistantInfoModel>({
    assistant_id: "",
    message_file_id: "",
    thread_id: "",
  });
  const [instructions, setInstructions] = useState<GetInstructionResponseModel>(
    {
      chatInstructions: [],
      diagnosisInstructions: [],
      triageInstructions: [],
    }
  );
  const [chatComments, setChatComments] = useState<ChatCommentModel[]>([]);
  const [chatLogId, setChatLogId] = useState<undefined | string>();

  useEffect(() => {
    if (chatLogId) {
      fb_id = chatLogId;
    }
  }, [chatLogId]);

  const onChangeChatLogId = (id: string) => {
    setChatLogId(id);
  };

  const onChangeChatComments = (args: ChatCommentModel[]) => {
    setChatComments([...args]);
  };

  const getIntructions = useCallback(async () => {
    try {
      const res = await healthInstructionsService.get();
      setInstructions({ ...res! });
    } catch (error) {
      console.log(error);
    }
  }, [healthInstructionsService]);

  const onSaveInstruction = async (
    type: keyof IInstructionModel,
    index: number,
    content: string,
    callback: Function
  ) => {
    try {
      let existInstructions = instructions;
      if (existInstructions[type]?.at(index)) {
        existInstructions[type][index].content = content;
        await healthInstructionsService.update(existInstructions);
        setInstructions({ ...existInstructions });
        toast("Updated Successfully!");
      } else {
        toast.warn("Please try again!");
      }
    } catch (error) {
      toast.warn((error as Error).message);
    } finally {
      callback();
    }
  };

  useEffect(() => {
    getIntructions();
  }, [getIntructions]);

  useEffect(() => {
    assistantInfoForDeletion = {
      ...assistantInfo,
      email: loggedInUserData?.email ?? "",
      patient_id: patientData?.patientID ?? "",
    };
  }, [assistantInfo, loggedInUserData?.email, patientData?.patientID]);

  const onChangeAssistantInfo = (args: AssistantInfoModel) => {
    setAssistantInfo((prevState) => ({
      assistant_id: args.assistant_id || prevState.assistant_id,
      message_file_id: args.message_file_id || prevState.message_file_id,
      thread_id: args.thread_id || prevState.thread_id,
    }));
  };

  const deleteAssistant = useCallback(async () => {
    try {
      if (!Object.values(assistantInfoForDeletion).every((val) => val)) return;

      await healthAssistantService.deleteAssistant({
        ...assistantInfoForDeletion,
        fb_id,
      });
    } catch (error) {
      console.log(error);
    }
  }, [healthAssistantService]);

  useEffect(() => {
    return () => {
      deleteAssistant();
    };
  }, [deleteAssistant]);

  useEffect(() => {
    const handleBeforeUnload = async (event: any) => {
      if (!Object.values(assistantInfoForDeletion).every((val) => val)) return;

      event.preventDefault();
      event.returnValue = "";
      await deleteAssistant();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [deleteAssistant]);

  const onChangeQueries = (args: IQueryModel[]) => {
    setQueries([...args]);
  };

  const getPatient = useCallback(async () => {
    if (!id) return;
    try {
      setLoading(true);
      const res = await patientService.getById(id);
      setPatientData({ ...res });
      if (res?.diagnosis) {
        setExistDiagnosis({
          value: res.diagnosis ?? "",
          date: res?.diagnosisCreationDate ?? "",
        });
      }
      if (res?.triage_reasoning) {
        setExistTriage({
          date: res?.triageCreationDate ?? "",
          reason: res?.triage_reasoning ?? "",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [id, patientService]);

  const onUpdateDiagnosis = (value: IDiagnosisSidebar) => {
    setExistDiagnosis({ ...value });
  };

  const onUpdateTriage = (value: ITriageSidebar) => {
    setExistTriage({ ...value });
  };

  useEffect(() => {
    getPatient();
  }, [getPatient]);

  const setDatetimeFormat = (date: string) =>
    moment(new Date(date)).format(DATETIME_FORMAT);

  const onClickHeaderButtons = (sidebarType: SidebarTypes) => {
    if (sidebar !== sidebarType) {
      setSidebar(sidebarType);
    } else {
      setSidebar(null);
    }
  };

  const onChangePatientData = (key: keyof IPatientFormModel, value: string) => {
    setPatientData((prevState) => ({ ...prevState, [key]: value }));
  };

  const onSavePatientData = () => {
    try {
      const payload = convertPatientFormModelToPayloadModel(patientData!);
      patientService.update(id!, payload);
    } catch (error) {
      console.log(error);
    }
  };

  const onClickHistorySave = () => {
    if (Object.values(assistantInfo).some((x) => x)) {
      if (window.confirm("Chat will be reset! Are you sure?")) {
        deleteAssistant();
        setAssistantInfo({
          assistant_id: "",
          message_file_id: "",
          thread_id: "",
        });
        setChatLogId(undefined);
        setChatComments([]);
        setQueries([]);
        onSavePatientData();
      }
    } else {
      onSavePatientData();
    }
    toast("Saved successfully!");
  };

  if (loading) {
    return <Skeleton />;
  }

  if (!patientData) {
    return <div>Patient not found!</div>;
  }

  return (
    <>
      <div className="patient-detail-container">
        <Card
          style={{ flex: 1 }}
          title="Patient Details"
          bordered={false}
          extra={
            <div>
              <Button
                type={sidebar === SidebarTypes.Chat ? "default" : "primary"}
                disabled={!patientData}
                onClick={() => onClickHeaderButtons(SidebarTypes.Chat)}
                style={{ marginRight: "10px" }}
              >
                {sidebar === SidebarTypes.Chat ? "Close Chat" : "Show Chat"}
              </Button>
              <Button
                type={
                  sidebar === SidebarTypes.Diagnosis ? "default" : "primary"
                }
                disabled={!patientData}
                onClick={() => onClickHeaderButtons(SidebarTypes.Diagnosis)}
                style={{ marginRight: "10px" }}
              >
                {sidebar === SidebarTypes.Diagnosis
                  ? "Close Diagnosis"
                  : "Make Diagnosis"}
              </Button>
              <Button
                type={sidebar === SidebarTypes.Triage ? "default" : "primary"}
                disabled={!patientData}
                onClick={() => onClickHeaderButtons(SidebarTypes.Triage)}
              >
                {sidebar === SidebarTypes.Triage
                  ? "Close Triage"
                  : "Make Triage"}
              </Button>
            </div>
          }
        >
          <Descriptions bordered>
            <Descriptions.Item label="Patient ID">
              {patientData.patientID}
            </Descriptions.Item>
            <Descriptions.Item label="Age">{patientData.age}</Descriptions.Item>
            <Descriptions.Item label="Sex">{patientData.sex}</Descriptions.Item>
            <Descriptions.Item label="Admission Date">
              {patientData.admission}
            </Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Patient History</Divider>
          <Input.TextArea
            cols={5}
            value={patientData.patient_history}
            rows={100}
            onChange={(e) =>
              onChangePatientData("patient_history", e.target.value)
            }
          />
          <Button
            type="primary"
            style={{ marginTop: "10px" }}
            onClick={onClickHistorySave}
          >
            Save
          </Button>
          <Divider orientation="left">Short Notes</Divider>
          <Input.TextArea
            cols={5}
            value={patientData.short_notes}
            onChange={(e) => onChangePatientData("short_notes", e.target.value)}
            onBlur={onSavePatientData}
          />
          <Divider orientation="left">Past Medical History</Divider>
          <List
            bordered
            dataSource={patientData.past_medical_history}
            renderItem={(item) => (
              <List.Item>
                <strong>{item.icd_code}:</strong> {item.description}
              </List.Item>
            )}
          />

          <Divider orientation="left">Allergies</Divider>
          <List
            bordered
            dataSource={patientData.allergies}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <Divider orientation="left">Diet</Divider>
          <List
            bordered
            dataSource={patientData.diet}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <Divider orientation="left">Vitals</Divider>
          <List
            bordered
            dataSource={patientData.vitals}
            renderItem={(item) => (
              <List.Item>
                <strong>{item.test_name}:</strong> {item.value} {item.unit}{" "}
                (Date: {setDatetimeFormat(item.date)})
              </List.Item>
            )}
          />

          <Divider orientation="left">Medications</Divider>
          <List
            bordered
            dataSource={patientData.meds}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <Divider orientation="left">Lab Results</Divider>
          {patientData?.labs?.map((lab, index) => (
            <div key={index}>
              <strong>Date:</strong>
              <span>{setDatetimeFormat(lab.date)}</span>{" "}
              <strong>Source:</strong>
              <span>{lab.source}</span>
              <List
                bordered
                dataSource={lab.results}
                renderItem={(item) => (
                  <List.Item>
                    <strong>{item.key}:</strong> {item.value}
                  </List.Item>
                )}
              />
            </div>
          ))}

          <Divider orientation="left">Recent Changes</Divider>
          <List
            bordered
            dataSource={patientData.recent_changes}
            renderItem={(item) => (
              <List.Item>
                <strong>{item.complaint}:</strong> {item.action} (Date:{" "}
                {setDatetimeFormat(item.date)})
              </List.Item>
            )}
          />
        </Card>
        {sidebar === SidebarTypes.Triage && (
          <TriageSidebar
            id={id!}
            onClose={() => setSidebar(null)}
            patient={patientData}
            existTriage={existTriage}
            onUpdateTriage={onUpdateTriage}
            instructions={instructions.triageInstructions}
            onSaveInstruction={(index, content, callback) =>
              onSaveInstruction("triageInstructions", index, content, callback)
            }
          />
        )}
        {sidebar === SidebarTypes.Diagnosis && (
          <DiagnosisSidebar
            id={id!}
            onClose={() => setSidebar(null)}
            patient={patientData}
            existDiagnosis={existDiagnosis}
            onUpdateDiagnosis={onUpdateDiagnosis}
            instructions={instructions.diagnosisInstructions}
            onSaveInstruction={(index, content, callback) =>
              onSaveInstruction("diagnosisInstructions", index, content, callback)
            }
          />
        )}
        {sidebar === SidebarTypes.Chat && (
          <ChatSidebar
            id={id!}
            onClose={() => setSidebar(null)}
            queries={queries}
            onChangeQueries={onChangeQueries}
            patient={patientData}
            assistantInfo={assistantInfo}
            onChangeAssistantInfo={onChangeAssistantInfo}
            instructions={instructions.chatInstructions}
            chatComments={chatComments}
            onChangeChatComments={onChangeChatComments}
            onChangeChatLogId={onChangeChatLogId}
            onSaveInstruction={(index, content, callback) =>
              onSaveInstruction("chatInstructions", index, content, callback)
            }
          />
        )}
      </div>
    </>
  );
};

export default memo(HealthPatientDetail);
